import { GraphQLRequest } from '@/setup/axios';
import {
  ACCEPT_REQUEST,
  GET_BANK_DELEGATIONS_REQUESTS,
  GET_DELEGATIONS_REQUEST_BY_ID,
  REJECT_REQUEST,
} from '@/api/graphql/bank-delegations-gql';

export function fetchBankDelegationRequests({ page = 1, size = 10, statuses = ['pending'] }) {
  return GraphQLRequest({
    query: GET_BANK_DELEGATIONS_REQUESTS,
    variables: {
      page,
      size,
      statuses,
    },
  });
}

export function fetchBankDelegationRequest(id) {
  return GraphQLRequest({
    query: GET_DELEGATIONS_REQUEST_BY_ID,
    variables: {
      id,
    },
  });
}

export function acceptBankDelegationRequest(id) {
  return GraphQLRequest({
    query: ACCEPT_REQUEST,
    variables: {
      id,
    },
  });
}

export function rejectBankDelegationRequest(id, rejectReason = null) {
  return GraphQLRequest({
    query: REJECT_REQUEST,
    variables: {
      id,
      rejectReason,
    },
  });
}
