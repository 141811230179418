import { GraphQLRequest } from '@/setup/axios';
import {
  EstablishmentRequestStatuses,
  EstablishmentType,
  EstablishmentRequestType,
  AdminRoles,
} from '@/constants/enums';
import {
  GET_USER_REQUESTS,
  GET_DISCLOSURE_FORM,
  FETCH_ESTABLISHMENT_REQUESTS,
  ACCEPT_ESTABLISHMENT_REQUEST,
  REJECT_ESTABLISHMENT_REQUEST,
  FIND_ADMIN_REQUEST,
  RESET_ACTION_EXPECTATION_STATUS,
  FIND_LAST_REJECTED_ESTABLISHMENT_ADMIN_REQUEST,
} from '@/api/graphql/user-requests-gql';

import { FIND_MEMBERSHIP_CONDTIONS } from '@/api/graphql/membership-conditions-gql';
import { LIST_SEVEN_HUNDRED_ERRORS } from './graphql/seven-hundred-errors-gql';

export function fetchEstablishmentRequests(
  {
    establishmentType = EstablishmentType.Entity,
    status = EstablishmentRequestStatuses.Pending,
    page = 1,
    size = 10,
    adminRole,
    unitId,
    unitTitle,
    remainingTime,
    requestType,
    active = false,
    activeMode = false,
    createdAt,
    type,
  },
  config = {},
) {
  const ADMIN_ROLES_WITH_RECOMMENDATION = [
    AdminRoles.HrsdEmployee,
    AdminRoles.RegistrationAndPermitEmployee,
    AdminRoles.GovernanceCenterEmployee,
  ];
  const getRequestType = ADMIN_ROLES_WITH_RECOMMENDATION.includes(adminRole)
    ? EstablishmentRequestType.Recommendation
    : undefined;
  return GraphQLRequest(
    {
      query: FETCH_ESTABLISHMENT_REQUESTS,
      variables: {
        status: activeMode ? undefined : status,
        active,
        unitType: establishmentType,
        page: Number(page),
        size: Number(size),
        adminRole: adminRole ? [adminRole] : undefined,
        unitId: unitId ? Number(unitId) : undefined,
        unitTitle: unitTitle ? String(unitTitle) : undefined,
        remaingTime: remainingTime ? String(remainingTime) : undefined,
        createdAt: createdAt ? String(createdAt) : undefined,
        type: type ? String(type) : undefined,
        requestType: requestType || getRequestType,
      },
    },
    config,
  );
}

export function fetchAdminRecommendation({
  establishmentType = EstablishmentType.Entity,
  size = 10,
  adminRole,
  unitId,
  requestType = EstablishmentRequestType.Recommendation,
}) {
  return GraphQLRequest({
    query: FETCH_ESTABLISHMENT_REQUESTS,
    variables: {
      unitType: establishmentType,
      size: Number(size),
      page: 1,
      adminRole: adminRole || undefined,
      unitId: unitId ? Number(unitId) : undefined,
      requestType,
    },
  });
}

export function acceptEstablismentRequest(id) {
  return GraphQLRequest({
    query: ACCEPT_ESTABLISHMENT_REQUEST,
    variables: {
      establishmentAdminRequestAttributes: {
        id: Number(id),
      },
    },
  });
}

export function rejectEstablishmentRequest(id, message) {
  return GraphQLRequest({
    query: REJECT_ESTABLISHMENT_REQUEST,
    variables: {
      establishmentAdminRequestAttributes: {
        id: Number(id),
        message,
      },
    },
  });
}

export function fetchUserRequests(unitId, unitType = EstablishmentType.Entity, config = {}) {
  return GraphQLRequest(
    {
      query: GET_USER_REQUESTS,
      variables: {
        unitType,
        unitId: Number(unitId),
      },
    },
    config,
  );
}

export function fetchSevenHundredErrorsRequests({
  unitId = 0,
  page = 1,
  size = 10,
  error = null,
  code = null,
}, config = {}) {
  return GraphQLRequest(
    {
      query: LIST_SEVEN_HUNDRED_ERRORS,
      variables: {
        unitId: Number(unitId),
        page,
        size,
        error,
        code,
      },
    },
    config,
  );
}

export function fetchDisclosureFormAnswers(requestId, config) {
  return GraphQLRequest(
    {
      query: GET_DISCLOSURE_FORM,
      variables: {
        id: Number(requestId),
      },
    },
    config,
  );
}

export function findAdminRequest(requestId) {
  return GraphQLRequest({
    query: FIND_ADMIN_REQUEST,
    variables: {
      id: Number(requestId),
    },
  });
}

export function fetchMembershipConditionsByEntityId(entityId) {
  return GraphQLRequest({
    query: FIND_MEMBERSHIP_CONDTIONS,
    variables: {
      page: 1,
      size: 10,
      entityId: Number(entityId),
    },
  });
}

export function resetActionExpectationStatus(id) {
  return GraphQLRequest({
    query: RESET_ACTION_EXPECTATION_STATUS,
    variables: {
      id: Number(id),
    },
  });
}

export function findLastRejectedEstablishmentAdminRequest(id, type = 'Entity') {
  return GraphQLRequest({
    query: FIND_LAST_REJECTED_ESTABLISHMENT_ADMIN_REQUEST,
    variables: {
      unitId: Number(id),
      unitType: type,
    },
  });
}
