import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import { ErrorHandler } from '@/helpers';
import {
  fetchEmployeeRequest,
  fetchEmployeeRequests,
  acceptEmployeeRequest,
  rejectEmployeeRequest,
} from '@/api/employee.api';
import { serializer } from '@/helpers/api';
import {
  mediaDocTypes,
  mediaAttachableTypes, RequestStatuses,
} from '@/constants/enums';
import { formatDate } from '@/helpers/date';

import { getDocumentsList, uploadDocuments } from '@/services/media.service';

let cancelSource = null;

const handleEmployeeRequests = (data) => (
  data.map((r) => ({
    ...r,
    createdAt: formatDate(r.request.createdAt),
    remainingValue: r.remainingData?.remainingValue,
    remainingType: r.remainingData?.remainingType,
    pausedAt: r.remainingData?.pausedAt,
    updatedAt: r.updatedAt
      ? formatDate(r.request.updatedAt)
      : formatDate(r.request.createdAt),
  }))
);

const mainState = {
  employeeRequests: [],
  employeeRequestsCount: [],
  employeeRequestsLoading: false,
  requestDetails: null,
  requestDetailsLoading: false,
  fileLoading: false,
  decision: null,
  decisionIsLoading: false,
  errors: [],
};

const mainGetters = {
  getField,
  employeeRequests: (store) => store.employeeRequests,
  getEmployeeRequestsCount: (store) => store.employeeRequestsCount,
  isEmployeeRequestsLoading: (store) => store.employeeRequestsLoading,
  getRequestDetails: (store) => store.requestDetails,
  isRequestDetailsLoading: (store) => store.requestDetailsLoading,
  isFileLoading: (store) => store.fileLoading,
  getDecision: (store) => store.decision,
  decisionIsLoading: (store) => store.decisionIsLoading,
  getErrors: (store) => store.errors,
};

const mainMutations = {
  updateField,
  setEmployeeRequests: (store, requests) => {
    store.employeeRequests = requests;
  },
  setEmployeeRequestsCount: (store, count) => {
    store.employeeRequestsCount = count;
  },
  setEmployeeRequestsLoading: (store, boolean) => {
    store.employeeRequestsLoading = boolean;
  },
  setRequestDetails: (store, details) => {
    store.requestDetails = details;
  },
  setRequestDetailsLoading: (store, boolean) => {
    store.requestDetailsLoading = boolean;
  },
  setFileLoading: (store, value) => {
    store.fileLoading = value;
  },
  setDecision: (store, value) => {
    store.decision = value;
  },
  setDecisionLoading: (store, value) => {
    store.decisionIsLoading = value;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
};

const mainActions = {
  cancelRequest: () => {
    if (cancelSource) {
      cancelSource.cancel('abort employee request');
    }
  },
  getEmployeeRequestsList: async ({ commit, rootGetters }, { namespace, status }) => {
    commit('setErrors', []);
    cancelSource = axios.CancelToken.source();
    commit('setEmployeeRequestsLoading', true);
    try {
      const res = await fetchEmployeeRequests(
        {
          page: rootGetters[`${namespace}/selectedPage`],
          size: rootGetters[`${namespace}/selectedSize`],
          status,
        }, { cancelToken: cancelSource.token },
      );
      const list = res.data?.listEmployeeAdminRequests?.adminRequests || [];
      const meta = res.data?.listEmployeeAdminRequests?.meta;
      commit(`${namespace}/setTotalPages`, meta.pageCount, { root: true });
      commit(`${namespace}/setTotalRecords`, meta.recordsCount, { root: true });
      commit('setEmployeeRequestsCount', meta.recordsCount);
      commit('setEmployeeRequests', handleEmployeeRequests(list));
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      commit('setEmployeeRequests', []);
    } finally {
      commit('setEmployeeRequestsLoading', false);
    }
  },
  getEmployeeRequestDetails: async ({ commit }, { requestId }) => {
    commit('setErrors', []);
    commit('setRequestDetailsLoading', true);
    let details = null;
    try {
      const request = await fetchEmployeeRequest(requestId);
      details = request.data?.findEmployeeAdminRequests;
      details.createdAt = formatDate(details.request.createdAt);
      details.updatedAt = formatDate(details.request.updatedAt);
      commit('setRequestDetails', details);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      commit('setRequestDetails', []);
      details = {
        error,
      };
    } finally {
      commit('setRequestDetailsLoading', false);
    }
    return details;
  },
  getUploadedFile: async ({ commit }, id) => {
    commit('setFileLoading', true);
    let res = null;
    try {
      res = await getDocumentsList(
        id,
        mediaAttachableTypes.EmployeeRequest,
        mediaDocTypes.SpecializedAdminFile,
        null,
      );
    } catch (error) {
      console.warn(error);
      res = { error };
    } finally {
      commit('setFileLoading', false);
    }
    return res;
  },
  uploadFile: async ({ commit }, { files, id }) => {
    commit('setFileLoading', true);
    const res = await uploadDocuments(
      files,
      id,
      mediaAttachableTypes.EmployeeRequest,
      mediaDocTypes.SpecializedAdminFile,
    );
    commit('setFileLoading', false);
    return res;
  },
  bringTheAction: async ({ commit, dispatch }, { decision, rejectMsg }) => {
    commit('setErrors', []);
    commit('setDecisionLoading', true);
    if (decision === RequestStatuses.Accepted) {
      await dispatch('acceptRequest', { decision });
    } else {
      await dispatch('rejectRequest', { rejectMsg });
    }
    commit('setDecisionLoading', false);
  },
  rejectRequest: async ({ commit, getters }, { rejectMsg }) => {
    const requestId = getters.getRequestDetails.id;
    try {
      const payload = {
        id: requestId,
        message: rejectMsg,
      };
      const serializedData = serializer(payload, 'employee_request');
      const request = await rejectEmployeeRequest(requestId, serializedData);
      commit('setDecision', request);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
    }
  },
  acceptRequest: async ({ commit, getters }) => {
    const requestId = getters.getRequestDetails.id;
    try {
      const request = await acceptEmployeeRequest(requestId);
      commit('setDecision', request);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
