import { Gateway, GraphQLRequest } from '@/setup/axios';
import { CREATE_ADMIN_ACCOUNT } from '@/api/graphql/admins-gql';

export function fetchAdminAccounts(role, page, size, config = {}) {
  return Gateway.get(`accounts/list?role=${role}&page=${page}&size=${size}`, config);
}

export function createAdminAccount(payload) {
  return GraphQLRequest({
    query: CREATE_ADMIN_ACCOUNT,
    variables: {
      adminAccount: {
        ...payload,
      },
    },
  });
}

export function deleteAdminAccount(id) {
  return Gateway.delete(`accounts?account_id=${id}`);
}
