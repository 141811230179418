import { GraphQLRequest } from '@/setup/axios';

import { NamaAdminRequestStatus } from '@/constants/enums';

import {
  LIST_NAMA_ADMIN_REQUESTS,
  FIND_NAMA_ADMIN_REQUEST,
  ACCEPT_NAMA_ADMIN_REQUEST,
  REJECT_NAMA_ADMIN_REQUEST,
} from './graphql/updating-entities-requests-gql';

export function fetchUpdatingEntitiesRequests({
  status = [
    NamaAdminRequestStatus.Pending,
    NamaAdminRequestStatus.Accepted,
    NamaAdminRequestStatus.Rejected,
  ],
  page = 1,
  size = 10,
  adminRole = 'audit_officer',
  config,
}) {
  return GraphQLRequest({
    query: LIST_NAMA_ADMIN_REQUESTS,
    variables: {
      status,
      page,
      size,
      adminRole,
    },
    config,
  });
}

export function fetchUpdatingEntitiesRequest(id) {
  return GraphQLRequest({
    query: FIND_NAMA_ADMIN_REQUEST,
    variables: {
      id: Number(id),
    },
  });
}

export function acceptUpdatingEntitiesRequest(id) {
  return GraphQLRequest({
    query: ACCEPT_NAMA_ADMIN_REQUEST,
    variables: {
      id: Number(id),
    },
  });
}

export function rejectUpdatingEntitiesRequest(id, message) {
  return GraphQLRequest({
    query: REJECT_NAMA_ADMIN_REQUEST,
    variables: {
      id: Number(id),
      message,
    },
  });
}
