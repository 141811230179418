<template>
  <div class="sidebar-list-item-container">
    <div class="sidebar-list-icon-container">
      <svg
        class="sidebar-list-icon"
        :viewBox="viewBox"
        color="#03D1AB"
      >
        <slot />
      </svg>
    </div>
    <div class="sidebar-list-text-container">
      <span class="sidebar-list-text">{{ $t(name) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarListItemContainer',
  props: {
    name: {
      type: String,
      required: true,
    },
    viewBox: {
      type: String,
      default: '0 0 32 32',
    },
  },
};
</script>
