<template>
  <div class="dashboard-layout">
    <layout-loading v-if="loading" />
    <redirect
      v-if="loginRedirectCondition"
      to="Login"
      @hook:created="showWarning"
    />
    <template v-if="dashboardViewCondition">
      <div class="dashboard-inner">
        <sidebar />
        <div class="dashboard-main">
          <mq-layout mq="tablet+">
            <dashboard-header />
          </mq-layout>
          <div
            id="main-content"
            class="dashboard-content"
          >
            <router-view />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { AdminRoles } from '@/constants/enums';
import Sidebar from '@/containers/sidebar/Sidebar.vue';
import DashboardHeader from '@/views/dashboard/dashboard-header/DashboardHeader.vue';

export default {
  name: 'DashboardLayout',
  components: {
    DashboardHeader,
    Sidebar,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loading: 'auth/loading',
      adminRole: 'auth/adminRole',
      updatedAt: 'auth/updatedAt',
    }),
    routeNotAvailable() {
      const availableRole = this.$route.matched.some(
        (record) => record.meta.roles?.includes(this.adminRole),
      );
      return !this.loading && this.isAuthenticated && !availableRole;
    },
    loginRedirectCondition() {
      return (!this.loading && !this.isAuthenticated)
        || (this.routeNotAvailable && this.adminRole)
        || (this.isAuthenticated && this.isAccountPasswordHasNotSet);
    },
    dashboardViewCondition() {
      return !this.loading && this.isAuthenticated && !this.routeNotAvailable;
    },
    isAccountPasswordHasNotSet() {
      return this.adminRole !== AdminRoles.SuperAdmin && !this.updatedAt;
    },
  },
  methods: {
    ...mapActions({
      showNotification: 'toast/showNotification',
    }),
    showWarning() {
      this.showNotification({
        message: this.$t('general.redirectLogin'),
        duration: 4000,
        type: 'warning',
      });
    },
  },
};
</script>

<style src="./styles.scss" lang="scss" />
