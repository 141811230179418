import { GraphQLRequest } from '@/setup/axios';
import {
  GET_EMPLOYEE_REQUEST_BY_ID,
  GET_EMPLOYEE_REQUESTS,
  ACCEPT_EMPLOYEE_ADMIN_REQUEST,
  REJECT_EMPLOYEE_ADMIN_REQUEST,
} from '@/api/graphql/employee-requests-gql';

export function fetchEmployeeRequests({ page = 1, size = 10, status }, config = {}) {
  return GraphQLRequest({
    query: GET_EMPLOYEE_REQUESTS,
    variables: {
      page,
      size,
      status,
    },
  }, config);
}

export function fetchEmployeeRequest(id) {
  return GraphQLRequest({
    query: GET_EMPLOYEE_REQUEST_BY_ID,
    variables: {
      id: Number(id),
    },
  });
}

export function acceptEmployeeRequest(id) {
  return GraphQLRequest({
    query: ACCEPT_EMPLOYEE_ADMIN_REQUEST,
    variables: {
      id: Number(id),
    },
  });
}

export function rejectEmployeeRequest(id, payload) {
  return GraphQLRequest({
    query: REJECT_EMPLOYEE_ADMIN_REQUEST,
    variables: {
      id: Number(id),
      message: payload.data.attributes.message,
    },
  });
}
