<template>
  <div
    class="download-item"
    :class="item.status"
  >
    <div class="download-item__wrapper">
      <div
        class="download-item__status"
      >
        <base-lottie
          v-if="getItemIcon.lottie"
          height="32"
          width="32"
          :json="getItemIcon.lottie"
          :backup-svg-name="getItemIcon.backup"
          :autoplay="true"
        />
        <svg
          v-else
          viewBox="0 0 32 32"
        >
          <component :is="getItemIcon.backup" />
        </svg>
      </div>
      <div class="download-item__progress">
        <div
          class="download-item__name"
        >
          <span v-text="getReportName" />
          <span
            class="download-item__desc"
            v-text="itemDescription"
          />
        </div>
        <progress-bar
          :status="item.status"
          :percentage="item.percentage"
        />
      </div>
      <div class="download-item__action">
        <div
          class="download-item__btn"
          @click="removeItem"
        >
          <base-lottie
            :json="getLottieDeleteJson.lottie"
            :backup-svg-name="getLottieDeleteJson.backup"
          />
        </div>
      </div>
    </div>
    <form-errors
      class="download-item__error"
      align="left"
      :errors="item.error"
    />
  </div>
</template>

<script>
import ProgressBar from '@/components/progress-bar/ProgressBar.vue';
import BaseLottie from '@/components/base-lottie/BaseLottie.vue';
import lottieDeleteJson from './assets/delete.json';
import lottiePendingJson from './assets/pending.json';
import lottieDownloadingJson from './assets/downloading.json';

const ItemStatuses = {
  Pending: 'pending',
  Downloading: 'downloading',
  Completed: 'completed',
  Failed: 'failed',
};

export default {
  name: 'DownloadItem',
  components: {
    BaseLottie,
    ProgressBar,
  },
  props: {
    index: {
      type: Number,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getReportName() {
      if (!this.item.order) return this.$t('entitiesList.entityReport');
      return `${this.$t('entitiesList.entityReport')} ${this.item.order}`;
    },
    getLottieDeleteJson() {
      return {
        lottie: lottieDeleteJson,
        backup: 'IconTrash',
      };
    },
    getItemIcon() {
      switch (this.item.status) {
        case ItemStatuses.Pending:
          return {
            lottie: lottiePendingJson,
            backup: 'IconGenerated',
          };
        case ItemStatuses.Downloading:
          return {
            lottie: lottieDownloadingJson,
            backup: 'IconDownloading',
          };
        case ItemStatuses.Completed:
          return {
            lottie: null,
            backup: 'IconCompleted',
          };
        case ItemStatuses.Failed:
          return {
            lottie: null,
            backup: 'IconReject',
          };
        default:
          return {
            lottie: null,
            backup: null,
          };
      }
    },
    itemDescription() {
      if (!this.item.status) {
        return '';
      }
      return this.$t(`entitiesList.${this.item.status}`);
    },
  },
  watch: {
    item: {
      handler(newValue, oldValue) {
        const { path } = newValue;
        const oldPath = oldValue.path;
        if (path && path !== oldPath) this.initDownload(path);
      },
    },
  },
  methods: {
    removeItem() {
      this.$emit('removeItem');
    },
    initDownload(path) {
      this.$emit('initDownload', path);
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
