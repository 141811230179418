import { GraphQLRequest } from '@/setup/axios';
import {
  GET_ESHOP_REQUESTS,
  GET_ESHOP_REQUEST_BY_LICENSE_REQUEST_ID,
  SEND_REJECT_ON_LICENSE_REQUEST,
  SEND_ACCEPT_ON_LICENSE_REQUEST,
} from '@/api/graphql/eshop-requests-gql';

export function fetchEshopRequests({ page = 1, size = 10, status }, config = {}) {
  return GraphQLRequest({
    query: GET_ESHOP_REQUESTS,
    variables: {
      page,
      size,
      status,
    },
  }, config);
}

export function fetchEshopRequestByLicenseId(licenseId) {
  return GraphQLRequest({
    query: GET_ESHOP_REQUEST_BY_LICENSE_REQUEST_ID,
    variables: {
      id: licenseId,
    },
  });
}

export function acceptEshopRequest(id) {
  return GraphQLRequest({
    query: SEND_ACCEPT_ON_LICENSE_REQUEST,
    variables: {
      id: Number(id),
    },
  });
}

export function rejectEshopRequest(id, rejectReason) {
  return GraphQLRequest({
    query: SEND_REJECT_ON_LICENSE_REQUEST,
    variables: {
      id: Number(id),
      rejectReason: String(rejectReason),
    },
  });
}
