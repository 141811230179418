import { GraphQLRequest } from '@/setup/axios';
import {
  ACCEPT_REQUEST,
  REJECT_REQUEST,
  FETCH_FAMILY_FUND_REQUEST,
  FETCH_FAMILY_FUND_SUBSCRIPTION,
  LIST_ENTITY_BANKS,
  ACCEPT_BY_SPECIALIZED,
  REJECT_BY_SPECIALIZED,
} from '@/api/graphql/family-fund-gql';

export const fetchFamilyFundRequest = (requestId) => GraphQLRequest({
  query: FETCH_FAMILY_FUND_REQUEST,
  variables: {
    id: Number(requestId),
  },
});

export const fetchFamilyFundSubscription = (id) => (
  GraphQLRequest({
    query: FETCH_FAMILY_FUND_SUBSCRIPTION,
    variables: {
      entityId: Number(id),
    },
  })
);
export const fetchEntityBanks = (unitId, unitType) => (
  GraphQLRequest({
    query: LIST_ENTITY_BANKS,
    variables: {
      unitId: Number(unitId),
      unitType,
    },
  })
);

export const acceptRequest = (id, message) => (
  GraphQLRequest({
    query: ACCEPT_REQUEST,
    variables: {
      establishmentAdminRequestAttributes: {
        id: Number(id),
        message: message || undefined,
      },
    },
  })
);

export const rejectRequest = (id, message) => (
  GraphQLRequest({
    query: REJECT_REQUEST,
    variables: {
      establishmentAdminRequestAttributes: {
        id: Number(id),
        message: message || undefined,
      },
    },
  })
);

export const acceptRequestBySpecialized = (id) => (
  GraphQLRequest({
    query: ACCEPT_BY_SPECIALIZED,
    variables: {
      userRequestAcceptAttributes: {
        id: Number(id),
      },
    },
  })
);

export const rejectRequestBySpecialized = (id) => (
  GraphQLRequest({
    query: REJECT_BY_SPECIALIZED,
    variables: {
      userRequestRejectBySpecializedAttributes: {
        id: Number(id),
      },
    },
  })
);
