export const GET_LOOKUP_DATA = `query {
  membershipPositions {
    Entity {
      privateAssociation {
        uid
        arTitle
        enTitle
      }
      privateCorporation {
        uid
        arTitle
        enTitle
      }
      cooperativeAssociation {
        uid
        arTitle
        enTitle
      }
    }
    Branch {
      privateAssociation {
        uid
        arTitle
        enTitle
      }
      privateCorporation {
        uid
        arTitle
        enTitle
      }
      cooperativeAssociation {
        uid
        arTitle
        enTitle
      }
    }
  }
  regions {
    code
    arTitle
    enTitle
    cities {
      arTitle
      enTitle
      uid
    }
  }
  banks {
    arTitle
    enTitle
    id
  }
  familyTrustGoals {
    id
    arTitle
    enTitle
  }
  familyRelations {
    arTitle
    enTitle
    uid
  }
}`;

export const LIST_BANKS = `query ListBanks {
  banks {
    arTitle
    enTitle
    id
  }
}`;
