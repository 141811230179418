import Vue from 'vue';

import '@takamol/nawa-library/src/assets/scss/theme.scss';
import './assets/scss/main.scss';

import setupVueGlobal from '@takamol/nawa-library/src/settings/vue';
import { setupVueLocal } from '@/setup/vue';
import { setupGlobalComponents, setupGlobalIcons } from '@/setup/components';
import { setupValidation, setupValidationRules } from '@/setup/validation';
import { setupSentry } from '@/setup/sentry';
import { setupInterceptors } from '@/setup/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

setupVueGlobal(store);
setupVueLocal();
setupGlobalComponents();
setupGlobalIcons();
setupSentry();
setupInterceptors(store);
setupValidation();
setupValidationRules();

const app = {
  render: (h) => h(App),
  router,
  store,
  i18n,
};

new Vue(app).$mount('#app');
