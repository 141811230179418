export const LIST_NAMA_ADMIN_REQUESTS = `query LIST_NAMA_ADMIN_REQUESTS($status: [NamaAdminRequestStatus!], $page: Int!, $size: Int!, $adminRole: String!) {
  listNamaAdminRequest(status: $status, page: $page, size: $size, adminRole: $adminRole) {
    namaAdminRequests {
      id,
      unitId,
      unitType,
      createdAt,
      updatedAt,
      status,
      unit {
        ... on Entity {
          nameArabic,
          nameEnglish,
          type,
        }
      }
    },
    meta {
      page,
      pageCount,
      recordsCount,
      size,
    }
  }
}`;

export const FIND_NAMA_ADMIN_REQUEST = `query FIND_NAMA_ADMIN_REQUEST($id: Int!) {
  findNamaAdminRequest(id: $id) {
    adminRole,
    createdAt,
    id,
    establishmentId,
    message,
    requestType,
    status,
    unit {
      ...on Entity {
        id,
        type,
        nameArabic,
        nameEnglish,
        secondSubClassificationId,
        goalIds,
        activityIds,
        registrationDateHijri,
        membershipType,
        typeOfBenefits,
        unifiedNumber700,
        bankVerificationFiles {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        permitToEstablishABoard {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        migrationDelegateLetters {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        entityLicenses {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        establishmentDecisions {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        entityPolicies {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        supportingDocuments {
          docType,
          fileSize,
          filename,
          mimeType,
          path,
          uid,
        },
        region {
          arTitle,
          enTitle,
        },
        city {
          arTitle,
          enTitle,
        }
        owner {
          firstName,
          lastName,
          fatherName,
          grandfatherName,
          nationalId,
          email,
          absherPhone,
          birthDateHijri,
        },

      }
    }
    unitId,
    unitType,
    updatedAt,
  }
}`;

export const ACCEPT_NAMA_ADMIN_REQUEST = `mutation AcceptNamaAdminRequest($id: Int!) {
  acceptNamaAdminRequest(id: $id) {
    id
  }
}`;

export const REJECT_NAMA_ADMIN_REQUEST = `mutation RejectNamaAdminRequest($id: Int!, $message: String) {
  rejectNamaAdminRequest(id: $id, message: $message) {
    id
  }
}`;

export const LIST_BANK_ACCOUNTS = `query ListBankAccounts($unitId: Int, $unitType: String, $page: Int, $size: Int) {
  listBankAccounts(unitId: $unitId, unitType: $unitType, page: $page, size: $size) {
    bankAccounts {
      number700
    }
  }
}`;
