<template>
  <li class="sidebar-list-item">
    <a
      v-if="externalUrl"
      :href="externalUrl"
      class="sidebar-list-link"
    >
      <sidebar-list-item-container
        :name="name"
        :view-box="viewBox"
      >
        <slot />
      </sidebar-list-item-container>
    </a>
    <router-link
      v-else-if="url"
      class="sidebar-list-link"
      :class="{ 'sidebar-active-link': manualSetActive(url), 'have-count': Boolean(count) }"
      :to="url"
      @click.native="clickLink"
    >
      <sidebar-list-item-container
        :name="name"
      >
        <slot />
      </sidebar-list-item-container>
      <div
        v-if="count"
        class="sidebar-list-link-count"
      >
        {{ count }}
      </div>
    </router-link>

    <sidebar-list-dropdown
      v-else
      :is-collapsed="isCollapsed"
      :available-routes="dropdownItems"
      :is-active="isDropdownActive(dropdownName)"
    >
      <template #activator>
        <sidebar-list-item-container :name="name">
          <slot />
        </sidebar-list-item-container>
      </template>
      <template #nestedListItem>
        <sidebar-list-item
          v-for="item in dropdownItems"
          :key="item.name"
          :name="item.name"
          :count="item.count"
          :external-url="item.externalUrl"
          :url="item.routeTo"
          :dropdown-items="item.dropdownItems"
          @click.native="clickLink"
        />
      </template>
    </sidebar-list-dropdown>
  </li>
</template>

<script>
import SidebarListDropdown from '@/containers/sidebar/sidebar-list-dropdown/SidebarListDropdown.vue';
import SidebarListItemContainer from '@/containers/sidebar/sidebar-list-item-container/SidebarListItemContainer.vue';

export default {
  name: 'SidebarListItem',
  components: {
    SidebarListDropdown,
    SidebarListItemContainer,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    externalUrl: {
      type: String,
      default: '',
    },
    url: {
      type: Object,
    },
    dropdownName: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    isCollapsed: {
      type: Boolean,
    },
    viewBox: {
      type: String,
    },
    count: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isActive: false,
      routeMap: {
        EntitiesList: ['EntitiesList', 'EntityMain', 'EntityMembers', 'EntityEmployees', 'EntityContact', 'EntityDocuments'],
        AdminList: ['AdminList', 'CreateAdminFabric'],
        EntityRequests: ['EntityRequests', 'RequestDetailsEntity', 'FamilyRequestDetails', 'NewEntityRequests', 'EntityRequestsHistory', 'SendingQuestion', 'ReceivedQuestion'],
        BranchRequests: ['BranchRequests', 'RequestDetailsBranch', 'NewBranchRequests', 'BranchRequestsHistory', 'BranchSendingQuestion'],
        EditPolicyRequests: ['EditPolicyRequests', 'EditPolicyRequestDetails', 'NewRequests', 'RequestsHistory'],
        EmployeeRequests: ['EmployeeRequests', 'EmployeeRequestDetails', 'EmployeeNewRequests', 'EmployeeRequestsHistory'],
        References: ['References'],
        ObjectionRequests: ['ObjectionRequests', 'NewObjectionRequests', 'ObjectionRequestsHistory', 'ObjectionDetails'],
        TemporaryBankAccountRequests: ['TemporaryBankAccountRequests', 'TemporaryBankAccountRequestDetails', 'TemporaryBankAccountNewRequests', 'TemporaryBankAccountRequestsHistory'],
        EntityActivityDropdown: ['NewEntityActivityRequests', 'EntityActivityRequestsHistory', 'EntityActivityAcceptedRequests', 'EntityActivitiesAcceptedRequestDetails', 'EntityActivitiesHistoryRequestDetails', 'EntityActivitiesRequestDetails'],
        NewEntityActivityRequests: ['NewEntityActivityRequests', 'EntityActivityRequestsHistory', 'EntityActivitiesRequestDetails', 'EntityActivitiesHistoryRequestDetails'],
        EntityActivityAcceptedRequests: ['EntityActivityAcceptedRequests', 'EntityActivitiesAcceptedRequestDetails'],
        FinancialTable: ['FinancialTable'],
        EshopRequests: ['EshopRequests', 'EshopRequestsDetails', 'EshopNewRequests', 'EshopRequestsHistory'],
        BankRequests: ['BankRequests', 'BankRequestsDetails', 'ReceivedBankRequests', 'PreviousBankRequests'],
        UpdatingEntitiesRequests: ['UpdatingEntitiesRequests', 'UpdatingEntitesRequestDetails', 'UpdatingEntitiesNewRequests', 'UpdatingEntitiesRequestsHistory'],
        BankDelegationRequests: ['BankDelegationRequests', 'BankDelegationRequestDetails'],
      },
    };
  },
  methods: {
    isDropdownActive(dropdownName) {
      if (!dropdownName) return false;
      if (!this.routeMap[dropdownName]) {
        console.warn(`Dropdown with name: ${dropdownName} is not described in routeMap. Edit SidebarListItem`);
        return false;
      }
      return this.routeMap[dropdownName].includes(this.$route.name);
    },
    manualSetActive(routeName) {
      if (!routeName.name) return false;
      if (!this.$route.params.adminType) {
        return this.routeMap[routeName.name]?.includes(this.$route.name);
      }
      return this.routeMap[routeName.name]?.includes(this.$route.name)
        && routeName.params.adminType === this.$route.params.adminType;
    },
    clickLink() {
      if (this.$screen['mobile-']) {
        this.$emit('clickLink');
      }
    },

  },
};
</script>

<style src="./styles.scss" lang="scss" />
