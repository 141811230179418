<template>
  <div
    v-show="isVisible && getDownloadList.length > 0"
    class="download-list"
  >
    <div class="download-list__head">
      <div>{{ $t('general.generating.entities.list') }}</div>
      <div class="download-list__actions">
        <div
          class="download-list__btn"
          :class="{'rotate': isBodyShown}"
          @click.prevent="showBody"
        >
          <svg
            viewBox="0 0 32 32"
            height="12"
            width="12"
          >
            <icon-chevron />
          </svg>
        </div>
        <div
          class="download-list__btn"
          @click.prevent="closeDownloadList"
        >
          <svg
            viewBox="0 0 32 32"
            height="12"
            width="12"
          >
            <icon-delete />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="download-list__body"
      :class="{'download-list__body--active': isBodyShown}"
    >
      <div class="download-list__body-wrapper">
        <download-item
          v-for="(item, index) in getDownloadList"
          :key="item.id"
          :index="index"
          :item="item"
          @removeItem="removeItem(item.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import DownloadItem from '@/components/download-item/DownloadItem.vue';

export default {
  name: 'DownloadList',
  components: {
    DownloadItem,
  },
  data() {
    return {
      isBodyShown: true,
    };
  },
  computed: {
    ...mapGetters({
      isVisible: 'downloadList/isVisible',
      getDownloadList: 'downloadList/downloadList',
    }),
  },
  methods: {
    ...mapActions({
      showDownloadList: 'downloadList/showDownloadList',
      hideDownloadList: 'downloadList/hideDownloadList',
      startDownloadByPath: 'downloadList/startDownloadByPath',
      removeAllDownloadItems: 'downloadList/removeAllDownloadItems',
      removeDownloadItem: 'downloadList/removeDownloadItem',
    }),
    ...mapMutations({
      updateItemStatus: 'downloadList/updateItemStatus',
      updateDownloadItem: 'downloadList/updateDownloadItem',
      setGenerating: 'downloadList/setGenerating',
    }),
    showBody() {
      this.isBodyShown = !this.isBodyShown;
    },
    closeDownloadList() {
      this.hideDownloadList();
      this.removeAllDownloadItems();
    },
    removeItem(id) {
      this.removeDownloadItem(id);
    },
    startDownload(item) {
      this.startDownloadByPath(item);
    },
  },
};

</script>

<style src="./styles.scss" lang="scss"/>
