<template>
  <div class="dashboard-header main-container">
    <div class="dashboard-header-left">
      <lang-switcher />
      <user-menu
        slot="header-left"
        :show-go-to-profile="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LangSwitcher from '@/containers/lang-switcher/LangSwitcher.vue';
import UserMenu from '@/containers/user-menu/UserMenu.vue';

export default {
  name: 'DashboardHeader',
  components: {
    LangSwitcher,
    UserMenu,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loading: 'auth/loading',
    }),
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
