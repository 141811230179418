export const CHECK_EMAIL = `mutation CheckEmail($email: String!) {
  checkEmail(email: $email) {
    accountId
    otpToken
  }
}`;

export const CHECK_EMAIL_OTP = `mutation CheckEmailOtp($otp: String!, $otpToken: String!, $accountId: Int!) {
  checkEmailOtp(otp: $otp, otpToken: $otpToken, accountId: $accountId) {
    resetToken
  }
}`;

export const CHANGE_PASSWORD = `mutation ChangePassword($accountId: Int!, $password: String!, $resetToken: String!) {
  changePassword(accountId: $accountId, password: $password, resetToken: $resetToken) {
    accountId
  }
}`;
