import axios from 'axios';
import { ErrorHandler } from '@/helpers';
import {
  fetchBankDelegationRequest,
  fetchBankDelegationRequests,
  acceptBankDelegationRequest,
  rejectBankDelegationRequest,
} from '@/api/bank-delegations.api';
import { RequestStatuses } from '@/constants/enums';
import {
  normalisedDelegationRequest,
  normalisedDelegationRequests,
} from '@/services/bank-delegations.service';

let cancelSource = null;

const mainState = {
  bankDelegationsRequests: [],
  isLoading: false,
  eshopRequestsCount: 0,
  error: null,
  delegationsRequestDetails: {},
  decision: null,
  decisionIsLoading: false,
};

const mainGetters = {
  requestsList: (store) => store.bankDelegationsRequests,
  isLoading: (store) => store.isLoading,
  getDelegationsRequestCount: (store) => store.eshopRequestsCount,
  getError: (store) => store.error,
  getDelegationsRequestDetails: (store) => store.delegationsRequestDetails,
};

const mainMutations = {
  setDelegationsRequests: (store, requests) => {
    store.bankDelegationsRequests = requests;
  },
  setDelegationsRequestsCount: (store, count) => {
    store.eshopRequestsCount = count;
  },
  setLoading: (store, boolean) => {
    store.isLoading = boolean;
  },
  setError: (store, error) => {
    store.error = error;
  },
  setDelegationsRequestDetails: (store, request) => {
    store.delegationsRequestDetails = request;
  },
  setDecision: (store, value) => {
    store.decision = value;
  },
  setDecisionLoading: (store, value) => {
    store.decisionIsLoading = value;
  },
  getDecision: (store) => store.decision,
};

const mainActions = {
  cancelRequest: () => {
    if (cancelSource) {
      cancelSource.cancel('abort request');
    }
  },
  fetchBankDelegationsRequestsList: async ({ commit, rootGetters }, { namespace, statuses }) => {
    commit('setError', null);
    cancelSource = axios.CancelToken.source();
    commit('setLoading', true);
    try {
      const { data } = await fetchBankDelegationRequests({
        page: rootGetters[`${namespace}/selectedPage`],
        size: rootGetters[`${namespace}/selectedSize`],
        statuses,
      }, { cancelToken: cancelSource.token });

      const { requests, meta } = data.listSecurityCheckRequests;
      const normalizedRequests = requests.map((el) => normalisedDelegationRequests(el));
      commit('setDelegationsRequests', normalizedRequests);
      if (meta) {
        commit('bankDelegationsRequestsPagination/setTotalPages', meta.pageCount, { root: true });
        commit('bankDelegationsRequestsPagination/setTotalRecords', meta.recordsCount, { root: true });
        commit('setDelegationsRequestsCount', meta.recordsCount);
      }
    } catch (e) {
      const { error } = ErrorHandler.parseFetchErrors(e);
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  fetchDelegationsRequestDetails: async ({ commit }, requestId) => {
    commit('setLoading', true);
    commit('setError', null);
    try {
      const { data } = await fetchBankDelegationRequest(Number(requestId));
      const normalizedRequest = normalisedDelegationRequest(data.request);
      commit('setDelegationsRequestDetails', normalizedRequest);
    } catch (e) {
      const { error } = ErrorHandler.parseFetchErrors(e);
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  bringTheAction: async ({ commit, dispatch }, { decision, rejectMsg }) => {
    commit('setError', null);
    commit('setDecisionLoading', true);
    if (decision === RequestStatuses.Accepted) {
      await dispatch('acceptRequest', { decision });
    } else {
      await dispatch('rejectRequest', { rejectMsg });
    }
    commit('setDecisionLoading', false);
  },
  rejectRequest: async ({ commit, getters }, { rejectMsg }) => {
    const requestId = getters.getDelegationsRequestDetails.id;
    try {
      const request = await rejectBankDelegationRequest(+requestId, rejectMsg);
      commit('setDecision', request);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setError', error);
    }
  },
  acceptRequest: async ({ commit, getters }) => {
    const requestId = getters.getDelegationsRequestDetails.id;
    try {
      const request = await acceptBankDelegationRequest(+requestId);
      commit('setDecision', request);
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setError', error);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
