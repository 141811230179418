const documentsFields = `
{
  docType,
  fileSize,
  filename,
  mimeType,
  path,
  uid,
}
`;
const BankDelegationRequest = `
bankDelegation {
          createdAt
          createdAtHijri
          delegationLetterNumber
          educationQualification
          email
          employee {
            autoAccept
            createdAt
            customPosition
            employee {
              absherPhone
              birthDateHijri
              birthplace
              createdAt
              degrees
              deletedAt
              educationSpecialization
              educationalCertificateUids
              educationalCertificates ${documentsFields}
              educationalLevel
              email
              employmentPlace
              fatherName
              firstName
              gender
              grandfatherName
              homePhone
              id
              lastName
              nationalAddress {
                additionalNo
                buildingNo
                cityAr
                cityEn
                createdAt
                districtAreaAr
                districtAreaEn
                id
                latitude
                longitude
                streetNameAr
                streetNameEn
                unitNo
                updatedAt
                zipCode
              }
              nationalId
              nidExpireDate
              occupation
              previousExperience
              socialMedias {
                accountName
                id
                type
                userId
              }
              updatedAt
            }
            employeeType
            id
            position
            positionExperienceYears
            previousExperience
            salary
            status
            totalExperience
            unitId
            unitType
            updatedAt
            userId
          }
          employeeId
          endDate
          endHijri
          entityId
          entity {
            entityLicenseNumber
            nameArabic
            nameEnglish
            type
          }
          facebook
          id
          isActual
          isAuto
          meetingMinutes ${documentsFields}
          meetingMinutesUids
          proofDocumentMetadata ${documentsFields}
          proofDocumentUid
          speciality
          startDate
          startHijri
          status
          twitter
          updatedAt
          updatedAtHijri
          userId
        }
`;

export const FIND_BANK_DELEGATION_ADMIN_REQUEST = `query findBankDelegationAdminRequest($id: Int!) {
  findBankDelegationAdminRequest(id: $id) {
     ${BankDelegationRequest}
     adminRole
     createdAt
     id
     message
     requestId
     status
     updatedAt
  }
}
`;

export const LIST_BANK_DELEGATIONS_ADMIN_REQUESTS = `
  query ListBankDelegationsAdminRequests (
    $page: Int!,
    $size: Int!,
    $requestId: Int,
    $status: [String!],
  )
  { listBankDelegationsAdminRequests(
    page: $page
    size: $size
    requestId: $requestId
    status: $status
  ) {
     adminRequests {
        ${BankDelegationRequest}
        adminRole
        createdAt
        id
        message
        requestId
        status
        updatedAt
     }
     meta {
      page,
      pageCount,
      recordsCount,
      size,
    }
  }
}
`;

export const ACCEPT_REQUEST = `mutation AcceptBankDelegationAdminRequest($id: Int!) {
  acceptBankDelegationAdminRequest(id: $id) {
    id
  }
}`;

export const REJECT_REQUEST = `mutation RejectBankDelegationAdminRequest($id: Int!, $message: String) {
  rejectBankDelegationAdminRequest(id: $id, message: $message) {
    id
  }
}`;
