import * as editPolicyAPI from '@/api/edit-policy.api';
import i18n from '@/i18n';
import { ErrorHandler } from '@/helpers';
import { formatDate } from '@/helpers/date';
import {
  LocaleTypes,
  RequestTypes,
  mediaAttachableTypes,
  mediaDocTypes,
  RequestStatuses,
  DatePattern,
} from '@/constants/enums';
import { getDocumentsList } from '@/services/media.service';
import { handleEditPolicyRequest } from '@/services/edit-policy.service';
import { getFullName } from '@/services/membership.service';
import Hijri from 'hijrah-date';

const handleEntityUpdates = (updateList) => (
  updateList.map((item) => ({
    createdAt: new Hijri(item.createdAt).format(DatePattern.Rtl),
    status: item.status,
    type: item.type,
    updatedAt: new Hijri(item.updatedAt).format(DatePattern.Rtl),
    version: item.version,
  }))
);

const mainState = {
  entityChangeDetails: null,
  generalRequestInfo: {},
  loading: false,
  fileLoading: false,
  markedWrongFields: [],
  entityUpdates: [],
};

const mainGetters = {
  getGeneralRequestInfo: ({ generalRequestInfo }) => ({
    requestNumber: generalRequestInfo.id,
    requestType: RequestTypes.EntityPolicy,
    requestStatus: generalRequestInfo.status,
    requestDate: formatDate(generalRequestInfo.updatedAt),
    requesterName: getFullName(generalRequestInfo.user),
    entityType: generalRequestInfo.unit?.type,
    entityName: i18n.locale === LocaleTypes.Ar
      ? generalRequestInfo.unit?.nameArabic
      : generalRequestInfo.unit?.nameEnglish,
    entityId: generalRequestInfo.unitId,
  }),
  isLoading: (state) => state.loading,
  getEntityDetails: (state) => state.entityChangeDetails,
  isFileLoading: (state) => state.fileLoading,
  getMarkedWrongFields: (state) => state.markedWrongFields,
  getLastEntityUpdates: (store) => {
    const latestUpdate = store.entityUpdates[0]?.updatedAt;
    let arr = [];
    try {
      arr = [
        ...new Set((store.entityUpdates.filter(
          (u) => u?.updatedAt === latestUpdate,
        )).map((obj) => JSON.stringify(obj))),
      ].map((str) => JSON.parse(str));
    } catch (error) {
      console.warn(error);
    }
    return arr;
  },
};

const mainMutations = {
  setLoading: (state, value) => {
    state.loading = value;
  },
  setFileLoading: (state, value) => {
    state.fileLoading = value;
  },
  setGeneralRequestInfo: (state, data) => {
    state.generalRequestInfo = data;
  },
  setEntityChangeDetails: (state, data) => {
    state.entityChangeDetails = data;
  },
  resetMarkedWrongFields: (state) => {
    state.markedWrongFields = [];
  },
  setMarkedWrongField: (state, fieldName) => {
    const markedWrongFields = [...state.markedWrongFields];
    markedWrongFields.push(fieldName);

    state.markedWrongFields = markedWrongFields;
  },
  removeMarkedWrongField: (state, fieldName) => {
    const indexFoundField = state.markedWrongFields.indexOf(fieldName);
    if (indexFoundField === -1) {
      return;
    }

    state.markedWrongFields = state.markedWrongFields
      .filter((field) => field !== fieldName);
  },
  setEntityUpdates: (store, list) => {
    store.entityUpdates = list;
  },
};

const mainActions = {
  loadEditPolicyGeneralInfo: async ({ commit }, editRequestId) => {
    let editPolicyGeneralInfo = null;
    try {
      const res = await editPolicyAPI.fetchEditPolicyRequestV2(editRequestId);
      commit('setGeneralRequestInfo', res.data.findEditRequest || {});
    } catch (e) {
      console.warn(e);
      editPolicyGeneralInfo = {
        error: e,
      };
    }
    return editPolicyGeneralInfo;
  },
  loadEditPolicyRequestChanges: async ({ commit, getters, rootGetters }, editRequestId) => {
    let editPolicyRequestChanges = null;
    try {
      const { data } = await editPolicyAPI.fetchEditItems(editRequestId);

      editPolicyRequestChanges = handleEditPolicyRequest(
        {
          data: data.listEditItems.editItems,
          adminRole: rootGetters['auth/adminRole'],
          entityInfo: getters.getGeneralRequestInfo,
        },
      );
      commit('setEntityChangeDetails', editPolicyRequestChanges);
    } catch (e) {
      console.warn(e);
      editPolicyRequestChanges = {
        error: e,
      };
    }
    return editPolicyRequestChanges;
  },
  getBothDataOnCreate: async ({ dispatch, commit }, id) => {
    commit('setLoading', true);
    commit('resetMarkedWrongFields');
    const editPolicyGeneralInfo = await dispatch('loadEditPolicyGeneralInfo', id);
    const editPolicyRequestChanges = await dispatch('loadEditPolicyRequestChanges', id);
    commit('setLoading', false);
    return [editPolicyGeneralInfo, editPolicyRequestChanges];
  },
  updateEditPolicyRequest: async (_, { id, status, rejectReason }) => {
    const UPDATE_EDIT_POLICY_REQUEST_ACTIONS = {
      [RequestStatuses.Rejected]: () => editPolicyAPI.rejectEditPolicyRequest(id, rejectReason),
      [RequestStatuses.Accepted]: () => editPolicyAPI.acceptEditPolicyRequest(id),
      [RequestStatuses.Adjustment]: () => editPolicyAPI.adjustEditPolicyRequest(id, rejectReason),
    };
    try {
      return await UPDATE_EDIT_POLICY_REQUEST_ACTIONS[status]();
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  fetchAcknowledgementFile: async ({ commit }, attachableId) => {
    commit('setFileLoading', true);
    const res = await getDocumentsList(
      attachableId,
      mediaAttachableTypes.Entity,
      mediaDocTypes.EntityAcknowledgmentFile,
      null,
    );
    commit('setFileLoading', false);
    return res;
  },
  getAcceptedUpdatesOfEntity: async ({ commit }, {
    adjustmentType,
    status,
    unitId,
    unitType,
  }) => {
    let updatesList = [];
    try {
      const res = await editPolicyAPI.getAcceptedUpdatesOfEntity({
        adjustmentType,
        status,
        unitId,
        unitType,
      });
      const updates = res.data?.listEditRequests?.editRequests || [];
      updatesList = handleEntityUpdates(updates).sort(
        (a, b) => a.updatedAt - b.updatedAt,
      );
      commit('setEntityUpdates', updatesList);
    } catch (e) {
      updatesList = ErrorHandler.parseFormErrors(e);
    }
    return updatesList;
  },
  async fetchGeneralAssemblyFiles({ commit }, attachableId) {
    commit('setFileLoading', true);
    const res = await getDocumentsList(
      attachableId,
      mediaAttachableTypes.Entity,
      mediaDocTypes.ExtraordinaryMeetingFile,
      null,
    );
    commit('setFileLoading', false);
    return res;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
