import UserRequestsApi from '@/api/user-requests.api';
import ErrorHandler from '@/helpers/errors';

export const bulkMembersCount = async (entityUnits) => {
  try {
    const res = await UserRequestsApi.bulkMembersCount(entityUnits);
    return res?.data.membersCount?.numberOfUnitMembers;
  } catch (err) {
    return ErrorHandler.parseFetchErrors(err);
  }
};
