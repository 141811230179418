import { createAdminAccount, deleteAdminAccount, fetchAdminAccounts } from '@/api/admins.api';
import { abortController, deserializer } from '@/helpers/api';
import { toSnakeCase } from '@/helpers/general';
import { ErrorHandler } from '@/helpers';

const cancelSource = abortController();

const state = {
  admins: [],
  loading: false,
  deleteLoading: false,
};

const getters = {
  admins: (store) => store.admins,
  isLoading: (store) => store.loading,
};

const mutations = {
  setLoading: (store, value) => {
    store.loading = value;
  },
  setDeleteLoading: (store, { id, value }) => {
    store.admins = store.admins.map(
      (r) => ({ ...r, loading: r.id === id ? value : false }),
    );
  },
  setAdmins: (store, admins) => {
    const transformedAdmins = admins.map((admin) => ({
      ...admin,
      showInfo: false,
      fullName: admin.meta?.fullName ? admin.meta.fullName : '-',
    }));
    store.admins = transformedAdmins;
  },
  setError: (store, errors) => {
    store.errors = errors;
  },
  setVisibleInfo: (store, id) => {
    store.admins = store.admins.map(
      (admin) => ({ ...admin, showInfo: admin.id === id ? !admin.showInfo : false }),
    );
  },
};

const actions = {
  cancelRequest: () => {
    if (cancelSource.hasItems()) {
      cancelSource.abort('abort request');
    }
  },
  fetchAdmins: async ({ commit, dispatch }, adminRole) => {
    commit('setLoading', true);
    await dispatch('getAdmins', adminRole);
  },
  getAdmins: async ({ commit, rootGetters }, adminRole) => {
    if (cancelSource.hasItems()) {
      cancelSource.abort(`abort ${adminRole} request`);
    }
    const cancelToken = cancelSource.register();

    try {
      const res = await fetchAdminAccounts(
        toSnakeCase(adminRole),
        rootGetters['adminPagination/selectedPage'],
        rootGetters['adminPagination/selectedSize'],
        { cancelToken },
      );
      const admins = await deserializer(res.data);
      const meta = res.data?.meta;
      if (meta) {
        commit('adminPagination/setTotalPages', meta.page_count, { root: true });
        commit('adminPagination/setTotalRecords', meta.records_count, { root: true });
      }
      commit('setAdmins', admins);
      commit('setLoading', false);
    } catch (e) {
      console.warn(e);
      if (!cancelSource.isCancel(e)) {
        commit('setLoading', false);
      }
    }
  },
  addRequest: async ({ commit }, form) => {
    commit('setLoading', true);
    try {
      const response = await createAdminAccount(form);
      return response.data?.createAdminAccount?.id;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  deleteRequest: async ({
    getters, rootGetters, dispatch, commit,
  }, payload) => {
    commit('setDeleteLoading', { id: payload.adminsId, value: true });
    try {
      await deleteAdminAccount(payload.adminsId);
      if (getters.admins.length < rootGetters['adminPagination/selectedPage']) {
        commit('adminPagination/setPage', 1, { root: true });
      }
      dispatch('getAdmins', toSnakeCase(payload.role));
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setError', error);
    } finally {
      commit('setDeleteLoading', { id: payload.adminsId, value: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
