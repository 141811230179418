import { DialogTypes } from '@/constants/enums';

const state = {
  dialogs: Object.keys(DialogTypes).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {}),
  spinner: false,
  layoutLoader: false,
};

const getters = {
  getDialog: (store) => (type) => store.dialogs[type],
  getSpinner: ({ spinner }) => Boolean(spinner),
  getLayoutLoader: ({ layoutLoader }) => Boolean(layoutLoader),
};

const mutations = {
  openDialog: (store, type) => {
    store.dialogs[type] = true;
  },
  closeDialog: (store, type) => {
    store.dialogs[type] = false;
  },
  setSpinner: (store, value) => {
    store.spinner = value;
  },
  setLayoutLoader: (store, value) => {
    store.layoutLoader = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
