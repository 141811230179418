import { handleNationalAddressUpdate } from '@/helpers/general';

export const normalisedDelegationRequest = (request) => ({
  createdAtHijri: request.createdAtHijri,
  educationQualification: request.educationQualification,
  email: request.email,
  facebook: request.facebook,
  id: request.id,
  speciality: request.speciality,
  status: request.status,
  twitter: request.twitter,
  absherPhone: request.user?.absherPhone,
  DOB: request.user?.birthDateHijri,
  birthplace: request.user?.birthplace,
  educationSpecialization: request.user?.educationSpecialization,
  employmentPlace: request.user?.employmentPlace,
  proofDocumentMetadata: {
    ...request?.proofDocumentMetadata,
    id: Number(...request?.proofDocumentMetadata?.id),
    customUrl: request?.proofDocumentMetadata?.url,
  },
  employeeName: `${request.user?.firstName} ${request.user?.fatherName} ${request.user?.grandfatherName} ${request.user?.lastName}`,
  nationalId: request.user?.nationalId,
  nationality: request.user?.nationality,
  gender: request.user?.gender,
  nidExpireDate: request.user?.nidExpireDate,
  occupation: request.user?.occupation,
  entityNameAr: request.bankDelegationsRequest?.entity?.nameArabic,
  entityNameEn: request.bankDelegationsRequest?.entity?.nameEnglish,
  entityType: request.bankDelegationsRequest?.entity?.type,
  nationalAddress: handleNationalAddressUpdate(request.user?.nationalAddress),
  position: request.bankDelegationsRequest?.employee?.position,
  isAuto: request.bankDelegationsRequest?.isAuto,
  minutesMeetingFile: request?.bankDelegationsRequest?.meetingMinutes || [],
});

export const normalisedDelegationRequests = (request) => ({
  sourceId: request?.sourceId,
  createdAtHijri: request.createdAtHijri,
  entityNameAr: request.bankDelegationsRequest?.entity.nameArabic,
  entityNameEn: request.bankDelegationsRequest?.entity.nameEnglish,
  entityType: request.bankDelegationsRequest?.entity?.type,
  id: request.id,
  status: request.status,
  isAuto: request.bankDelegationsRequest?.isAuto,
  remainingValue: request.remainingData?.remainingValue,
  remainingType: request.remainingData?.remainingType,
});
