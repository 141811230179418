import Vue from 'vue';

// Components
import BaseButton from '@takamol/nawa-library/src/components/base-button/BaseButton.vue';
import FormErrors from '@takamol/nawa-library/src/components/form-errors/FormErrors.vue';
import FormSearch from '@takamol/nawa-library/src/components/form-search/FormSearch.vue';
import HijriDatePicker from '@takamol/nawa-library/src/components/date-picker-hijri/HijriDatePicker.vue';
import Tooltip from '@takamol/nawa-library/src/components/tooltip/Tooltip.vue';
import Loader from '@takamol/nawa-library/src/components/loader/Loader.vue';
import MultipleSelection from '@takamol/nawa-library/src/components/multiple-selection/MultipleSelection.vue';
import FormTextarea from '@takamol/nawa-library/src/components/form-textarea/FormTextarea.vue';
import FormInput from '@takamol/nawa-library/src/components/form-input/FormInput.vue';
import FormCheckbox from '@takamol/nawa-library/src/components/form-checkbox/FormCheckbox.vue';
import ComboSelect from '@takamol/nawa-library/src/components/combo-select/ComboSelect.vue';
import TableView from '@takamol/nawa-library/src/components/table/TableView.vue';
import MainPanel from '@takamol/nawa-library/src/components/main-panel/MainPanel.vue';
import MainPanelFooter from '@takamol/nawa-library/src/components/main-panel-footer/MainPanelFooter.vue';
import RowAligner from '@takamol/nawa-library/src/components/row-aligner/RowAligner.vue';
import Logo from '@takamol/nawa-library/src/components/logo/Logo.vue';
import Modal from '@takamol/nawa-library/src/components/modal/Modal.vue';
import MqLayout from '@takamol/nawa-library/src/components/mq-layout/MqLayout.vue';
import Toast from '@takamol/nawa-library/src/components/toast/Toast.vue';
import FormFile from '@takamol/nawa-library/src/components/form-file/FormFile.vue';
import LayoutLoading from '@takamol/nawa-library/src/components/layout-loading/LayoutLoading.vue';
import FocusHidden from '@takamol/nawa-library/src/components/focus-hidden/FocusHidden.vue';
import Redirect from '@takamol/nawa-library/src/components/redirect/Redirect';
import Pagination from '@takamol/nawa-library/src/components/pagination/Pagination.vue';
import TabSet from '@takamol/nawa-library/src/components/tabset/TabSet.vue';
import FormValidator from '@takamol/nawa-library/src/components/form-validator/FormValidator.vue';
import FormRadio from '@takamol/nawa-library/src/components/form-radio/FormRadio.vue';
import FormRadioGroup from '@takamol/nawa-library/src/components/form-radio-group/FormRadioGroup.vue';
import CustomLabel from '@takamol/nawa-library/src/components/custom-label/CustomLabel.vue';
import ContentGuard from '@takamol/nawa-library/src/containers/content-guard/ContentGuard.vue';
import ServiceTabs from '@takamol/nawa-library/src/components/service-tabs/ServiceTabs.vue';
import EntityTitle from '@takamol/nawa-library/src/components/entity-title/EntityTitle.vue';
import WarnBlock from '@takamol/nawa-library/src/components/warn-block/WarnBlock.vue';
import UploadDocument from '@takamol/nawa-library/src/components/upload-document/UploadDocument.vue';
import BulletItem from '@takamol/nawa-library/src/components/bullet-item/BulletItem.vue';
import FilterRequests from '@takamol/nawa-library/src/components/filter-requests/FilterRequests.vue';
import FilterRequestsEntities from '@takamol/nawa-library/src/components/filter-requests-entities/FilterRequestsEntities.vue';
import RequestActions from '@takamol/nawa-library/src/components/request-actions/RequestActions.vue';
import InfoCard from '@takamol/nawa-library/src/components/info-card/InfoCard.vue';
import StepsSwitcher from '@takamol/nawa-library/src/components/steps-switcher/StepsSwitcher.vue';
import Lottie from 'vue-lottie';
import PdfDocument from '@takamol/nawa-library/src/components/pdf-document/PdfDocument.vue';

// Containers
import QuestionContainer from '@takamol/nawa-library/src/containers/question-container/QuestionContainer.vue';
import QuestionPart from '@takamol/nawa-library/src/containers/question-part/QuestionPart.vue';
import OwnerQuestionPart from '@takamol/nawa-library/src/containers/owner-question-part/OwnerQuestionPart.vue';
import QuestionForm from '@takamol/nawa-library/src/containers/question-form/QuestionForm.vue';
import AreasContainer from '@takamol/nawa-library/src/containers/areas-container/AreasContainer.vue';
// Icons
import IconActionCopy from '@takamol/nawa-library/src/components/icons/IconActionCopy.vue';
import IconNotificationDefault from '@takamol/nawa-library/src/components/icons/IconNotificationDefault.vue';
import IconPencil from '@takamol/nawa-library/src/components/icons/IconPencil.vue';
import IconEyeSmall from '@takamol/nawa-library/src/components/icons/IconEyeSmall.vue';
import IconDownload from '@takamol/nawa-library/src/components/icons/IconDownload.vue';
import IconPdf from '@takamol/nawa-library/src/components/icons/IconPdf.vue';
import IconAsk from '@takamol/nawa-library/src/components/icons/IconAsk.vue';
import IconCeoAdmin from '@takamol/nawa-library/src/components/icons/IconCeoAdmin.vue';
import IconSupervisory from '@takamol/nawa-library/src/components/icons/IconSupervisory.vue';
import IconCenterAdmin from '@takamol/nawa-library/src/components/icons/IconCenterAdmin.vue';
import IconHrsdAdmin from '@takamol/nawa-library/src/components/icons/IconHrsdAdmin.vue';
import IconHrsdEmployee from '@takamol/nawa-library/src/components/icons/IconHrsdEmployee.vue';
import IconSpecialized from '@takamol/nawa-library/src/components/icons/IconSpecialized.vue';
import IconReferences from '@takamol/nawa-library/src/components/icons/IconReferences.vue';
import IconBankAccountRequests from '@takamol/nawa-library/src/components/icons/IconBankAccountRequests.vue';
import IconCenterEmployeeAdmin from '@takamol/nawa-library/src/components/icons/IconCenterEmployeeAdmin.vue';
import IconCenterFinancialSafetyEmployee from '@takamol/nawa-library/src/components/icons/IconCenterFinancialSafetyEmployee.vue';
import IconCharteredAccountantOfficesManagement from '@takamol/nawa-library/src/components/icons/IconCharteredAccountantOfficesManagement.vue';
import IconEditRequest from '@takamol/nawa-library/src/components/icons/IconEditRequest.vue';
import IconEmployeeRequest from '@takamol/nawa-library/src/components/icons/IconEmployeeRequest.vue';
import IconEntityActivitiesRequests from '@takamol/nawa-library/src/components/icons/IconEntityActivitiesRequests.vue';
import IconGovernanceAdmin from '@takamol/nawa-library/src/components/icons/IconGovernanceAdmin.vue';
import IconGovernanceHrsdEmployee from '@takamol/nawa-library/src/components/icons/IconGovernanceHrsdEmployee.vue';
import IconGovernanceCenterEmployee from '@takamol/nawa-library/src/components/icons/IconGovernanceCenterEmployee.vue';
import IconProfile from '@takamol/nawa-library/src/components/icons/IconProfile.vue';
import IconArrowLocation from '@takamol/nawa-library/src/components/icons/IconArrowLocation.vue';
import IconEyeSmallClose from '@takamol/nawa-library/src/components/icons/IconEyeSmallClose.vue';
import IconClock from '@takamol/nawa-library/src/components/icons/IconClock.vue';
import IconEshopRequests from '@takamol/nawa-library/src/components/icons/IconEshopRequests.vue';
import IconList from '@takamol/nawa-library/src/components/icons/IconList.vue';
import IconCharteredOfficeAdmin from '@takamol/nawa-library/src/components/icons/IconCharteredOfficeAdmin.vue';
import IconChevron from '@takamol/nawa-library/src/components/icons/IconChevron.vue';
import IconBeneficiaryAdmin from '@takamol/nawa-library/src/components/icons/IconBeneficiaryAdmin.vue';
import IconDelegateBeneficiaryRequest from '@takamol/nawa-library/src/components/icons/IconDelegateBeneficiaryRequest.vue';
import IconInspectionVisit from '@takamol/nawa-library/src/components/icons/IconInspectionVisit.vue';
import IconCalendar from '@takamol/nawa-library/src/components/icons/IconCalendar.vue';
import IconQuestion from '@takamol/nawa-library/src/components/icons/IconQuestion.vue';
import IconReceived from '@takamol/nawa-library/src/components/icons/IconReceived.vue';
import IconSearch from '@takamol/nawa-library/src/components/icons/IconSearch.vue';
import IconGCE from '@takamol/nawa-library/src/components/icons/IconGCE.vue';
import IconReceivedBranchRequest from '@takamol/nawa-library/src/components/icons/IconReceivedBranchRequest.vue';
import IconReject from '@takamol/nawa-library/src/components/icons/IconReject.vue';
import IconPrevious from '@takamol/nawa-library/src/components/icons/IconPrevious.vue';
import IconNawa from '@takamol/nawa-library/src/components/icons/IconNawa.vue';
import IconDoc from '@takamol/nawa-library/src/components/icons/IconDoc.vue';
import IconTrash from '@takamol/nawa-library/src/components/icons/IconTrash.vue';
import IconDelete from '@takamol/nawa-library/src/components/icons/IconDelete.vue';
import IconCustomEdit from '@takamol/nawa-library/src/components/icons/IconCustomEdit.vue';
import IconComsRight from '@takamol/nawa-library/src/components/icons/IconComsRight.vue';
import IconComsLeft from '@takamol/nawa-library/src/components/icons/IconComsLeft.vue';
import IconCompleted from '@takamol/nawa-library/src/components/icons/IconCompleted.vue';
import IconAuditOfficer from '@takamol/nawa-library/src/components/icons/IconAuditOfficer.vue';
import IconAppointingBankDelegations from '@takamol/nawa-library/src/components/icons/IconAppointingBankDelegations.vue';
import IconPaused from '@takamol/nawa-library/src/components/icons/IconPaused.vue';

export const setupGlobalComponents = () => {
  Vue.component('BaseButton', BaseButton);
  Vue.component('PdfDocument', PdfDocument);
  Vue.component('FormErrors', FormErrors);
  Vue.component('Tooltip', Tooltip);
  Vue.component('Loader', Loader);
  Vue.component('MultipleSelection', MultipleSelection);
  Vue.component('FormTextarea', FormTextarea);
  Vue.component('FormValidator', FormValidator);
  Vue.component('FormInput', FormInput);
  Vue.component('FormCheckbox', FormCheckbox);
  Vue.component('ComboSelect', ComboSelect);
  Vue.component('TableView', TableView);
  Vue.component('MainPanel', MainPanel);
  Vue.component('MainPanelFooter', MainPanelFooter);
  Vue.component('RowAligner', RowAligner);
  Vue.component('Logo', Logo);
  Vue.component('Modal', Modal);
  Vue.component('MqLayout', MqLayout);
  Vue.component('Toast', Toast);
  Vue.component('FormFile', FormFile);
  Vue.component('LayoutLoading', LayoutLoading);
  Vue.component('FocusHidden', FocusHidden);
  Vue.component('FormSearch', FormSearch);
  Vue.component('HijriDatePicker', HijriDatePicker);
  Vue.component('Redirect', Redirect);
  Vue.component('Pagination', Pagination);
  Vue.component('TabSet', TabSet);
  Vue.component('FormRadio', FormRadio);
  Vue.component('CustomLabel', CustomLabel);
  Vue.component('FormRadioGroup', FormRadioGroup);
  Vue.component('ContentGuard', ContentGuard);
  Vue.component('ServiceTabs', ServiceTabs);
  Vue.component('EntityTitle', EntityTitle);
  Vue.component('WarnBlock', WarnBlock);
  Vue.component('UploadDocument', UploadDocument);
  Vue.component('BulletItem', BulletItem);
  Vue.component('FilterRequests', FilterRequests);
  Vue.component('FilterRequestsEntities', FilterRequestsEntities);
  Vue.component('RequestActions', RequestActions);
  Vue.component('QuestionContainer', QuestionContainer);
  Vue.component('QuestionPart', QuestionPart);
  Vue.component('OwnerQuestionPart', OwnerQuestionPart);
  Vue.component('QuestionForm', QuestionForm);
  Vue.component('InfoCard', InfoCard);
  Vue.component('FormSearch', FormSearch);
  Vue.component('StepsSwitcher', StepsSwitcher);
  Vue.component('Lottie', Lottie);
  Vue.component('AreasContainer', AreasContainer);
};

export const setupGlobalIcons = () => {
  Vue.component('IconActionCopy', IconActionCopy);
  Vue.component('IconNotificationDefault', IconNotificationDefault);
  Vue.component('IconPencil', IconPencil);
  Vue.component('IconEyeSmall', IconEyeSmall);
  Vue.component('IconDownload', IconDownload);
  Vue.component('IconPdf', IconPdf);
  Vue.component('IconAsk', IconAsk);
  Vue.component('IconSupervisory', IconSupervisory);
  Vue.component('IconCenterAdmin', IconCenterAdmin);
  Vue.component('IconCeoAdmin', IconCeoAdmin);
  Vue.component('IconHrsdAdmin', IconHrsdAdmin);
  Vue.component('IconHrsdEmployee', IconHrsdEmployee);
  Vue.component('IconSpecialized', IconSpecialized);
  Vue.component('IconReferences', IconReferences);
  Vue.component('IconBankAccountRequests', IconBankAccountRequests);
  Vue.component('IconCenterEmployeeAdmin', IconCenterEmployeeAdmin);
  Vue.component('IconCenterFinancialSafetyEmployee', IconCenterFinancialSafetyEmployee);
  Vue.component('IconCharteredAccountantOfficesManagement', IconCharteredAccountantOfficesManagement);
  Vue.component('IconEditRequest', IconEditRequest);
  Vue.component('IconEmployeeRequest', IconEmployeeRequest);
  Vue.component('IconEntityActivitiesRequests', IconEntityActivitiesRequests);
  Vue.component('IconGovernanceAdmin', IconGovernanceAdmin);
  Vue.component('IconGovernanceHrsdEmployee', IconGovernanceHrsdEmployee);
  Vue.component('IconGovernanceCenterEmployee', IconGovernanceCenterEmployee);
  Vue.component('IconProfile', IconProfile);
  Vue.component('IconArrowLocation', IconArrowLocation);
  Vue.component('IconEyeSmallClose', IconEyeSmallClose);
  Vue.component('IconClock', IconClock);
  Vue.component('IconEshopRequests', IconEshopRequests);
  Vue.component('IconList', IconList);
  Vue.component('IconCharteredOfficeAdmin', IconCharteredOfficeAdmin);
  Vue.component('IconChevron', IconChevron);
  Vue.component('IconBeneficiaryAdmin', IconBeneficiaryAdmin);
  Vue.component('IconDelegateBeneficiaryRequest', IconDelegateBeneficiaryRequest);
  Vue.component('IconInspectionVisit', IconInspectionVisit);
  Vue.component('IconCalendar', IconCalendar);
  Vue.component('IconQuestion', IconQuestion);
  Vue.component('IconReceived', IconReceived);
  Vue.component('IconSearch', IconSearch);
  Vue.component('IconDoc', IconDoc);
  Vue.component('IconDelete', IconDelete);
  Vue.component('IconReject', IconReject);
  Vue.component('IconCompleted', IconCompleted);
  Vue.component('IconTrash', IconTrash);
  Vue.component('IconGCE', IconGCE);
  Vue.component('IconReceivedBranchRequest', IconReceivedBranchRequest);
  Vue.component('IconPrevious', IconPrevious);
  Vue.component('IconNawa', IconNawa);
  Vue.component('IconCustomEdit', IconCustomEdit);
  Vue.component('IconComsRight', IconComsRight);
  Vue.component('IconComsLeft', IconComsLeft);
  Vue.component('IconAuditOfficer', IconAuditOfficer);
  Vue.component('IconAppointingBankDelegations', IconAppointingBankDelegations);
  Vue.component('IconPaused', IconPaused);
};
