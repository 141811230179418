import { ErrorHandler } from '@/helpers';
import * as objectionsAPI from '@/api/objection-requests.api';

export const listObjectionRequests = async (
  status, adminRole, page = 1, size = 10, config, abortControllerName) => {
  try {
    const res = await objectionsAPI.listObjectionRequests(
      status,
      adminRole,
      page,
      size,
      config,
    );
    return res?.data?.objectionAdminRequests;
  } catch (e) {
    if (e.message === abortControllerName) {
      return {
        error: e.message,
      };
    }
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const fetchObjectionDetails = async (objectionId) => {
  try {
    const res = await objectionsAPI.fetchObjectionDetails(objectionId);
    return res?.data?.findObjectionAdminRequest;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const fetchAdminObjectionRecommendation = async (unitId, adminRole) => {
  try {
    const res = await objectionsAPI.fetchAdminObjectionRecommendation(unitId, adminRole);
    return res?.data?.objectionAdminRequests?.establishmentAdminRequests;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const acceptObjectionAdminRequest = async (objectionId, message) => {
  try {
    const res = await objectionsAPI.acceptObjectionAdminRequest(objectionId, message);
    return res?.data?.acceptObjectionAdminRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const rejectObjectionAdminRequest = async (objectionId, message) => {
  try {
    const res = await objectionsAPI.rejectObjectionAdminRequest(objectionId, message);
    return res?.data?.rejectObjectionAdminRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
