export const FETCH_FAMILY_FUND_REQUEST = `query findEstablishmentAdminRequest($id: Int!) {
  findEstablishmentAdminRequest(id: $id) {
    actionExpectation
    admin {
      email
    }
    adminRole
    createdAt
    departmentId
    lockRequest {
      accountId
      requestId
      requestType
      status
    }
    id
    message
    requestType
    remainingData {
      remainingType
      remainingValue
    }
    status
    unit {
      ... on Entity {
        type
        status
        nameArabic
        nameEnglish
        id
        foundForSelf
        foundFor
        goalIds
        families{
          name
          relation
        }
        classificationDetails {
          secondSubClassificationId,
        },
        goals {
          ... on FamilyTrustGoal {
            arTitle
            enTitle
            id
          }
        }
        entityNationalAddress {
          additionalNumber
          buildingNumber
          postCode
          streetName
        }
        cityUid
        regionCode
        region {
          arTitle
          enTitle
        }
        city {
          arTitle
          enTitle
        }
      }
    }
    unitId
    unitType
    updatedAt
  }
}`;

export const FETCH_FAMILY_FUND_SUBSCRIPTION = `query findFamilyFundSubscriptionByEntityIdRequest($entityId: Int!) {
    findFamilyFundSubscriptionByEntityIdRequest(entityId: $entityId) {
      amount
      createdAt
      entityId
      id
      updatedAt
    }
  }
`;

export const LIST_ENTITY_BANKS = `query ListEntitiesBanks($unitType: UnitType!, $unitId: Int!) {
  entitiesBanks(unitType: $unitType, unitId: $unitId) {
    bankBranchName
    bankId
    id
    unitId
    unitType
  }
}`;

export const ACCEPT_REQUEST = `mutation acceptEstablishmentAdminRequest($establishmentAdminRequestAttributes: EstablishmentAdminRequestsAcceptInput!) {
  acceptEstablishmentAdminRequest(establishmentAdminRequestAttributes: $establishmentAdminRequestAttributes) {
    id
    status
    admin {
      id
      userId
      meta {
        departments
        accountantOfficeId
      }
      email
      phone
    }
  }
}`;

export const REJECT_REQUEST = `mutation rejectEstablishmentAdminRequest($establishmentAdminRequestAttributes: EstablishmentAdminRequestsRejectInput!) {
  rejectEstablishmentAdminRequest(establishmentAdminRequestAttributes: $establishmentAdminRequestAttributes) {
    id
    status
    admin {
      id
      userId
      meta {
        departments
        accountantOfficeId
      }
      email
      phone
    }
  }
}`;

export const LOCK_REQUEST = `mutation createLockRequest($lockRequestCreateAttributes: LockRequestCreateInput!) {
  createLockRequest(lockRequestCreateAttributes: $lockRequestCreateAttributes) {
    accountId
    requestId
    requestType
  }
}`;

export const REJECT_BY_SPECIALIZED = `mutation rejectBySpecializedUserRequest($userRequestRejectBySpecializedAttributes: UserRequestRejectBySpecializedInput!) {
  rejectBySpecializedUserRequest(userRequestRejectBySpecializedAttributes: $userRequestRejectBySpecializedAttributes) {
    id
    status
  }
}`;

export const ACCEPT_BY_SPECIALIZED = `mutation acceptBySpecializedUserRequest($userRequestAcceptAttributes: UserRequestsAcceptBySpecializedInput!) {
  acceptBySpecializedUserRequest(userRequestAcceptAttributes: $userRequestAcceptAttributes) {
    id
    status
  }
}`;
