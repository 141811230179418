import { LocaleTypes } from '@/constants/enums';

export const getEnvVar = (value) => {
  // eslint-disable-next-line
  const config = window._config || {};
  return config[value] || process.env[`VUE_APP_${value}`];
};

// env variables
export const API_URL = getEnvVar('GATEWAY_URL');
export const PROJECT_ENV = getEnvVar('PROJECT_ENV');
export const FINANCIAL_SPA = getEnvVar('FINANCIAL_SPA');
export const BENEFICIARY_SPA = getEnvVar('BENEFICIARY_SPA');
export const INSPECTION_VISIT_SPA = getEnvVar('INSPECTION_VISIT_SPA');
export const MANAGE_MEETINGS_SPA = getEnvVar('MANAGE_MEETINGS_SPA');
export const SENTRY_DNS = getEnvVar('SENTRY_DNS');

// general variables
export const SUPPORTED_LOCALES = [LocaleTypes.Ar, LocaleTypes.En];
export const DEFAULT_LOCALE = LocaleTypes.Ar;
export const IS_PROD = PROJECT_ENV === 'prod';
export const IS_DEMO = PROJECT_ENV === 'demo';
export const ENTITY_BANNED_WORDS = [
  'إقليمي',
  'إقليمية',
  'اميري',
  'اميرية',
  'أهلية',
  'بر',
  'تعاوني',
  'تعاونية',
  'جمعيه',
  'خليجي',
  'خليجية',
  'خيري',
  'خيرية',
  'دولي',
  'دولية',
  'سعودي',
  'السعودية',
  'سعودية',
  'صندوق',
  'عالمي',
  'عالمية',
  'عربي',
  'عربيه',
  'فرع',
  'مؤسسة',
  'مركز',
  'مشروع',
  'مكتب',
  'ملكي',
  'ملكية',
  'وطني',
  'وطنية',
  'الإقليميه',
  'الإقليمية',
  'الإقليمي',
  'إقليميه',
  'الاميري',
  'الأميري',
  'اميرية',
  'الاميرية',
  'الاميريه',
  'الأميرية',
  'الأميريه',
  'اميريه',
  'أميريه',
  'الأهليه',
  'أهليه',
  'الأهلية',
  'البر',
  'التعاوني',
  'تعاونيه',
  'التعاونيه',
  'التعاونية',
  'الجمعيه',
  'الجمعية',
  'جمعية',
  'الخليجي',
  'الخيري',
  'خيريه',
  'الخيرية',
  'الخيريه',
  'الدولي',
  'الدولية',
  'الدوليه',
  'السعودي',
  'السعوديه',
  'سعوديه',
  'الصندوق',
  'العالمي',
  'عالميه',
  'العالميه',
  'العالمية',
  'العربي',
  'العربية',
  'العربيه',
  'عربية',
  'الفرع',
  'المؤسسة',
  'المؤسسه',
  'مؤسسه',
  'المركز',
  'المشروع',
  'المكتب',
  'الملكي',
  'الملكيه',
  'الملكية',
  'ملكيه',
  'الوطني',
  'الوطنية',
  'الوطنيه',
  'وطنيه',
];
