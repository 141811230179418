export const GET_ADMIN_PROFILE = `query GetUserProfile {
  profile {
    accountId
    departments
    role
    updatedAt
  }
}`;

export const CREATE_ADMIN_ACCOUNT = `mutation createAdminAccount($adminAccount: AdminAccountInput!) {
  createAdminAccount(adminAccount: $adminAccount) {
    id
  }
}`;
