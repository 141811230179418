import { formatDate } from '@/helpers/date';
import { ErrorHandler } from '@/helpers';
import * as requestsApi from '@/api/requests.api';

export const normalizeRequests = (request = {}) => ({
  isSpecialized: request.specialized,
  foundingMember: request.foundingMember,
  numberOfShares: request.numberOfShares,
  customPosition: request.customPosition,
  createdAt: formatDate(request.createdAt),
  id: request.id,
  email: request?.email,
  membershipId: request.membershipId,
  status: request.status,
  familyRelation: request.familyRelation,
  member: request.member,
  position: request.position,
  role: request.role,
  governmental: request.governmental,
  showInfo: false,
  financeContributionAmount: request.financeContributionAmount,
  memberId: request.memberId,
  delegatorProof: request.delegatorProof,
});

export const findAdminRequest = async (id) => {
  try {
    const res = await requestsApi.findAdminRequest(id);
    return res?.data?.findEstablishmentAdminRequest;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const resetActionExpectationStatus = async (id) => {
  try {
    const res = await requestsApi.resetActionExpectationStatus(id);
    return res?.data;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const normalizeEntityLocations = (entityLocations) => {
  if (!entityLocations?.length) return [];
  return entityLocations.map((location) => ({
    regionCode: location?.regionCode,
    regionName: {
      arTitle: location.region.arTitle,
      enTitle: location.region.enTitle,
    },
    selectedCities: location?.cityUids || [],
    allCitiesSelected: !location?.cityUids?.length,
  }));
};
