import { formatDate } from '@/helpers/date';

export const normalizeEshopRequestList = (request = {}) => ({
  id: request.id,
  status: request.status,
  autoAccepted: request.autoAccepted,
  entityNameAr: request?.websiteLicense?.unit?.nameArabic,
  entityNameEn: request?.websiteLicense?.unit?.nameEnglish,
  entityType: request?.websiteLicense?.unit?.type,
  updatedAt: request.updatedAt ? formatDate(request.updatedAt) : formatDate(request.createdAt),
  requestDate: formatDate(request.requestDate),
  remainingType: request?.remainingData?.remainingType,
  remainingValue: request?.remainingData?.remainingValue,
  license: { ...request?.websiteLicense },
});
