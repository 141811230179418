import { GraphQLRequest } from '@/setup/axios';
import {
  LIST_BANK_DELEGATIONS_ADMIN_REQUESTS,
  ACCEPT_REQUEST,
  REJECT_REQUEST, FIND_BANK_DELEGATION_ADMIN_REQUEST,
} from '@/api/graphql/bank-delegation-requests.gql';

export function findBankDelegationRequest(bankDelegationId) {
  return GraphQLRequest({
    query: FIND_BANK_DELEGATION_ADMIN_REQUEST,
    variables: {
      id: bankDelegationId,
    },
  });
}

export function listBankDelegationsAdminRequests({
  page,
  requestId,
  size,
  statuses,
}, config) {
  return GraphQLRequest({
    query: LIST_BANK_DELEGATIONS_ADMIN_REQUESTS,
    variables: {
      page: Number(page) || 1,
      size: Number(size) || 10,
      requestId: Number(requestId) || undefined,
      status: statuses && Array.isArray(statuses) ? statuses.map(String) : undefined,
    },
  }, config);
}

export function acceptBankDelegationRequest(id) {
  return GraphQLRequest({
    query: ACCEPT_REQUEST,
    variables: {
      id,
    },
  });
}

export function rejectBankDelegationRequest(id, message = null) {
  return GraphQLRequest({
    query: REJECT_REQUEST,
    variables: {
      id,
      message,
    },
  });
}
