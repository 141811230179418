import ErrorHandler from '@takamol/nawa-library/src/helpers/errors';

export const generatePDF = (data, filename, mime, bom) => {
  const blobData = (typeof bom !== 'undefined') ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = (window.URL && window.URL.createObjectURL)
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

function saveFile(url) {
  return new Promise((resolve, reject) => {
    // Get file name from url.
    const XHR = new XMLHttpRequest();
    XHR.responseType = 'blob';
    XHR.onload = () => {
      resolve(XHR);
    };
    XHR.onerror = reject;
    XHR.open('GET', url);
    XHR.send();
  }).then((xhr) => {
    const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0];
    const a = document.createElement('a');
    a.id = filename;
    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
    a.download = filename; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.getElementById(filename).remove();
    return xhr;
  });
}

export const downloadFilesByUrls = async (urls) => {
  let result;
  try {
    result = await Promise.all(urls.map(saveFile));
  } catch (e) {
    result = ErrorHandler.parseFetchErrors(e);
  }
  return result;
};
