export const GET_ESHOP_REQUESTS = `query WebsiteLicenseCollection($page: Int!, $size: Int!, $status: [WebsiteLicenseStatus!]) {
  websiteLicenseAdminRequests(page: $page, size: $size, status: $status) {
    websiteLicenseAdminRequests {
      createdAt
      remainingData {
        remainingType
        remainingValue
      }
      id
      status
      autoAccepted
      websiteLicense {
        unit {
            ... on Entity {
              nameArabic
              nameEnglish
              entityUid
              entityLicenseNumber
              type
              city {
                arTitle
                enTitle
              }
              region {
                arTitle
                enTitle
              }
            }
        }
        id
        iban
        bankId
        websiteUrl
        status
        createdAt
        unitId
        unitType
        recommendationNotes {
          ... on RecommendationNote {
            reason
            status
          }
        }
        managers {
          name
          position
          absherPhone
        }
        saudiNetworkRegistrationCertificates {
          id: uid
          uid
          name: filename
          size: fileSize
          url: path
          type: mimeType
        }
        approvingEstablishmentWebsites {
          id: uid
          uid
          name: filename
          size: fileSize
          url: path
          type: mimeType
        }
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const GET_ESHOP_REQUEST_BY_LICENSE_REQUEST_ID = `query FindWebsiteLicense($id: Int!) {
  findWebsiteLicenseAdminRequest(id: $id) {
    adminRole
    autoAccepted
    createdAt
    id
    remainingType
    remainingValue
    status
    unitId
    unitType
    updatedAt
    websiteLicense {
      unitId
      unitType
      id
      iban
      bankId
      websiteUrl
      status
      createdAt
      unit {
        ... on Entity {
          nameArabic
          nameEnglish
          entityUid
          entityLicenseNumber
          type
          city {
            arTitle
            enTitle
          }
          region {
            arTitle
            enTitle
          }
        }
      }
      recommendationNotes {
        ... on RecommendationNote {
          reason
          status
        }
      }
      managers {
        name
        position
        absherPhone
      }
      saudiNetworkRegistrationCertificates {
        id: uid
        uid
        name: filename
        size: fileSize
        url: path
        type: mimeType
      }
      approvingEstablishmentWebsites {
        id: uid
        uid
        name: filename
        size: fileSize
        url: path
        type: mimeType
      }
    }
  }
}`;
export const SEND_REJECT_ON_LICENSE_REQUEST = `mutation RejectWebsiteLicense($id: Int!, $rejectReason: String!) {
  rejectWebsiteLicenseAdminRequest(id: $id, rejectReason: $rejectReason) {
    id
  }
}`;

export const SEND_ACCEPT_ON_LICENSE_REQUEST = `mutation AcceptWebsiteLicense($id: Int!) {
  acceptWebsiteLicenseAdminRequest(id: $id) {
    id
  }
}`;
