<template>
  <div class="progress-wrapper">
    <div class="progress-bar">
      <div class="progress-bar__background" />
      <div
        v-if="!isDownloading"
        :class="`progress-bar__track progress-bar__track--${status}`"
      />
      <transition
        v-if="isDownloading"
        appear
        @before-appear="beforeEnter"
        @after-appear="enter"
      >
        <div
          :class="getTrackClass"
        />
      </transition>
    </div>
  </div>
</template>

<script>

const ItemStatuses = {
  Pending: 'pending',
  Downloading: 'downloading',
  Completed: 'completed',
  Failed: 'failed',
};

export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      type: Number,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    getTrackClass() {
      const statusClass = this.status ? ` progress-bar__track--${this.status}` : '';
      return `progress-bar__track${statusClass}`;
    },
    isDownloading() {
      return this.status === ItemStatuses.Downloading;
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.width = 0;
    },
    enter(el) {
      el.style.width = `${this.percentage}%`;
      el.style.transition = 'width .5s linear';
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
