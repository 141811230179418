export const LIST_OBJECTION_REQUESTS = `query objectionAdminRequests($status: [EstablishmentAdminRequestStatus!], $adminRole: [AccountRole!], $requestType: [EstablishmentAdminRequestType!], $page: Int!, $unitId: Int, $size: Int!, $unitType: UnitType) {
  objectionAdminRequests(requestType: $requestType, status: $status, adminRole: $adminRole, page: $page, size: $size, unitType: $unitType, unitId: $unitId) {
    establishmentAdminRequests {
      actionExpectation
      adminRole
      autoAccepted
      createdAt
      departmentId
      id
      lockRequest {
        accountId
        requestId
        requestType
        status
      }
      message
      remainingData {
        expireAt
        remainingType
        remainingValue
        startDate
      }
      requestType
      status
      objectionUnit: unit {
        ... on ObjectionRequest {
            entity {
              id
              nameArabic
              foundForSelf
            }
            user {
              nationalId
              birthDateHijri
            }
            absherPhone
            description
            email
            entityAnnouncementId
            entityId
            familyRelationUid
            id
            objectorName
            status
            userId
        }
      }
      unitId
      unitType
      updatedAt
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const FIND_OBJECTION_REQUEST = `query findObjectionAdminRequest($id: Int!) {
  findObjectionAdminRequest(id: $id) {
    actionExpectation
    adminRole
    autoAccepted
    createdAt
    departmentId
    id
    lockRequest {
      accountId
      requestId
      requestType
      status
    }
    message
    remainingData {
      expireAt
      remainingType
      remainingValue
      startDate
    }
    requestType
    status
    objectionUnit: unit {
      ... on ObjectionRequest {
          entity {
            id
            nameArabic
            goals {
            ... on FamilyTrustGoal {
                arTitle
                enTitle
                id
              }
            }
            foundForSelf
            city {
              arTitle
              enTitle
              regionCode
              uid
            }
            region {
              arTitle
              code
              enTitle
            }
          }
          user {
            nationalId
            birthDateHijri
          }
          absherPhone
          description
          email
          entityAnnouncementId
          entityId
          familyRelationUid
          id
          objectorName
          status
          userId
      }
    }
    unitId
    unitType
    updatedAt
  }
}`;

export const ACCEPT_OBJECTION_REQUEST = `mutation acceptObjectionAdminRequest($objectionAdminRequestAttributes: ObjectionAdminRequestsAcceptInput!) {
  acceptObjectionAdminRequest(objectionAdminRequestAttributes: $objectionAdminRequestAttributes) {
    status
    id
  }
}`;

export const REJECT_OBJECTION_REQUEST = `mutation rejectObjectionAdminRequest($objectionAdminRequestAttributes: ObjectionAdminRequestsRejectInput!) {
  rejectObjectionAdminRequest(objectionAdminRequestAttributes: $objectionAdminRequestAttributes) {
    status
    id
  }
}`;
