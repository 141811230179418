<template>
  <div
    v-click-outside="closeMobileMenu"
    class="user-menu"
    :class="{ open: isOpen }"
    @keyup.enter="openMenu"
    @mouseenter="openMenu"
    @mouseleave="closeMenu"
  >
    <button
      id="menu"
      type="button"
      class="user-menu-profile"
      @click.stop="handleClick"
    >
      <svg
        viewBox="0 0 32 32"
        class="user-menu-ico"
      >
        <icon-profile />
      </svg>
      <span class="user-menu-label">{{ this.$t('space.profile') }}</span>
    </button>
    <div class="user-menu-wrap">
      <ul class="user-menu-list">
        <li
          v-if="showGoToProfile && this.$screen['mobile-']"
          class="user-menu-item"
        >
          <button
            id="dashboard"
            type="button"
            class="user-menu-btn"
          >
            <router-link
              :to="{name: 'Dashboard'}"
              tag="span"
              class="user-menu-label"
            >
              {{ this.$t('space.profile') }}
            </router-link>
          </button>
        </li>
        <li class="user-menu-item">
          <button
            id="edit-profile"
            class="user-menu-btn"
          >
            <span class="user-menu-label">{{ this.$t('space.edit') }}</span>
          </button>
        </li>
        <li class="user-menu-item">
          <button
            id="logout"
            type="button"
            class="user-menu-btn"
            @click="logout"
          >
            <span
              class="user-menu-label"
            >{{ this.$t('space.logout') }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'UserMenu',
  props: {
    showGoToProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    ...mapActions({
      logoutIndividual: 'auth/logoutIndividual',
      hideDownloadList: 'downloadList/hideDownloadList',
    }),
    ...mapMutations({
      resetEntityFilters: 'entities/resetEntityFilters',
    }),
    openMenu() {
      if (!this.$screen['mobile-']) {
        this.isOpen = true;
      }
    },
    closeMobileMenu() {
      this.isOpen = false;
    },
    closeMenu() {
      if (!this.$screen['mobile-']) {
        this.isOpen = false;
      }
    },
    handleClick() {
      if (this.$screen['mobile-']) {
        this.isOpen = !this.isOpen;
      } else if (this.$route.name !== 'Dashboard') {
        this.$router.push({ name: 'Dashboard' });
      }
    },
    logout() {
      this.logoutIndividual();
      this.hideDownloadList();
      this.resetEntityFilters();
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
