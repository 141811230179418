import { setupLookup } from '@takamol/nawa-library/src/services/lookup.service';
import i18n from '@/i18n';
import * as lookupAPI from '@/api/lookup.api';
import { MembershipEntityTypes, EntityTypes, LocaleTypes } from '@/constants/enums';
import { ErrorHandler } from '@/helpers';

const lookupName = 'nawa/lookup';

export const fetchLookup = async () => {
  try {
    const res = await lookupAPI.fetchLookupData();
    localStorage.setItem(lookupName, JSON.stringify(res.data));
  } catch (e) {
    console.warn(e);
  }
};

export function getPositionList(
  unitType = MembershipEntityTypes.Entity,
  entityType = EntityTypes.CooperativeAssociation,
) {
  try {
    const { membershipPositions } = JSON.parse(localStorage.getItem(lookupName));
    return Object.freeze(membershipPositions[unitType][entityType]);
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function getPosition(
  uid,
  unitType = MembershipEntityTypes.Entity,
  entityType = EntityTypes.CooperativeAssociation,
) {
  try {
    const { membershipPositions } = JSON.parse(localStorage.getItem(lookupName));
    return Object.freeze(membershipPositions[unitType][entityType].find((el) => el.uid === uid));
  } catch (e) {
    console.warn(e);
    return {};
  }
}

export function getRegions() {
  try {
    const { regions } = JSON.parse(localStorage.getItem(lookupName));
    return Object.freeze(regions.map((r) => ({
      code: r.code,
      arTitle: r.arTitle,
      enTitle: r.enTitle,
    })));
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function getRegion(regionCode) {
  try {
    const { regions } = JSON.parse(localStorage.getItem(lookupName));
    const region = regions.find((r) => r.code === regionCode);
    return {
      arTitle: region.arTitle,
      enTitle: region.enTitle,
    };
  } catch (e) {
    console.warn(e);
    return {};
  }
}

export function getCities(regionCode) {
  try {
    const { regions } = JSON.parse(localStorage.getItem(lookupName));
    return Object.freeze(regions.find((r) => r.code === regionCode).cities);
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function getCity(regionCode, cityUid) {
  try {
    const { regions } = JSON.parse(localStorage.getItem(lookupName));
    const region = regions.find((r) => r.code === regionCode);
    const city = region.cities.find((c) => c.uid === cityUid);
    return {
      arTitle: city.arTitle,
      enTitle: city.enTitle,
    };
  } catch (e) {
    console.warn(e);
    return {};
  }
}

export const getBankById = async (bankId) => {
  if (!bankId) {
    return {};
  }

  try {
    const { data } = await lookupAPI.fetchBanks();
    const [firstBank] = data.banks.filter(({ id }) => Number(id) === bankId);

    return {
      arTitle: firstBank.arTitle,
      enTitle: firstBank.enTitle,
    };
  } catch (e) {
    console.warn(e);
    return ErrorHandler.parseFetchErrors(e);
  }
};

export function getBanks() {
  try {
    const { banks } = JSON.parse(localStorage.getItem(lookupName));
    return banks || [];
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function getBankOptions() {
  return getBanks().map((bank) => ({
    label: i18n.locale === LocaleTypes.Ar ? bank.arTitle : bank.enTitle,
    key: bank.id,
  }));
}

export function getBanksByIds(ids) {
  if (!Array.isArray(ids)) return [];
  return ids.map((id) => getBankOptions().find((e) => Number(e.key) === Number(id)));
}

export function getFamilyGoals() {
  try {
    const { familyTrustGoals } = JSON.parse(localStorage.getItem(lookupName));
    return familyTrustGoals || [];
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function getFamilyGoalOptions() {
  return getFamilyGoals().map((goal) => ({
    label: i18n.locale === LocaleTypes.Ar ? goal.arTitle : goal.enTitle,
    key: +goal.id,
  }));
}

export function getFamilyGoalsById(id) {
  const goal = getFamilyGoals().find((g) => Number(g?.id) === Number(id)) || {};
  return i18n.locale === LocaleTypes.Ar ? goal.arTitle : goal.enTitle;
}

export function getFamilyRelations() {
  try {
    const { familyRelations } = JSON.parse(localStorage.getItem(lookupName));
    return familyRelations || [];
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function getFamilyRelationById(id) {
  const relation = getFamilyRelations().find((r) => r?.uid === id) || {};
  return i18n.locale === LocaleTypes.Ar ? relation.arTitle : relation.enTitle;
}

export default setupLookup(i18n);
