import AuthLayout from '@/views/auth/AuthLayout.vue';

export default {
  path: '',
  name: 'AuthLayout',
  component: AuthLayout,
  children: [
    {
      path: 'login',
      name: 'Login',
      component: () => import(
        /* webpackChunkName: "login" */
        '../views/auth/login/Login.vue'
      ),
    },
    {
      path: 'change-password',
      name: 'ChangePassword',
      component: () => import(
        /* webpackChunkName: "change-password" */
        '../views/auth/change-password/ChangePassword.vue'
      ),
    },

    {
      path: 'reset-password',
      name: 'ResetPassword',
      component: () => import(
        /* webpackChunkName: "reset-password" */
        '../views/auth/reset-password/ResetPassword.vue'
      ),
    },
  ],
};
