import * as bankDelegationRequestsApi from '@/api/bank-delegation-requests.api';
import { ErrorHandler } from '@/helpers';
import { abortController } from '@/helpers/api';
import { RequestStatuses } from '@/constants/enums';
import {
  acceptBankDelegationRequest,
  rejectBankDelegationRequest,
} from '@/api/bank-delegation-requests.api';

const abortControllerRequests = 'Abort Bank Delegation Request List';
const cancelSource = abortController();

const state = {
  bankDelegationRequest: null,
  bankDelegationRequestList: [],
  listMeta: null,
  listLoading: false,
  error: null,
};

const getters = {
  getBankDelegationRequest: (state) => state.bankDelegationRequest,
  getBankDelegationRequestId: (state) => +state.bankDelegationRequest.id,
  getBankDelegationRequestList: (state) => state.bankDelegationRequestList,
  isListLoading: (state) => state.listLoading,
  getRequestsCount: (state) => state.listMeta?.recordsCount || 0,
  getError: (state) => state.error,
};

const mutations = {
  setBankDelegationRequest(state, request) {
    state.bankDelegationRequest = request;
  },
  setBankDelegationRequestList(state, list) {
    state.bankDelegationRequestList = list;
  },
  setListLoading(state, value) {
    state.listLoading = value;
  },
  setListMeta(state, meta) {
    state.listMeta = meta;
  },
  setError(state, error) {
    state.error = error;
  },
};
const actions = {
  cancelRequest: () => {
    if (cancelSource.hasItems()) {
      cancelSource.abort(abortControllerRequests);
    }
  },
  async findBankDelegationRequest({ getters, commit }, adminRequestId) {
    const isRequestExistInList = getters.getBankDelegationRequestList
      .find((r) => (+r.id === +adminRequestId));
    if (isRequestExistInList?.bankDelegation) {
      commit('setBankDelegationRequest', isRequestExistInList.bankDelegation);
      return isRequestExistInList.bankDelegation;
    }
    try {
      const { data } = await bankDelegationRequestsApi.findBankDelegationRequest(adminRequestId);
      commit('setBankDelegationRequest', data?.findBankDelegationAdminRequest?.bankDelegation);
      return data;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  async fetchBankDelegationRequestList({ commit, rootGetters }, {
    statuses,
    requestId,
  }) {
    commit('setError', null);
    commit('setListLoading', true);
    let result;
    if (cancelSource.hasItems()) {
      cancelSource.abort(abortControllerRequests);
    }
    const cancelToken = cancelSource.register();
    try {
      const { data } = await bankDelegationRequestsApi.listBankDelegationsAdminRequests({
        page: rootGetters['bankDelegationRequestsPagination/selectedPage'],
        size: rootGetters['bankDelegationRequestsPagination/selectedSize'],
        statuses,
        requestId,
      },
      { cancelToken });
      const { adminRequests, meta } = data?.listBankDelegationsAdminRequests;
      if (meta) {
        commit('bankDelegationRequestsPagination/setTotalPages', meta.pageCount, { root: true });
        commit('bankDelegationRequestsPagination/setTotalRecords', meta.recordsCount, { root: true });
        commit('setListMeta', meta);
      }
      commit('setBankDelegationRequestList', adminRequests);
    } catch (e) {
      const { error: result } = ErrorHandler.parseFetchErrors(e);
      commit('setError', result);
    } finally {
      commit('setListLoading', false);
    }
    return result;
  },
  bringTheAction: async ({ dispatch }, { decision, rejectMsg, requestId }) => {
    let result;
    if (decision === RequestStatuses.Accepted) {
      result = await dispatch('acceptRequest', { requestId });
    } else {
      result = await dispatch('rejectRequest', { requestId, rejectMsg });
    }
    return result;
  },
  rejectRequest: async (_, { requestId, rejectMsg }) => {
    try {
      const request = await rejectBankDelegationRequest(requestId, rejectMsg);
      return request;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  acceptRequest: async (_, { requestId }) => {
    try {
      const request = await acceptBankDelegationRequest(requestId);
      return request;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
