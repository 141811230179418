import Hijri from 'hijrah-date';
import DateTime from 'luxon/src/datetime';
import i18n from '@/i18n';
import { LocaleTypes, TranslationsForTitles } from '@/constants/enums';

const isJustNumbers = new RegExp('^\\d+$');

export const capitalizeFirstLetter = (string = '') => {
  if (!string || typeof string !== 'string') {
    console.warn(`Can't capitalize first letter. Get this, like string parametr: ${string}`);
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fileSizeFormat = (bytes, decimals = 2) => {
  if (typeof bytes === 'string' && isJustNumbers.test(bytes)) bytes = Number(bytes);
  if (!bytes || typeof bytes !== 'number') {
    console.warn(`Can't calculate file size. Get this, like bytes parametr: ${bytes}`);
    return 'NaN Bytes';
  }
  const k = 1024;
  decimals = Number(decimals);
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))}${sizes[i]}`;
};

export const randomEmail = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let string = '';
  for (let i = 0; i < 15; i += 1) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  return `${string}@domain.com`;
};

export const handleNationalAddress = (address) => {
  // TODO: Create foo for checking is Object (Lodash???)
  if (
    typeof address !== 'object'
    || Array.isArray(address)
    || address === null
    || !address.buildingNo
  ) return '—';
  const { buildingNo } = address;
  const streetName = address[`streetName${capitalizeFirstLetter(i18n.locale)}`] || '—';
  const districtArea = address[`districtArea${capitalizeFirstLetter(i18n.locale)}`] || '—';
  const city = address[`city${capitalizeFirstLetter(i18n.locale)}`] || '—';
  const code = address.zipCode || '—';
  const additional = address.additionalNo || '—';
  const unit = address.unitNo || '—';
  return [`${buildingNo} ${streetName}`, `${districtArea}`, `${city} ${code} ${additional} ${unit}`];
};

export const handleNationalAddressUpdate = (address) => {
  if (!address) return ['—'];
  const { buildingNo } = address;
  const streetName = address[`streetName${capitalizeFirstLetter(i18n.locale)}`];
  const districtArea = address[`districtArea${capitalizeFirstLetter(i18n.locale)}`];
  const city = address[`city${capitalizeFirstLetter(i18n.locale)}`];
  const code = address.zipCode;
  const additional = address.additionalNo;
  const unit = address.unitNo;
  return [`${buildingNo || ''} ${streetName || ''}`, `${districtArea || ''}`, `${city || ''} ${code || ''} ${additional || ''} ${unit || ''}`];
};

export const getUrlParam = (query) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(query);
};

export const setUrlParams = (params) => {
  let queryString = '?';
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.forEach((v, k) => {
    if (!Object.prototype.hasOwnProperty.call(params, k)) {
      params[k] = v;
    }
  });

  Object.entries(params).forEach(([k, v]) => {
    queryString += `${k}=${v}&`;
  });

  window.history.replaceState(null, null, queryString.slice(0, -1));
};

export const handleDOB = (dobStr) => {
  if (dobStr && typeof dobStr === 'string') {
    return {
      day: dobStr.slice(6, 8),
      month: dobStr.slice(4, 6),
      year: dobStr.slice(0, 4),
    };
  }
  return {
    day: '',
    month: '',
    year: '',
  };
};
export const parseDateFromString = (formattedDate, parse = 'yyyy/MM/dd') => {
  // This function can parse date from string just in this patterns:
  // 'yyyy/MM/dd',
  // 'yyyyMMdd'
  const parsedHijriDate = {
    day: '',
    month: '',
    year: '',
  };
  if (!parse) {
    parse = formattedDate.includes('/') ? 'yyyy/MM/dd' : 'yyyyMMdd';
  }
  if (!formattedDate || typeof formattedDate !== 'string') {
    console.warn('Broken date: ', formattedDate);
    return parsedHijriDate;
  }
  if (
    (formattedDate.includes('/') && !parse.includes('/'))
    || (!formattedDate.includes('/') && parse.includes('/'))
  ) {
    console.warn(`Broken pattern for parsing date. Pattern: ${parse} \n Date: formattedDate`);
    return parsedHijriDate;
  }
  const date = DateTime.fromFormat(formattedDate, parse);
  const addMask = (string) => {
    string = String(string);
    return (string.length === 2) ? string : `0${string}`;
  };
  if (date.invalid) {
    // Fix: FEBRUARY HAS ONLY 28 or 29 days
    if (!formattedDate.includes('/02')) {
      console.warn(`Broken date. Make sure that date is not out of range. Date: ${formattedDate}`);
      return parsedHijriDate;
    }
    const fixHijriDate = formattedDate.replace('02', '01');
    const fixedDate = DateTime.fromFormat(fixHijriDate, parse);
    const hd = new Hijri(fixedDate.year, fixedDate.month, fixedDate.day);
    parsedHijriDate.day = addMask(hd.getDay());
    parsedHijriDate.month = addMask(hd.getMonth() + 1);
    parsedHijriDate.year = String(hd.getFullYear());
    return parsedHijriDate;
  }
  parsedHijriDate.day = addMask(date.day);
  parsedHijriDate.month = addMask(date.month);
  parsedHijriDate.year = String(date.year);
  return parsedHijriDate;
};

export const toSnakeCase = (str) => {
  if (str && typeof str === 'string') {
    return str
      && str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.toLowerCase())
        .join('_');
  }
  return undefined;
};

export const toPascalCase = (str) => {
  if (str && typeof str === 'string') {
    return str.match(/[a-z]+/gi)
      .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join('');
  }
  return undefined;
};

export const getDateOfBirthAsString = (obj) => {
  if (!obj && !obj.day && !obj.month && !obj.year) return '';
  return `${obj.day}.${obj.month}.${obj.year}`;
};

// Check if an element is out of the viewport
export const isOutOfViewport = (element) => {
  const out = {};

  if (element) {
    const bounding = element.getBoundingClientRect();

    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
  }

  return out;
};

export const makeDateFromString = (date, separator = '/') => {
  if (typeof date === 'string') {
    const day = date.slice(6, 8);
    const month = date.slice(4, 6);
    const year = date.slice(0, 4);

    return `${year}${separator}${month}${separator}${day}`;
  }
  return '';
};

export const replaceEmptyValuesInArray = (v) => ((v === 0 || v) ? v : i18n.t('general.noData'));

export const getLocationTranslation = (obj) => {
  if (!obj) return i18n.t('general.noData');
  const locationTitle = i18n.locale === LocaleTypes.Ar
    ? TranslationsForTitles.Ar
    : TranslationsForTitles.En;
  return obj[locationTitle] || i18n.t('general.noData');
};
