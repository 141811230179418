import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import { getLocale, setLocale } from '@/i18n';
import { SUPPORTED_LOCALES } from '@/constants';
import auth from '@/router/auth';
import dashboard from '@/router/dashboard';

NProgress.configure({
  showSpinner: false,
});

Vue.use(VueRouter);

const routes = [
  {
    path: '/:locale',
    component: {
      name: 'RouterRoot',
      template: '<router-view/>',
    },
    children: [
      dashboard,
      auth,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.params.skipScroll) {
      return null;
    }
    return { x: 0, y: 0 };
  },
});

let timer = null;
let counter = 0;

router.beforeEach((to, from, next) => {
  if (!SUPPORTED_LOCALES.includes(to.params.locale)) {
    const reg = new RegExp(`^/${SUPPORTED_LOCALES.join('|')}/`, 'i');
    next({
      path: reg.test(to.fullPath) ? getLocale() : `${getLocale()}${to.fullPath}`,
    });
    return;
  }
  if (to.name !== from.name && !timer && counter > 1) {
    timer = setTimeout(() => {
      NProgress.start();
    }, 0);
  }
  if (to.params.locale !== from.params.locale) {
    setLocale(to.params.locale);
  }
  next();
});

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    timer = clearTimeout(timer);
    counter += 1;
    NProgress.done();
  }
});

router.onError(() => {
  console.warn('router error');
  setTimeout(() => {
    NProgress.done();
  }, 100);
});

export default router;
