export const FETCH_FILTERED_ENTITIES_FOR_ADMIN = `query FetchFilteredEntitiesForAdmin(
  $page: Int!,
  $size: Int!,
  $name: String,
  $type: EntityType,
  $statuses: [String!],
  $mainClassificationId: Int,
  $firstSubClassificationId: Int,
  $secondSubClassificationsId: [Int!],
  $hijriDateFrom: String,
  $hijriDateTo: String,
  $goalId: Int,
  $activityId: Int,
  $excludedType: [EntityType!]
  $regionCode: String
  $cityUid: [String!]
  $entityUid: String
  $unifiedNumber700: String
){
  listEntitiesForAdmin(
    page: $page
    size: $size
    name: $name
    type: $type
    mainClassificationId: $mainClassificationId
    firstSubClassificationId: $firstSubClassificationId
    secondSubClassificationsId: $secondSubClassificationsId
    hijriDateFrom: $hijriDateFrom
    hijriDateTo: $hijriDateTo
    goalId: $goalId
    activityId: $activityId
    statuses: $statuses
    excludedType: $excludedType
    regionCode: $regionCode
    cityUid: $cityUid
    entityUid: $entityUid
    unifiedNumber700: $unifiedNumber700
  ) {
    entities {
      entityProfile {
        logo {
          origin {
            url: path
            uid
          }
          x100 {
            url: path
            uid
          }
          x200 {
            url: path
            uid
          }
          x300 {
            url: path
            uid
          }
          x500 {
            url: path
            uid
          }
        }
      }
      activities{
        arTitle
      }
      entityLicenseNumber
      unifiedNumber700
      status
      owner {
        id
      }
      createdAt
      acceptedAt
      foundForSelf
      foundFor
      classificationDetails{
        mainClassificationArTitle
        firstSubClassificationArTitle
        secondSubClassificationArDescription
        departmentArTitle
      }
      goals {
        ... on CommonGoal {
          arTitle
          id
        }
        ... on FamilyTrustGoal {
          arTitle
          id
        }
      }
      id
      nameArabic
      nameEnglish
      numberOfBoardMembers
      secondSubClassificationId
      type
      updatedAt
      updatedAtHijri: registrationDateHijri
    }
    meta{
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const LIST_CONTRIBUTION_SOURCES = `query ListContributionSources($entityId: Int, $page: Int, $size: Int) {
  listContributionSources(entityId: $entityId, page: $page, size: $size) {
    contributionsSources {
      amount,
      title,
      kind
    }
  }
}`;

export const GENERATE_REPORTS = `query GenerateReports(
  $name: String,
  $locale: String,
  $type: String,
  $status: [String!],
  $mainClassificationId: Int,
  $firstSubClassificationId: Int,
  $secondSubClassificationsId: [Int!],
  $hijriDateFrom: String,
  $hijriDateTo: String,
  $goalId: Int,
  $activityId: Int,
  $regionCode: String
  $cityUid: [String!]
  $entityUid: String
  $unifiedNumber700: String
) {
  getEntitiesReport(
    name: $name,
    locale: $locale,
    type: $type,
    status: $status,
    mainClassificationId: $mainClassificationId,
    firstSubClassificationId: $firstSubClassificationId,
    secondSubClassificationsId: $secondSubClassificationsId,
    hijriDateFrom: $hijriDateFrom,
    hijriDateTo: $hijriDateTo,
    goalId: $goalId,
    activityId: $activityId,
    regionCode: $regionCode
    cityUid: $cityUid
    entityUid: $entityUid
    unifiedNumber700: $unifiedNumber700
  ) {
    path
    uid
  }
}`;
