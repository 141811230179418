import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { API_URL, IS_PROD, IS_DEMO } from '@/constants';

export const Gateway = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const GraphQL = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const GraphQLRequest = (data, config) => (
  GraphQL.post('graphql', data || {}, config)
);

export const setupInterceptors = (store) => {
  Gateway.interceptors.response.use(
    (response) => response,
    (error) => {
      if ([401, 403].includes(error?.response?.status)) {
        store.commit('auth/setContext', null, { root: true });
      }
      if (IS_DEMO) {
        if ([501].includes(error?.response.status)) {
          const endPoint = error?.response.config.baseURL + error?.response.config.url;
          store.dispatch('toast/showNotification', {
            message: `Unrealized APIs were used on this page. Please open network and check all of them. ${endPoint}`,
            duration: 60000,
            type: 'error',
          });
        }
      }
      return Promise.reject(error);
    },
  );

  GraphQL.interceptors.response.use(
    (response) => {
      const errors = response.data?.errors || [];
      if (!isEmpty(errors)) {
        if (!IS_PROD) {
          const messages = errors.map((e) => e.message);
          store.dispatch('toast/showNotification', {
            message: messages.join(', '),
            duration: 5000,
            type: 'error',
          });
        }
        return Promise.reject(errors);
      }
      return response.data;
    },
    (error) => {
      if ([401, 403].includes(error?.response?.status)) {
        store.commit('auth/setContext', null, { root: true });
      }
      return Promise.reject(error);
    },
  );
};
