import { GraphQLRequest } from '@/setup/axios';
import { EditRequestTypes, EditPolicyStatuses, UnitTypes } from '@/constants/enums';
import {
  FETCH_EDIT_POLICY_REQUEST_DETAILS,
  FETCH_EDIT_POLICY_REQUEST_LIST,
  LIST_EDIT_POLICY_ITEMS, LIST_ENTITY_REQUESTS,
} from '@/api/graphql/edit-request-gql';
import {
  ACCEPT_EDIT_REQUEST,
  REJECT_EDIT_REQUEST,
  SUBMIT_EDIT_REQUEST,
  ADJUST_EDIT_REQUEST,
  UPDATE_EDIT_ITEM,
} from '@/api/graphql/edit-policy-gql';

export function fetchEditPolicyRequestV2(editRequestId) {
  return GraphQLRequest({
    query: FETCH_EDIT_POLICY_REQUEST_DETAILS,
    variables: {
      requestId: Number(editRequestId),
    },
  });
}

export function fetchEditPolicyRequestsV2({
  requestType = EditRequestTypes.regulationsAndGeografic,
  status = 'pending,pending_after_adjustment',
  page = 1,
  size = 10,
  secondSubClassificationId,
  unitId,
  unitType,
  sortByCreatedAt,
  id,
  entityName,
}) {
  return GraphQLRequest({
    query: FETCH_EDIT_POLICY_REQUEST_LIST,
    variables: {
      page: Number(page),
      size: Number(size),
      type: Array.isArray(requestType) ? requestType.map((e) => String(e)) : undefined,
      status: status.split(','),
      secondSubClassificationId: Array.isArray(secondSubClassificationId)
        ? secondSubClassificationId.map((e) => Number(e)) : undefined,
      unitId: Number(unitId) || undefined,
      unitType: unitType ? String(unitType) : undefined,
      id: Number(id) || undefined,
      entityName: entityName ? String(entityName) : undefined,
      sortByCreatedAt,
    },
  });
}

export function adjustEditPolicyRequest(id, rejectReason) {
  return GraphQLRequest({
    query: ADJUST_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(id),
        rejectReason,
      },
    },
  });
}

export function submitEditPolicyRequest(id, comments) {
  return GraphQLRequest({
    query: SUBMIT_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(id),
        comments: String(comments),
      },
    },
  });
}

export function acceptEditPolicyRequest(id) {
  return GraphQLRequest({
    query: ACCEPT_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(id),
      },
    },
  });
}

export function rejectEditPolicyRequest(editId, rejectReason) {
  return GraphQLRequest({
    query: REJECT_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(editId),
        rejectReason: String(rejectReason),
      },
    },
  });
}

export function fetchEditItems(editRequestId, action = ['idle', 'create', 'update', 'delete'], page = 1, size = 10) {
  return GraphQLRequest({
    query: LIST_EDIT_POLICY_ITEMS,
    variables: {
      editRequestId: Number(editRequestId),
      page: Number(page),
      size: Number(size),
      action,
    },
  });
}

export function updateEditItem(id, unitId, action, newPayload) {
  return GraphQLRequest({
    query: UPDATE_EDIT_ITEM,
    variables: {
      editItemAttributes: {
        id: Number(id),
        unitId: Number(unitId),
        action,
        newPayload,
      },
    },
  });
}

export function getAcceptedUpdatesOfEntity({
  adjustmentType = [
    EditRequestTypes.primaryInformation,
    EditRequestTypes.regulationsAndGeografic,
  ],
  status = EditPolicyStatuses.Accepted,
  unitId,
  unitType = UnitTypes.Entity,
}) {
  return GraphQLRequest({
    query: LIST_ENTITY_REQUESTS,
    variables: {
      unitId: Number(unitId),
      unitType,
      type: adjustmentType,
      status,
      page: 1,
      size: 50,
    },
  });
}
