import axios from 'axios';
import { ErrorHandler } from '@/helpers';
import {
  acceptEshopRequest,
  fetchEshopRequestByLicenseId,
  fetchEshopRequests,
  rejectEshopRequest,
} from '@/api/eshop.api';
import { normalizeEshopRequestList } from '@/services/eshop.service';
import { getBankById } from '@/services/lookup.service';
import { RequestStatuses } from '@/constants/enums';
import i18n from '@/i18n';

let cancelSource = null;

const state = {
  eshopRequests: [],
  isLoading: false,
  eshopRequestsCount: 0,
  error: null,
  eshopRequestDetails: {},
  adminRecommendationInfo: null,
  currentRequestMode: {
    requestId: Number(),
    isPassed: Boolean(),
  },
};

const getters = {
  eshopRequests: (store) => store.eshopRequests,
  isLoading: (store) => store.isLoading,
  getEshopRequestsCount: (store) => store.eshopRequestsCount,
  getAdminRecommendationInfo: (store) => store.adminRecommendationInfo,
  error: (store) => store.error,
  getEshopRequestDetails: (store) => store.eshopRequestDetails,
  isCurrentRequestInHistoryMode: (store) => store.currentRequestMode?.isPassed ?? false,
};

const mutations = {
  setEshopRequests: (store, requests) => {
    store.eshopRequests = requests;
  },
  setEshopRequestsCount: (store, count) => {
    store.eshopRequestsCount = count;
  },
  setAdminRecommendationInfo: (store, obj) => {
    store.adminRecommendationInfo = obj;
  },
  setLoading: (store, boolean) => {
    store.isLoading = boolean;
  },
  setError: (store, error) => {
    store.error = error;
  },
  setEshopRequestDetails: (store, request) => {
    store.eshopRequestDetails = request;
  },
  setCurrentRequestMode: (store, { requestId, isPassed }) => {
    store.currentRequestMode.requestId = Number(requestId);
    store.currentRequestMode.isPassed = Boolean(isPassed);
  },
};

const actions = {
  cancelRequest: () => {
    if (cancelSource) {
      cancelSource.cancel('abort request');
    }
  },
  fetchEshopRequestsList: async ({ commit, rootGetters }, { namespace, status }) => {
    commit('setError', null);
    cancelSource = axios.CancelToken.source();
    commit('setLoading', true);
    try {
      const res = await fetchEshopRequests({
        status,
        page: rootGetters[`${namespace}/selectedPage`],
        size: rootGetters[`${namespace}/selectedSize`],
      }, { cancelToken: cancelSource.token });
      const requestsList = res.data?.websiteLicenseAdminRequests?.websiteLicenseAdminRequests || [];
      const normalizedRequests = requestsList.map(normalizeEshopRequestList);
      commit('setEshopRequests', normalizedRequests);
      const meta = res.data?.websiteLicenseAdminRequests?.meta;
      if (meta) {
        commit('eshopRequestsPagination/setTotalPages', meta.pageCount, { root: true });
        commit('eshopRequestsPagination/setTotalRecords', meta.recordsCount, { root: true });
        commit('setEshopRequestsCount', meta.recordsCount);
      }
    } catch (e) {
      const { error } = ErrorHandler.parseFetchErrors(e);
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  fetchEshopRequestDetails: async ({ commit, getters }, requestId) => {
    commit('setLoading', true);
    commit('setError', null);
    try {
      const requestFromList = Array.isArray(getters.eshopRequests)
        ? getters.eshopRequests.find((r) => Number(r.id) === Number(requestId))
        : null;
      if (requestFromList) {
        commit('setCurrentRequestMode', {
          requestId: +requestId,
          isPassed: [
            RequestStatuses.Accepted,
            RequestStatuses.Rejected,
          ].includes(requestFromList?.status),
        });
      }
      const licenseFromList = requestFromList?.license || null;
      if (licenseFromList) {
        const bankName = await getBankById(licenseFromList?.bankId);
        commit('setAdminRecommendationInfo', licenseFromList?.recommendationNotes);
        commit('setEshopRequestDetails', { ...licenseFromList, bankName });
        return;
      }
      const res = await fetchEshopRequestByLicenseId(Number(requestId));
      const currentRequest = res.data?.findWebsiteLicenseAdminRequest;
      const licenseRequest = currentRequest?.websiteLicense;
      const bankName = await getBankById(licenseRequest?.bankId);
      commit('setCurrentRequestMode', {
        requestId: +requestId,
        isPassed: [
          RequestStatuses.Accepted,
          RequestStatuses.Rejected,
        ].includes(currentRequest?.status),
      });
      commit('setAdminRecommendationInfo', licenseRequest.recommendationNotes);
      commit('setEshopRequestDetails', { ...licenseRequest, bankName });
    } catch (e) {
      const { error } = ErrorHandler.parseFetchErrors(e);
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  fetchAdminRecommendation: async () => {
    /* TODO: include admin recommendation API */
  },
  takeDecisionOnRequest: async ({ dispatch }, payload) => {
    let decision = null;
    if (!payload?.status) return decision;
    try {
      if (payload.status === RequestStatuses.Accepted) {
        await acceptEshopRequest(payload.id);
      }
      if (payload.status === RequestStatuses.Rejected) {
        await rejectEshopRequest(payload.id, payload.statusReason);
      }
      dispatch('toast/showNotification', {
        message: i18n.t('general.requestHasBeenSent'),
        duration: 4000,
        type: 'success',
      }, { root: true });
    } catch (e) {
      decision = ErrorHandler.parseFormErrors(e);
    }
    return decision;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
