import { getField, updateField } from 'vuex-map-fields';
import { abortController } from '@/helpers/api';
import { formatDate } from '@/helpers/date';
import {
  acceptObjectionAdminRequest, fetchAdminObjectionRecommendation,
  fetchObjectionDetails,
  listObjectionRequests, rejectObjectionAdminRequest,
} from '@/services/objection-requests.service';
import { AdminRoles, RequestStatuses } from '@/constants/enums';
import { ErrorHandler } from '@/helpers';

const handleRequest = (r) => (
  {
    ...r,
    unit: r?.objectionUnit?.entity,
    createdAt: formatDate(r.createdAt),
    remainingValue: r.remainingData?.remainingValue,
    remainingType: r.remainingData?.remainingType,
    updatedAt: r.updatedAt ? formatDate(r.updatedAt) : formatDate(r.createdAt),
  }
);

const abortControllerName = 'Abort Request';
const cancelSource = abortController();

const state = {
  objectionRequests: [],
  objectionDetails: {},
  objectionsCount: 0,
  loading: false,
};

const getters = {
  getField,
  isLoading: (store) => store.loading,
  objectionsCount: (store) => store.objectionsCount,
  objectionRequests: (store) => store.objectionRequests,
  objectionDetails: (store) => store.objectionDetails,
};

const mutations = {
  updateField,
  setObjectionRequests: (store, requests) => {
    store.objectionRequests = requests;
  },
  setObjectionsCount: (store, count) => {
    store.objectionsCount = count;
  },
  setObjectionDetails: (store, request) => {
    store.objectionDetails = request;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
};
const actions = {
  cancelRequest: () => {
    if (cancelSource.hasItems()) {
      cancelSource.abort(abortControllerName);
    }
  },
  getObjectionsCount: async ({ commit, rootGetters }) => {
    const res = await listObjectionRequests(
      [RequestStatuses.Pending],
      [rootGetters['auth/adminRole']],
      1,
      1,
    );
    const metaData = res?.meta;
    commit('setObjectionsCount', metaData?.recordsCount || 0);
  },
  getObjectionsList: async ({ commit, rootGetters }, { namespace, status }) => {
    commit('setLoading', true);
    if (cancelSource.hasItems()) {
      cancelSource.abort(abortControllerName);
    }
    const cancelToken = cancelSource.register();
    const res = await listObjectionRequests(
      status,
      [rootGetters['auth/adminRole']],
      rootGetters[`${namespace}/selectedPage`],
      rootGetters[`${namespace}/selectedSize`],
      { cancelToken },
      abortControllerName,
    );
    const metaData = res?.meta;
    const data = res?.establishmentAdminRequests;
    if (metaData) {
      commit(`${namespace}/setTotalPages`, metaData.pageCount, { root: true });
      commit(`${namespace}/setTotalRecords`, metaData.recordsCount, { root: true });
    }
    if (res.error) {
      commit('setObjectionRequests', []);
      if (res.error !== abortControllerName) commit('setLoading', false);
    } else {
      commit('setObjectionRequests', data.map((r) => handleRequest(r)));
      commit('setLoading', false);
    }
  },
  fetchObjectionDetails: async ({ commit, dispatch }, objectionId) => {
    try {
      let data = await fetchObjectionDetails(objectionId);
      const subscriptionPromise = dispatch('familyFundRequest/findFamilyFundSubscription', data?.objectionUnit?.entityId, { root: true });
      const recommendationPromise = fetchAdminObjectionRecommendation(
        data?.unitId, [AdminRoles.RegistrationAndPermitEmployee],
      );
      const banksPromise = dispatch('familyFundRequest/listEntityBanks', data?.objectionUnit?.entityId, { root: true });
      const [banks, subscription, recommendations] = await Promise.all(
        [banksPromise, subscriptionPromise, recommendationPromise],
      );
      if (recommendations && !recommendations?.error) {
        data = { ...data, recommendations };
      }
      if (banks && !banks.error) {
        data = { ...data, banks };
      }
      if (subscription && !subscription.error) {
        data = { ...data, subscription: subscription?.amount };
      }
      if (!data.error) {
        commit('setObjectionDetails', handleRequest(data));
      }
      return data;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  submitObjection: async (_, { requestId, message, isAccept }) => {
    const method = isAccept ? acceptObjectionAdminRequest : rejectObjectionAdminRequest;
    const data = await method(requestId, message);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
