import { Gateway } from '@/setup/axios';

class DocumentsApi {
  getDocument = (id, itemType, docType, bankId = null) => {
    const optionalParameter = bankId ? `&bank_id=${bankId}` : '';
    return Gateway.get(
      `documents/pdf/${id}?item_type=${itemType}&doc_type=${docType}${optionalParameter}`,
      { responseType: 'blob' },
    );
  }

  getDocumentV2 = (uid) => (
    Gateway.get(
      `/media/v2/download/${uid}`,
      { responseType: 'blob' },
    )
  )

  getPublicDocument = (itemId, itemType, docType) => Gateway.get(`public/documents/pdf/${itemId}?item_type=${itemType}&doc_type=${docType}`, { responseType: 'blob' })
}

export default new DocumentsApi();
