import { Gateway, GraphQLRequest } from '@/setup/axios';
import { GET_ADMIN_PROFILE } from '@/api/graphql/admins-gql';
import { CHANGE_PASSWORD, CHECK_EMAIL_OTP, CHECK_EMAIL } from '@/api/graphql/auth-gql';

export function loginIndividual(payload) {
  return Gateway.post('individual/login', payload);
}

export function logoutIndividual(payload) {
  return Gateway.post('individual/log-out', payload);
}

export function checkLoginOtp(payload) {
  return Gateway.post('individual/login-otp', payload);
}

export function changeAccountPassword(payload) {
  return Gateway.post('accounts/change-password', payload);
}

export function getAdminProfile() {
  return GraphQLRequest({
    query: GET_ADMIN_PROFILE,
  });
}

export function checkEmail(email) {
  return GraphQLRequest({
    query: CHECK_EMAIL,
    variables: {
      email,
    },
  });
}

export function checkEmailOtp({ otp, otpToken, accountId }) {
  return GraphQLRequest({
    query: CHECK_EMAIL_OTP,
    variables: {
      otp,
      otpToken,
      accountId,
    },
  });
}

export function changePassword({ accountId, password, resetToken }) {
  return GraphQLRequest({
    query: CHANGE_PASSWORD,
    variables: {
      accountId,
      password,
      resetToken,
    },
  });
}
