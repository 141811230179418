<template>
  <section-container
    :title="$t('entityDetails.mainInfo')"
  >
    <section-row
      :label="$t('entityDetails.applicantName')"
      :value="applicantName"
    />
    <section-row
      :label="$t('entityDetails.applicantRole')"
      :value="applicantMemberRole"
    />
    <section-row
      v-if="isApplicantMemberEntity"
      :label="$t('entityDetails.entityEstablisherRole')"
      :value="entityEstablisherRole"
    />
    <section-row
      v-if="isEntityEstablisherRoleDeligate"
      :label="$t('entityDetails.delegateType')"
      :value="entityEstablisherType"
    />
  </section-container>
</template>
<script>
import { mapGetters } from 'vuex';

import { MemberTypes } from '@/constants/enums';
import SectionContainer from '@/components/section-container';
import SectionRow from '@/components/section-row/SectionRow.vue';

export default {
  name: 'RequestApplicantInformationSectionBase',
  components: {
    SectionContainer,
    SectionRow,
  },
  computed: {
    ...mapGetters({
      requestDetails: 'requests/getRequestDetails',
    }),
    applicantName() {
      return this.requestDetails.entityEstablisherName;
    },
    isApplicantMemberEntity() {
      return this.requestDetails.autocreateMembershipForOwner;
    },
    entityEstablisherRole() {
      if (this.requestDetails.ownerMembership?.role === MemberTypes.Individual) {
        return this.$t('entityDetails.memberRole.individual');
      }
      if (this.requestDetails.ownerMembership?.role === MemberTypes.Delegate) {
        return this.$t('entityDetails.memberRole.delegate');
      }
      return '';
    },
    entityEstablisherType() {
      switch (this.requestDetails.ownerMembership?.governmental) {
        case true:
          return this.$t('entityDetails.entityType.governmentalEntity');
        case false:
          return this.$t('entityDetails.entityType.nonGovernmentalEntity');
        default:
          return '';
      }
    },
    isEntityEstablisherRoleDeligate() {
      return this.requestDetails.ownerMembership?.role === MemberTypes.Delegate;
    },
    applicantMemberRole() {
      return this.isApplicantMemberEntity
        ? this.$t('entityDetails.deligateType.member')
        : this.$t('entityDetails.deligateType.nonmember');
    },
  },
};
</script>
