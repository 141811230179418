export const FETCH_ACTIVITIES_REQUESTS_LIST = `query FetchActivitiesRequestsList (
  $page: Int!
  $size: Int!
  $unitId: Int
  $status: [EntityActivityStatus!]
  $departmentId: Int
  $unitOwnerId: Int
  $unitType: String
  $id: Int
  $name: String
  $entityName: String
) {
    listNewEntityActivity(
    page: $page
    size: $size
    unitId: $unitId
    status: $status
    departmentId: $departmentId
    unitOwnerId: $unitOwnerId
    unitType: $unitType
    id: $id
    name: $name
    entityName: $entityName
    ) {
      entityActivities {
        active
        activityAmount
        activityGoals
        categoryBeneficiares
        consistentWithGoals
        consistentWithObjectives
        createdAt
        departmentId
        description
        durationType
        durationValue
        endActivity
        remainingData {
          remainingValue
          remainingType
        }
        lockRequest {
          accountId
          requestId
          requestType
          status
        }
        entity {
          nameArabic
          nameEnglish
          type
          id
        }
        expectedOutputs
        foreignCity
        foreignCountry
        getAmount
        id
        internalActivity
        linkActivityDev
        mainGoal
        name
        numberBeneficiares
        percentageTotalCost
        regionCode
        rejectReason
        rejectedBy
        saCityUid
        startActivity
        status
        step
        subjectActivity
        subjectActivityDetails
        subjectActivityPerformance
        technicalSupervisory
        totalCost
        totalPartners
        type
        unitId
        unitOwnerId
        unitType
        updatedAt
        verifyCorrectData
      }
      meta {
        pageCount
        page
        recordsCount
      }
    }
  }`;

export const FETCH_ENTITY_ACTIVITY = `query FetchEntityActivity (
  $id: Int!
) {
    findNewEntityActivity(
    id: $id
    ) {
        remainingData {
          remainingValue
          remainingType
        }
        lockRequest {
          accountId
          requestId
          requestType
          status
        }
        closeActivityFiles {
          filename,
          docType,
          fileSize,
          uid,
          path,
        }
        region {
          enTitle
          arTitle
          code
        }
        city {
          enTitle
          arTitle
          uid
        }
        closingSummary
        admin {
          email
        }
        active
        activityAmount
        activityGoals
        categoryBeneficiares
        consistentWithGoals
        consistentWithObjectives
        createdAt
        departmentId
        description
        durationType
        durationValue
        endActivity
        entity {
          nameArabic
          nameEnglish
          type
          supportingDocuments {
            filename,
            docType,
            fileSize,
            uid,
            path,
          },
        }
        expectedOutputs
        foreignCity
        foreignCountry
        getAmount
        id
        internalActivity
        linkActivityDev
        mainGoal
        name
        numberBeneficiares
        percentageTotalCost
        regionCode
        rejectReason
        rejectedBy
        saCityUid
        startActivity
        status
        step
        subjectActivity
        subjectActivityDetails
        subjectActivityPerformance
        technicalSupervisory
        totalCost
        totalPartners
        type
        unitId
        unitOwnerId
        unitType
        updatedAt
        verifyCorrectData
    }
  }`;

export const ACCEPT_ACTIVITY_REQUEST = `mutation AcceptEntityActivity ($entityActivityAcceptAttributes: AcceptEntityActivityInput!) {
  acceptEntityActivity(entityActivityAcceptAttributes: $entityActivityAcceptAttributes) {
    unitId
    unitType
    status
  }
}`;

export const REJECT_ACTIVITY_REQUEST = `mutation RejectEntityActivity ($entityActivityRejectAttributes: RejectEntityActivityInput!) {
  rejectEntityActivity(entityActivityRejectAttributes: $entityActivityRejectAttributes) {
    active
  }
}`;

export const FETCH_CHOSEN_EXTERNAL_AUTHORITIES_BY_ACTIVITY_ID = `query ListChosenAuthoritiesByActivityId ($entityActivityId: Int!){
  listActivityAuthoritiesByActivity(entityActivityId: $entityActivityId){
    entityActivityId
    externalAuthorityId
    id
  }
}`;
