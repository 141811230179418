<template>
  <div class="sidebar-header">
    <logo route-name="Dashboard" />
  </div>
</template>

<script>

export default {
  name: 'SidebarHeader',
};
</script>

<style src="./styles.scss" lang="scss" />
