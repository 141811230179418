<template>
  <div class="section-row">
    <div :class="['section-row-inner', direction]">
      <div class="section-row-label">
        {{ label }}
      </div>
      <div
        :id="label"
        class="section-row-value"
      >
        <template v-if="value">
          <div
            v-if="isMultipleValue"
            class="multiple-values"
          >
            <span
              v-for="(v, index) in value"
              :id="label"
              :key="index"
              class="multiple-value"
            >
              {{ v }}
            </span>
          </div>
          <template v-else>
            {{ value }}
          </template>
        </template>
        <template v-else>
          <slot />
        </template>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'SectionRow',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
    },
    direction: {
      type: String,
      validator: (value) => ['column', 'list'].indexOf(value) !== -1,
      default: 'column',
    },
  },
  computed: {
    isMultipleValue() {
      return Array.isArray(this.value);
    },
  },
};
</script>
<style src="./styles.scss" lang="scss" />
