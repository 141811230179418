import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localize, localeChanged } from 'vee-validate';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/constants';
import { LocaleTypes } from '@/constants/enums';
import ar_validations from './ar/validations.json';
import en_validations from './en/validations.json';
import ar_auth from './ar/auth.json';
import en_auth from './en/auth.json';
import ar_dashboard from './ar/dashboard.json';
import en_dashboard from './en/dashboard.json';
import ar_general from './ar/general.json';
import en_general from './en/general.json';
import en_sidebar from './en/sidebar.json';
import ar_sidebar from './ar/sidebar.json';
import ar_dropdowns from './ar/dropdowns.json';
import en_dropdowns from './en/dropdowns.json';
import ar_migration from './ar/migration.json';
import en_migration from './en/migration.json';
import ar_entitiesList from './ar/entitiesList.json';
import en_entitiesList from './en/entitiesList.json';
import ar_profile from './ar/profile.json';
import en_profile from './en/profile.json';

Vue.use(VueI18n);

const messages = {
  ar: {
    ...ar_validations.messages,
    ...ar_general,
    ...ar_auth,
    ...ar_dashboard,
    ...ar_sidebar,
    ...ar_dropdowns,
    ...ar_migration,
    ...ar_entitiesList,
    ...ar_profile,
  },
  en: {
    ...en_validations.messages,
    ...en_general,
    ...en_auth,
    ...en_dashboard,
    ...en_sidebar,
    ...en_dropdowns,
    ...en_migration,
    ...en_entitiesList,
    ...en_profile,
  },
};

localize({
  ar_validations,
  en_validations,
});

export function getLocale() {
  return localStorage.getItem('nawa/locale') || DEFAULT_LOCALE;
}

const i18n = new VueI18n({
  messages,
  locale: getLocale(),
  fallbackLocale: DEFAULT_LOCALE,
  silentTranslationWarn: process.env.NODE_ENV !== 'production',
});

export function setLocale(lang) {
  if (!SUPPORTED_LOCALES.includes(lang)) return;
  if (i18n.locale !== lang) {
    i18n.locale = lang;
    localeChanged(lang);
  }
  window.document.title = i18n.t('general.nawa');
  document.dir = lang === LocaleTypes.Ar ? 'rtl' : 'ltr';
  document.documentElement.lang = lang;
  localStorage.setItem('nawa/locale', lang);
}

export default i18n;
