import { GraphQLRequest } from '@/setup/axios';
import {
  FIND_ENTITY_BY_ID_FOR_PROFILE,
  ENTITY_ADDITIONAL_INFO,
  FETCH_ENTITY_CONTACTS,
  FETCH_ENTITY_EMPLOYEES,
  FETCH_CITIES_FOR_ISSUING_UNIFIED_NUMBER_700,
  ISSUE_UNIFIED_NUMBER_700,
} from '@/api/graphql/entity-profile-gql';
import { MembershipEntityTypes } from '@/constants/enums';

class EntityProfileApi {
  findEntityProfileInfoByEntityId = (id) => (
    GraphQLRequest({
      query: FIND_ENTITY_BY_ID_FOR_PROFILE,
      variables: {
        id: Number(id),
      },
    })
  )

  findEntityAdditionalInfo = (entityId) => (
    GraphQLRequest({
      query: ENTITY_ADDITIONAL_INFO,
      variables: {
        entityId: Number(entityId),
      },
    })
  )

  fetchEntityContacts = (id) => (
    GraphQLRequest({
      query: FETCH_ENTITY_CONTACTS,
      variables: {
        entityId: Number(id),
      },
    })
  )

  fetchEntityEmployees = ({
    entityId,
    unitType = MembershipEntityTypes.Entity,
    page = 1,
    size = 100,
  }) => (
    GraphQLRequest({
      query: FETCH_ENTITY_EMPLOYEES,
      variables: {
        entityId: Number(entityId),
        page: Number(page),
        size: Number(size),
        unitType,
        active: true,
      },
    })
  )

  issueUnifiedNumber700 = (payload) => (
    GraphQLRequest({
      query: ISSUE_UNIFIED_NUMBER_700,
      variables: {
        unifiedNumberIssueAttributes: {
          entityId: Number(payload.entityId) || undefined,
          phoneNumber: payload.phoneNumber ? String(payload.phoneNumber) : undefined,
          email: payload.email ? String(payload.email) : undefined,
          telephoneNumber: payload.telephoneNumber ? String(payload.telephoneNumber) : undefined,
          cityCode: payload.cityCode ? Number(payload.cityCode) : undefined,
        },
      },
    })
  )

  fetchCitiesForIssuingUnifiedNumber700 = ({ name, page, size }) => (
    GraphQLRequest({
      query: FETCH_CITIES_FOR_ISSUING_UNIFIED_NUMBER_700,
      variables: {
        name: name ? String(name) : undefined,
        page: page ? Number(page) : 1,
        size: size ? Number(size) : 500,
      },
    })
  )
}

export default new EntityProfileApi();
