const entityFragment = `on Entity {
  nameArabic
  nameEnglish
  type
}`;
const documentData = `
  attachedAt
  createdAt
  docType
  fileSize
  filename
  mimeType
  path
  uid
  updatedAt
`;
export const GET_BANK_DELEGATIONS_REQUESTS = `query ListSecurityCheckRequests($page: Int!, $size: Int!, $statuses:[SecurityCheckStatus!]) {
  listSecurityCheckRequests(page: $page, size: $size, statuses: $statuses) {
    requests {
      createdAt
      updatedAt
      createdAtHijri
      id
      status
      remainingData {
        expireAt
        remainingValue
        remainingType
      }
      sourceId
      bankDelegationsRequest {
        isAuto
        entity {
          ...${entityFragment}
        }
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;
export const GET_DELEGATIONS_REQUEST_BY_ID = `query findSecurityCheckRequest($id: Int!) {
    request: findSecurityCheckRequest(id: $id) {
      status
      createdAtHijri
      email
      educationQualification
      id
      speciality
      status
      twitter
      facebook
      educationQualification
      speciality
      proofDocumentMetadata {
        id: uid
        uid
        name: filename
        title: filename
        size: fileSize
        url: path
        type: mimeType
        docType
      }
        bankDelegationsRequest {
          meetingMinutes {
            ${documentData}
          }
          employee {
            position
          }
          entity {
            ...${entityFragment}
          }
        }
      user {
        educationSpecialization
        employmentPlace
        firstName
        fatherName
        grandfatherName
        lastName
        gender
        nationalId
        nidExpireDate
        birthDateHijri
        birthplace
        occupation
        absherPhone
        nationalAddress {
          districtAreaAr
          districtAreaEn
          cityAr
          cityEn
          buildingNo
          streetNameAr
          districtAreaEn
        }
        socialMedias {
          id
          accountName
          type
        }
        nationality {
          arTitle
          enTitle
        }
      }
    }
}`;

export const ACCEPT_REQUEST = `mutation AcceptSecurityCheckRequest($id: Int!) {
  acceptSecurityCheckRequest(id: $id) {
    id
  }
}`;
export const REJECT_REQUEST = `mutation RejectSecurityCheckRequest($id: Int!, $rejectReason: String) {
  rejectSecurityCheckRequest(id: $id, rejectReason: $rejectReason) {
    id
  }
}`;
