import {
  createEditRequest,
  fetchEditItems,
  updateEditItem,
  acceptEditRequest,
  rejectEditRequest,
} from '@/services/edit-request.service';
import {
  EditRequestTypesByAdminRole,
  EditRequestUnitTypes,
  EstablishmentType,
} from '@/constants/enums';
import { fetchFamilyFundRequest } from '@/api/family-fund.api';
import { ErrorHandler } from '@/helpers';

const findEditItemByUnitType = (uType, list) => list
  ?.find(({ unitType }) => unitType === EditRequestUnitTypes[uType]);

const findEditItemsByUnitType = (uType, list) => list
  ?.filter(({ unitType }) => unitType === EditRequestUnitTypes[uType]);

const state = {
  familyRequest: {},
  editRequest: {},
  editAdminRequestId: null,
  editItems: {},
};

const getters = {
  getEditRequest: (store) => store.editRequest,
  getEditRequestId: (store) => store.editRequest.id,
  getEditAdminRequestId: (store) => store.editAdminRequestId,
  getFamilyRequest: (store) => store.familyRequest,
  getEntityEditItem: (store) => findEditItemByUnitType(
    EditRequestUnitTypes.Entity, store.editItems,
  ),
  getEntitySubscriptionEditItem: (store) => findEditItemByUnitType(
    EditRequestUnitTypes.FamilyFundSubscription, store.editItems,
  ),
  getEntitiesBankEditItems: (store) => findEditItemsByUnitType(
    EditRequestUnitTypes.EntitiesBank, store.editItems,
  ),
  getEntityAddressEditItem: (store) => findEditItemByUnitType(
    EditRequestUnitTypes.EntityNationalAddress, store.editItems,
  ),
};

const mutations = {
  setEditRequest: (store, request) => {
    store.editRequest = request;
  },
  setEditAdminRequestId: (store, id) => {
    store.editAdminRequestId = Number(id);
  },
  setFamilyRequest(store, request) {
    store.familyRequest = request;
  },
  setEditItems(store, items) {
    store.editItems = items;
  },
  removeEditItem(store, id) {
    store.editItems = store.editItems.filter((e) => e.id !== id);
  },
  addEditItem(store, item) {
    store.editItems = [...store.editItems, item];
  },
};
const actions = {
  fetchFamilyFundRequestForEdit: async ({ commit }, requestId) => {
    try {
      const res = await fetchFamilyFundRequest(requestId);
      const request = res?.data?.findEstablishmentAdminRequest;
      commit('setFamilyRequest', request);
      return request;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  createEditRequest: async ({ commit, rootGetters }, entityId) => {
    const adminRole = rootGetters['auth/adminRole'];
    const data = await createEditRequest(
      entityId,
      EstablishmentType.Entity,
      EditRequestTypesByAdminRole[adminRole],
    );
    commit('setEditRequest', data);
    return data;
  },
  fetchEditItems: async ({ commit, getters }) => {
    const data = await fetchEditItems(getters.getEditRequestId);
    commit('setEditItems', data);
  },
  updateEditItem: async ({ getters, dispatch }, { id, newPayload, action }) => {
    const data = await updateEditItem(id, getters.getFamilyRequest?.unitId, newPayload, action);
    await dispatch('fetchEditItems');
    return data;
  },
  submitDecisionEditRequest: async ({ getters }, { decision, reason }) => {
    const method = decision ? acceptEditRequest : rejectEditRequest;
    const data = await method(getters.getEditAdminRequestId, reason);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
