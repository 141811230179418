<template>
  <div class="section-container">
    <h3
      v-if="title"
      class="section-title"
    >
      {{ title }}
    </h3>
    <div :class="['section-inner', sectionInnerClass]">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SectionContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    sectionInnerClass: {
      type: String,
    },
  },
};
</script>
<style src="./styles.scss" lang="scss" />
