import { Gateway, GraphQLRequest } from '@/setup/axios';
import { GET_LOOKUP_DATA, LIST_BANKS } from '@/api/graphql/lookup-requests-gql';

export function fetchBanks() {
  return GraphQLRequest({
    query: LIST_BANKS,
  });
}

export function fetchDepartments() {
  return Gateway.get('static_data/departments');
}

export function fetchClassifications(type, id) {
  return Gateway.get(`static_data/classifications?type=${type}${id ? `&classification_id=${id}` : ''}`);
}

export function fetchClassification(id) {
  return Gateway.get(`static_data/classifications/${id}`);
}

export function fetchClassificationsDetails(ids, config = {}) {
  return Gateway.get(`static_data/classifications/details?second_sub_classification_ids=${ids}`, config);
}

export function fetchActivitiesByClassificationId(classificationId) {
  return Gateway.get(`static_data/activities?classification_id=${classificationId}`);
}

export function fetchGoals(classificationId, ids, type, config = {}) {
  return Gateway.get(`static_data/goals?classification_id=${classificationId}${type ? `&entity_type=${type}` : ''}${ids
    ? `&ids=${ids}` : ''}`, config);
}

export function fetchLookupData() {
  return GraphQLRequest({ query: GET_LOOKUP_DATA });
}
