import DocumentsApi from '@/api/documents.api';
import ErrorHandler from '@/helpers/errors';
import { v4 as uuid } from 'uuid';

export const getPublicDocument = async (itemId, itemType, docType) => {
  try {
    const { data } = await DocumentsApi.getPublicDocument(itemId, itemType, docType);
    return data;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const normalizeDownloadItem = (data = {}) => ({
  name: data.name,
  status: data.status || 'pending',
  icon: '',
  path: data.path || null,
  id: uuid(),
  percentage: 0,
});
