<template>
  <div
    v-click-outside="closeMenu"
    class="header-left user-menu"
    :class="{ open: isMenuOpened }"
  >
    <div
      class="ham-menu hamRotate"
      :class="{active: isMenuOpened}"
      @click="toggleMenu"
    >
      <div class="line top" />
      <div class="line middle" />
      <div class="line bottom" />
    </div>

    <transition name="fade">
      <div
        v-if="isMenuOpened"
        class="user-menu-wrap"
      >
        <ul class="user-menu-list mobile-sidebar-menu-list">
          <slot name="mobile-sidebar-items" />
        </ul>
        <lang-switcher />
        <Footer />
      </div>
    </transition>
  </div>
</template>

<script>
import LangSwitcher from '@/containers/lang-switcher/LangSwitcher.vue';
import Footer from '@/containers/footer/Footer.vue';

export default {
  name: 'Header',
  components: {
    LangSwitcher,
    Footer,
  },
  data() {
    return {
      isMenuOpened: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened;
    },
    closeMenu() {
      this.isMenuOpened = false;
    },
    handleLinkClick() {
      this.closeMenu();
    },
  },
};
</script>
