<template>
  <footer class="footer">
    <span
      class="footer-copyright"
      v-text="$t('footer.copyright')"
    />
    <div class="footer-legal">
      <router-link
        class="legal-link"
        :to="{name: 'Dashboard'}"
      >
        {{ $t('footer.privacy') }}
      </router-link>
      <router-link
        class="legal-link"
        :to="{name: 'Dashboard'}"
      >
        {{ $t('footer.terms') }}
      </router-link>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
};
</script>

<style src="./styles.scss" lang="scss"/>
