export const LIST_SEVEN_HUNDRED_ERRORS = `query ListUnifiedNumberErrors($unitId: Int!, $code: String, $error: String, $page: Int!, $size: Int!) { 
  listUnifiedNumberErrors(unitId: $unitId, code: $code, error: $error, page: $page, size: $size) {
    errors {
              id 
              unitId
              errorEn
              errorAr
              createdAt
              entity {
                nameArabic
                nameEnglish
                type
              }
              code
          }
      meta {
            page
            pageCount
            recordsCount
            size
        }
      }
  }`;
