import { Gateway } from '@/setup/axios';

function getOptionalParams(params) {
  const userId = params.userId ? `&user_id=${params.userId}` : '';
  const attachableId = params.attachableId ? `&attachable_id=${params.attachableId}` : '';
  const attachableType = params.attachableType ? `&attachable_type=${params.attachableType}` : '';
  const docType = params.docType ? `&doc_type=${params.docType}` : '';

  return `${userId}${attachableId}${attachableType}${docType}`;
}

export function fetchDocuments({
  userId, attachableId, attachableType, docType, page = 1, size = 10,
}, config = {}) {
  return Gateway.get(
    `media/search?page=${page}&size=${size}${getOptionalParams({
      userId,
      attachableId,
      attachableType,
      docType,
    })}`, config,
  );
}

export function uploadDocuments(file, onUploadProgress, attachableId, attachableType, docType) {
  const formData = new FormData();
  formData.append('file', file);
  if (attachableId) formData.append('attachable_id', attachableId);
  if (attachableType) formData.append('attachable_type', attachableType);
  if (docType) formData.append('doc_type', docType);

  return Gateway.post('media/upload-document', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

export function uploadMedia(file) {
  const formData = new FormData();
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  formData.append('file', file);
  return Gateway.post('media/v2/upload', formData, config);
}

export function deleteFile(fileId) {
  return Gateway.delete(`/media/${fileId}`);
}
