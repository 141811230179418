import * as mediaAPI from '@/api/media.api';
import { deserializer } from '@/helpers/api';
import { FileErrorTypes } from '@/constants/enums';
import { ErrorHandler } from '@/helpers';

export const normalizeDocument = (item) => {
  if (item) {
    return {
      type: item.mimeType,
      id: item.id,
      name: item.filename,
      size: item.fileSize,
      url: item.path,
      uid: item.uid,
    };
  }
  return item;
};

export const uploadDocuments = (files, attachableId, attachableType, docType) => {
  const promises = files.map((file) => {
    if (file.url) {
      return Promise.resolve(file);
    }
    return mediaAPI.uploadDocuments(file.file, (e) => {
      file.progress = Math.round((100 * e.loaded) / e.total);
    }, attachableId, attachableType, docType)
      .then(async (res) => {
        let url;
        try {
          const media = await deserializer(res.data);
          url = media.path;
          file.id = media.id;
        } catch {
          url = 'wrong url';
        }
        return Promise.resolve({
          ...file,
          progress: 0,
          ...(res.error || res.data.code === 'Failed' ? { error: res.error || FileErrorTypes.Request } : { url }),
        });
      })
      .catch(() => Promise.resolve({
        ...file,
        progress: 0,
        error: FileErrorTypes.Request,
      }));
  });
  return Promise.all(promises);
};

export const getDocumentsList = async (attachableId, attachableType, docType, userId) => {
  try {
    const res = await mediaAPI.fetchDocuments({
      attachableId,
      attachableType,
      docType,
      userId,
    });
    const result = await deserializer(res.data) || [];
    return result.map((item) => normalizeDocument(item));
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export async function deleteFile(fileId) {
  try {
    await mediaAPI.deleteFile(fileId);
  } catch (e) {
    throw new Error(e);
  }
}
