<template>
  <div id="app">
    <router-view />
    <toast-container />
    <download-list />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { fetchLookup } from '@/services/lookup.service';
import ToastContainer from '@/containers/toast/ToastContainer.vue';
import DownloadList from '@/containers/download-list/DownloadList.vue';

export default {
  name: 'App',
  components: {
    DownloadList,
    ToastContainer,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  async created() {
    fetchLookup();
    await this.getAdminProfile();
    if (this.isAuthenticated && ['Login', 'ChangePassword', 'ResetPassword'].includes(this.$route.name)) {
      this.logoutIndividual(false);
    }
  },
  methods: {
    ...mapActions({
      getAdminProfile: 'auth/getAdminProfile',
      logoutIndividual: 'auth/logoutIndividual',
    }),
  },
};
</script>
