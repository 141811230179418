<template>
  <div
    v-click-outside="closeMobileList"
    class="lang-switcher"
    :class="{ open: isOpen }"
    @mouseenter="openList"
    @mouseleave="closeList"
  >
    <button
      class="lang-button"
      tabindex="0"
      @click.stop="openMobileList"
      v-text="$t(`lang.${$i18n.locale}`)"
    />
    <div class="lang-wrap">
      <span
        class="mobile-btn"
        @click.stop="closeMobileList"
      >
        <span class="line" />
        <span class="line" />
      </span>
      <ul
        ref="langList"
        class="lang-list"
      >
        <li
          v-for="(option, key) in optionList"
          v-show="!option.active"
          :key="key"
          :tabindex="0"
          :class="{active: option.lang === $i18n.locale}"
          class="lang-list-item"
          @keypress.space.enter="onClick(option.lang)"
        >
          <span
            class="lang-list-value"
            @click.stop="onClick(option.lang)"
            v-text="$t(`lang.${option.lang}`)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { SUPPORTED_LOCALES } from '@/constants';

export default {
  name: 'LangSwitcher',
  data() {
    return {
      localeOptions: [],
      isOpen: false,
    };
  },
  computed: {
    optionList() {
      return this.localeOptions;
    },
  },
  created() {
    this.localeOptions = SUPPORTED_LOCALES.map((o) => ({
      lang: o,
      active: this.$i18n.locale === o,
    }));
  },
  methods: {
    openList() {
      if (!this.$screen['mobile-']) {
        this.isOpen = !this.isOpen;
      }
    },
    closeList() {
      if (!this.$screen['mobile-']) {
        this.isOpen = false;
      }
    },
    openMobileList() {
      this.isOpen = !this.isOpen;
    },
    closeMobileList() {
      if (this.$screen['mobile-']) {
        this.isOpen = false;
      }
    },
    onClick(lang) {
      this.localeOptions.forEach((o) => {
        o.active = lang === o.lang;
      });
      this.setLocale(lang);
      this.isOpen = false;
    },
    setLocale(lang) {
      const { locale } = this.$route.params;
      if (locale && locale !== lang) {
        this.$router.push({
          path: this.$route.fullPath.replace(`/${locale}`, `/${lang}`),
        });
      }
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
