import { GraphQLRequest } from '@/setup/axios';
import {
  MEMBERS_COUNT,
} from '@/api/graphql/user-requests-gql';

class UserRequestsApi {
  bulkMembersCount = (entityUnits) => GraphQLRequest({
    query: MEMBERS_COUNT,
    variables: {
      units: entityUnits,
    },
  })
}

export default new UserRequestsApi();
