import { Gateway, GraphQLRequest } from '@/setup/axios';
import {
  ACCEPT_ACTIVITY_REQUEST,
  FETCH_ACTIVITIES_REQUESTS_LIST,
  FETCH_CHOSEN_EXTERNAL_AUTHORITIES_BY_ACTIVITY_ID,
  FETCH_ENTITY_ACTIVITY,
  REJECT_ACTIVITY_REQUEST,
} from '@/api/graphql/activities-gql';

export function fetchActivityRequests(data = {}, config = {}) {
  return GraphQLRequest({
    query: FETCH_ACTIVITIES_REQUESTS_LIST,
    variables: {
      page: Number(data?.page) || 1,
      size: Number(data?.size) || 10,
      unitId: Number(data?.unitId) || undefined,
      status: Array.isArray(data?.status) ? data?.status : [],
      departmentId: Number(data?.departmentId) || undefined,
      unitOwnerId: Number(data?.unitOwnerId) || undefined,
      unitType: data?.unitType || undefined,
      id: Number(data?.id) || undefined,
      name: data?.name || undefined,
      entityName: data?.entityName || undefined,
    },
  }, config);
}

export function fetchActivityRequest(id) {
  return GraphQLRequest({
    query: FETCH_ENTITY_ACTIVITY,
    variables: {
      id: Number(id),
    },
  });
}

export function acceptActivityRequest(data = {}) {
  return GraphQLRequest({
    query: ACCEPT_ACTIVITY_REQUEST,
    variables: {
      entityActivityAcceptAttributes: {
        id: data.requestId ? Number(data.requestId) : undefined,
        externalAuthorities: Array.isArray(data.externalAuthorities)
          ? data.externalAuthorities.map((e) => ({
            id: Number(e.id) || undefined,
            customExternalAuthority:
              e?.customExternalAuthority
                ? String(e.customExternalAuthority)
                : undefined,
          }))
          : undefined,
      },
    },
  });
}

export function rejectActivityRequest(data = {}) {
  return GraphQLRequest({
    query: REJECT_ACTIVITY_REQUEST,
    variables: {
      entityActivityRejectAttributes: {
        id: data.requestId ? Number(data.requestId) : undefined,
        rejectReason: data.rejectReason ? String(data.rejectReason) : undefined,
      },
    },
  });
}

export function fetchExternalAuthorities() {
  return Gateway.get('external_authorities/list');
}

export function fetchChosenExternalAuthoritiesByActivityId(id) {
  return GraphQLRequest({
    query: FETCH_CHOSEN_EXTERNAL_AUTHORITIES_BY_ACTIVITY_ID,
    variables: {
      entityActivityId: Number(id),
    },
  });
}
