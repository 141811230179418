export const LIST_ENTITY_REQUESTS = `query listEditRequests($type: [EditRequestType!], $unitType: String, $status: [EditRequestStatus!], $unitId: Int, $page: Int!, $size: Int!) {
  listEditRequests(type: $type, unitType: $unitType, status: $status, unitId: $unitId, page: $page, size: $size) {
    editRequests {
      status
      id
      unitId
      type
      comments
      createdAt
      updatedAt
      version
      lockRequest {
        accountId
        requestId
        requestType
        status
      }
    }
  }
}`;

export const CREATE_EDIT_REQUEST = `mutation createEditRequest($editRequestAttributes: EditRequestCreateInput!) {
  createEditRequest(editRequestAttributes: $editRequestAttributes) {
    accountId
    id
    rejectReason
    rejectedBy
    secondSubClassificationId
    status
    type
    unitId
    unitType
    updatedAt
    userId
    version
  }
}`;

export const SUBMIT_EDIT_REQUEST = `mutation submitEditRequest($editRequestAttributes: EditRequestSubmitInput!) {
  submitEditRequest(editRequestAttributes: $editRequestAttributes) {
    accountId
    createdAt
    id
    rejectReason
    rejectedBy
    secondSubClassificationId
    status
    type
    unitId
    unitType
    updatedAt
    version
  }
}`;

export const LIST_EDIT_REQUESTS = `query listEditRequests($type: [EditRequestType!], $unitType: String, $status: [EditRequestStatus!], $unitId: Int, $page: Int!, $size: Int!) {
  listEditRequests(type: $type, unitType: $unitType, status: $status, unitId: $unitId, page: $page, size: $size) {
    editRequests {
      autoDecision,
      meta {
        traceparent
        rules
      }
      status
      id
      unitId
      accountId
      type
      comments
      rejectReason
      lockRequest {
        accountId
        requestId
        requestType
        status
      }
    }
  }
}`;

export const LIST_EDIT_ADMIN_REQUESTS = `query listEditAdminRequests($requestId: Int, $status: [AdminRequestStatus!], $type: [EditAdminRequestType!], $page: Int!, $size: Int!) {
  listEditAdminRequests(requestId: $requestId, status: $status, type: $type, page: $page, size: $size) {
    adminRequests {
        message
        adminRole
        autoDecision
        createdAt
        id
        requestId
        requestType
        status
        updatedAt
    }
    meta {
        page
        size
        recordsCount
        pageCount
    }
  }
}`;

export const ACCEPT_EDIT_REQUEST = `mutation acceptEditAdminRequest($editAdminRequestAttributes: EditAdminRequestsAcceptInput!) {
  acceptEditAdminRequest(editAdminRequestAttributes: $editAdminRequestAttributes) {
    id
  }
}`;

export const REJECT_EDIT_REQUEST = `mutation rejectEditAdminRequest($editAdminRequestAttributes: EstablishmentAdminRequestsRejectInput!) {
  rejectEditAdminRequest(editAdminRequestAttributes: $editAdminRequestAttributes) {
    id
  }
}`;

export const LIST_EDIT_ITEMS = `query listEditItems($editRequestId: Int!, $action: [EditRequestAction!], $page: Int!, $size: Int!) {
  listEditItems(editRequestId: $editRequestId, action: $action, page: $page, size: $size) {
    editItems {
      action
      createdAt
      currentPayload {
        activityIds
        additionalNumber
        amount
        bankBranchName
        bankId
        buildingNumber
        ceoCustomCondition
        ceoDegreesCondition
        ceoEducationalLevelCondition
        ceoPreviousExperienceCondition
        cityUid
        city {
          cityArTitle: arTitle
          cityEnTitle: enTitle
        }
        description
        duties
        entityId
        entityLocations {
          region {
            enTitle
            arTitle
            code
          }
          regionCode
          cityUids
        }
        fee
        goalIds
        minNumOfSharesPerMember
        nameArabic
        nameEnglish
        numberOfBoardMembers
        numberOfShares
        postCode
        regionCode
        region {
          regionArTitle: arTitle
          regionEnTitle: enTitle
        }
        rights
        secondSubClassificationId
        shareValue
        streetName
        type
        typeOfBenefits
        unitId
      }
      editRequestId
      id
      newPayload {
        activityIds
        additionalNumber
        amount
        bankBranchName
        bankId
        buildingNumber
        ceoCustomCondition
        ceoDegreesCondition
        ceoEducationalLevelCondition
        ceoPreviousExperienceCondition
        cityUid
        city {
          cityArTitle: arTitle
          cityEnTitle: enTitle
        }
        description
        duties
        entityId
        entityLocations {
          region {
            enTitle
            arTitle
            code
          }
          regionCode
          cityUids
        }
        fee
        goalIds
        minNumOfSharesPerMember
        nameArabic
        nameEnglish
        numberOfBoardMembers
        numberOfShares
        postCode
        regionCode
        region {
          regionArTitle: arTitle
          regionEnTitle: enTitle
        }
        rights
        secondSubClassificationId
        shareValue
        streetName
        type
        typeOfBenefits
        unitId
      }
      unitId
      unitType
      updatedAt
    }
  }
}`;

export const UPDATE_EDIT_ITEM = `mutation updateEditItem($editItemAttributes: EditItemUpdateInput!) {
  updateEditItem(editItemAttributes: $editItemAttributes) {
    action
    createdAt
    currentPayload {
      activityIds
      additionalNumber
      amount
      bankBranchName
      bankId
      buildingNumber
      ceoCustomCondition
      ceoDegreesCondition
      ceoEducationalLevelCondition
      ceoPreviousExperienceCondition
      cityUid
      description
      duties
      entityId
      fee
      goalIds
      minNumOfSharesPerMember
      nameArabic
      nameEnglish
      numberOfBoardMembers
      numberOfShares
      postCode
      regionCode
      rights
      secondSubClassificationId
      shareValue
      streetName
      type
      typeOfBenefits
      unitId
    }
    editRequestId
    id
    newPayload {
      activityIds
      additionalNumber
      amount
      bankBranchName
      bankId
      buildingNumber
      ceoCustomCondition
      ceoDegreesCondition
      ceoEducationalLevelCondition
      ceoPreviousExperienceCondition
      cityUid
      description
      duties
      entityId
      fee
      goalIds
      minNumOfSharesPerMember
      nameArabic
      nameEnglish
      numberOfBoardMembers
      numberOfShares
      postCode
      regionCode
      rights
      secondSubClassificationId
      shareValue
      streetName
      type
      typeOfBenefits
      unitId
    }
    unitId
    unitType
    updatedAt
  }
}`;

export const CREATE_EDIT_ITEM = `mutation createEditItem($editItemAttributes: EditItemCreateInput!) {
  createEditItem(editItemAttributes: $editItemAttributes) {
    action
    createdAt
    currentPayload {
      activityIds
      additionalNumber
      amount
      bankBranchName
      bankId
      buildingNumber
      ceoCustomCondition
      ceoDegreesCondition
      ceoEducationalLevelCondition
      ceoPreviousExperienceCondition
      cityUid
      description
      duties
      entityId
      fee
      goalIds
      minNumOfSharesPerMember
      nameArabic
      nameEnglish
      numberOfBoardMembers
      numberOfShares
      postCode
      regionCode
      rights
      secondSubClassificationId
      shareValue
      streetName
      type
      typeOfBenefits
      unitId
    }
    editRequestId
    id
    newPayload {
      activityIds
      additionalNumber
      amount
      bankBranchName
      bankId
      buildingNumber
      ceoCustomCondition
      ceoDegreesCondition
      ceoEducationalLevelCondition
      ceoPreviousExperienceCondition
      cityUid
      description
      duties
      entityId
      fee
      goalIds
      minNumOfSharesPerMember
      nameArabic
      nameEnglish
      numberOfBoardMembers
      numberOfShares
      postCode
      regionCode
      rights
      secondSubClassificationId
      shareValue
      streetName
      type
      typeOfBenefits
      unitId
    }
    unitId
    unitType
    updatedAt
  }
}`;

export const DELETE_EDIT_ITEM = `mutation deleteEditItem($id: Int!) {
  deleteEditItem(id: $id) {
    id
    uid
  }
}`;

export const FETCH_EDIT_POLICY_REQUEST_DETAILS = `query fetchEditPolicyRequestDetails ($requestId: Int!) {
  findEditRequest(id: $requestId) {
    accountId
    createdAt
    id
    lockRequest {
      accountId
      requestId
      requestType
      status
    }
    rejectReason
    rejectedBy
    secondSubClassificationId
    status
    type
    unitId
    unitType
    updatedAt
    userId
    version
    unit {
      ...on Entity {
        nameArabic,
        nameEnglish,
        entityUid,
        type,
      }
    }
    user {
        firstName
        fatherName
        grandfatherName
        lastName
    }
  }
}`;

export const FETCH_EDIT_POLICY_REQUEST_LIST = `query fetchEditPolicyRequestsList (
  $page: Int!,
  $size: Int!,
  $type: [EditRequestType!],
  $status: [EditRequestStatus!],
  $secondSubClassificationId: Int,
  $unitId: Int,
  $unitType: String,
  $sortByCreatedAt: Boolean,
  $id: Int,
  $entityName: String,
  ) {
    listEditRequests(
      page: $page
      size: $size
      type: $type
      status: $status
      secondSubClassificationId: $secondSubClassificationId
      unitId: $unitId
      unitType: $unitType
      sortByCreatedAt: $sortByCreatedAt
      id: $id,
      entityName: $entityName,
    ) {
      editRequests {
        id,
        autoDecision,
        meta {
          traceparent
          rules
        }
        accountId
        createdAt
        id
        lockRequest {
          accountId
          requestId
          requestType
          status
        }
        rejectReason
        rejectedBy
        secondSubClassificationId
        status
        type
        unitId
        unitType
        unit {
          ...on Entity {
            nameArabic,
            nameEnglish,
            entityUid,
            type,
          }
        }
        user {
           firstName
            fatherName
            grandfatherName
            lastName
        }
         remainingData {
           remainingType
           remainingValue
         }
        updatedAt
        userId
        version
      }
      meta {
        page
        pageCount
        recordsCount
        size
      }
    }
}`;

export const LIST_EDIT_POLICY_ITEMS = `query listEditItems($editRequestId: Int!, $action: [EditRequestAction!], $page: Int!, $size: Int!) {
  listEditItems(editRequestId: $editRequestId, action: $action, page: $page, size: $size) {
    editItems {
      action
      createdAt
      unitId
      unitType
      updatedAt
      editRequestId
      id
      currentPayload {
        activityIds
        additionalNumber
        amount
        bankBranchName
        bankId
        buildingNumber
        ceoCustomCondition
        ceoDegreesCondition
        ceoEducationalLevelCondition
        ceoPreviousExperienceCondition
        cityUid
        city {
          cityArTitle: arTitle
          cityEnTitle: enTitle
        }
        description
        duties
        entityId
        fee
        goalIds
        minNumOfSharesPerMember
        nameArabic
        nameEnglish
        numberOfBoardMembers
        numberOfShares
        postCode
        regionCode
        region {
          regionArTitle: arTitle
          regionEnTitle: enTitle
        }
        rights
        secondSubClassificationId
        shareValue
        streetName
        type
        typeOfBenefits
        unitId
        activitiesArTitles: activities {
          value: arTitle
        }
        goalsArTitles: goals {
          ...on CommonGoal {
            value: arTitle
          }
        }
        goalsEnTitles: goals {
          ...on CommonGoal {
            value: enTitle
          }
        }
        firstSubClassificationArTitle: classificationDetails {
          value: firstSubClassificationArTitle
        }
        firstSubClassificationEnTitle: classificationDetails {
          value: firstSubClassificationEnTitle
        }
        mainClassificationArTitle: classificationDetails {
          value: mainClassificationArTitle
        }
        mainClassificationEnTitle: classificationDetails {
          value: mainClassificationEnTitle
        }
        secondSubClassificationArTitle: classificationDetails {
          value: secondSubClassificationArTitle
        }
        secondSubClassificationEnTitle: classificationDetails {
          value: secondSubClassificationEnTitle
        }
        entityLocations {
          region {
            enTitle
            arTitle
            code
          }
          regionCode
          cityUids
        }
      }
      newPayload {
        activityIds
        additionalNumber
        amount
        bankBranchName
        bankId
        buildingNumber
        ceoCustomCondition
        ceoDegreesCondition
        ceoEducationalLevelCondition
        ceoPreviousExperienceCondition
        cityUid
        city {
          cityArTitle: arTitle
          cityEnTitle: enTitle
        }
        description
        duties
        entityId
        fee
        goalIds
        minNumOfSharesPerMember
        nameArabic
        nameEnglish
        numberOfBoardMembers
        numberOfShares
        postCode
        regionCode
        region {
          regionArTitle: arTitle
          regionEnTitle: enTitle
        }
        rights
        secondSubClassificationId
        shareValue
        streetName
        type
        typeOfBenefits
        unitId
        activitiesArTitles: activities {
          value: arTitle
        }
        goalsArTitles: goals {
          ...on CommonGoal {
            value: arTitle
          }
        }
        goalsEnTitles: goals {
          ...on CommonGoal {
            value: enTitle
          }
        }
        firstSubClassificationArTitle: classificationDetails {
          value: firstSubClassificationArTitle
        }
        firstSubClassificationEnTitle: classificationDetails {
          value: firstSubClassificationEnTitle
        }
        mainClassificationArTitle: classificationDetails {
          value: mainClassificationArTitle
        }
        mainClassificationEnTitle: classificationDetails {
          value: mainClassificationEnTitle
        }
        secondSubClassificationArTitle: classificationDetails {
          value: secondSubClassificationArTitle
        }
        secondSubClassificationEnTitle: classificationDetails {
          value: secondSubClassificationEnTitle
        }
        entityLocations {
          region {
            enTitle
            arTitle
            code
          }
          regionCode
          cityUids
        }
      }
    }
  }
}`;
