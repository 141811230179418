<template>
  <div class="sidebar-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SidebarContent',
};
</script>

<style src="./styles.scss" lang="scss" />
