import { getField, updateField } from 'vuex-map-fields';
import isEmpty from 'lodash.isempty';
import i18n from '@/i18n';
import { timeConvert, formatDate } from '@/helpers/date';

import {
  acceptActivityRequest,
  rejectActivityRequest,
  fetchActivityRequest,
  fetchActivityRequests,
  fetchExternalAuthorities,
  fetchChosenExternalAuthoritiesByActivityId,
} from '@/api/activities.api';
import { abortController, deserializer } from '@/helpers/api';
import { ErrorHandler } from '@/helpers';
import { ActivityRequestApprovalStatuses, AdminRoles } from '@/constants/enums';

const cancelSourceActivities = abortController();
const abortControllerActivities = 'Abort Controller Activities';

const handleRequestsList = (requestList) => (
  requestList.map((r) => ({
    ...r,
    entityNameAr: r.entity?.nameArabic || '',
    entityNameEn: r.entity?.nameEnglish || '',
    createdAt: formatDate(r.createdAt),
    updatedAt: r.updatedAt ? formatDate(r.updatedAt) : formatDate(r.createdAt),
  }))
);

const handleRequest = (request) => ({
  ...request,
  startActivity: formatDate(request?.startActivity),
  endActivity: formatDate(request?.endActivity),
  remainingAt: timeConvert(request.remainingTime),
});

function handleFilterOptions(options) {
  return {
    unitId: options.unitId,
    id: options.id,
    name: options.name,
    entityName: options.entityName,
  };
}

const getListWithoutChosen = (chosen, list) => {
  const arrOfChosenId = chosen.map((item) => {
    if (Number(item.externalAuthorityId) === 6) return false;
    return Number(item.externalAuthorityId);
  });
  return list.filter((item) => !arrOfChosenId.includes(Number(item.id)));
};

const getDefaultValue = (prop) => {
  switch (typeof prop) {
    case 'string':
      return '';
    case 'object':
      return null;
    default:
      return null;
  }
};

const getDurationTypeTranslation = (durationType) => {
  switch (durationType) {
    case 'months':
      return i18n.t('entityActivitiesRequestDetails.months');
    case 'days':
      return i18n.t('entityActivitiesRequestDetails.days');
    case 'hours':
      return i18n.t('entityActivitiesRequestDetails.hours');
    default:
      return '';
  }
};

const adminsAfterSupervisory = [
  AdminRoles.GovernanceAdmin,
  AdminRoles.HrsdEmployee,
];

const mainState = {
  requests: [],
  error: null,
  requestDetails: null,
  requestStatus: '',
  allExternalAuthorities: [],
  residualExternalAuthorities: [],
  customExternalAuthorities: '',
  chosenExternalAuthorities: [],
  totalRequestsCount: 0,
  isLoading: false,
  filterOptions: {},
};

const mainGetters = {
  getField,
  getRequests: (store) => store.requests,
  getError: (store) => store.error,
  getRequestDetails: (store) => ({
    ...store.requestDetails,
    duration: `${store.requestDetails?.duration?.value} ${getDurationTypeTranslation(store.requestDetails?.duration?.type)}`,
  }),
  getRequestStatus: (store) => store.requestStatus,
  getAllExternalAuthorities: (store) => store.allExternalAuthorities,
  getCustomExternalAuthorities: (store) => store.customExternalAuthorities,
  getResidualExternalAuthorities: (store) => store.residualExternalAuthorities,
  getChosenExternalAuthorities: (store) => store.chosenExternalAuthorities,
  getTotalRequestCount: (store) => store.totalRequestsCount,
  isLoading: (store) => store.isLoading,
};

const mainMutations = {
  updateField,
  setRequests(state, list) {
    state.requests = list;
  },
  setError(state, error) {
    state.error = error;
  },
  setAllExternalAuthorities(state, array) {
    state.allExternalAuthorities = array;
  },
  setChosenExternalAuthorities(state, array) {
    state.chosenExternalAuthorities = array;
  },
  setResidualExternalAuthorities(state, array) {
    state.residualExternalAuthorities = array;
  },
  setRequestDetails(state, request) {
    state.requestDetails = request;
  },
  setTotalRequestsCount: (store, value) => {
    store.totalRequestsCount = value;
  },
  setLoading: (state, boolean) => {
    state.isLoading = boolean;
  },
  setValue: (state, keyName, value) => {
    state[keyName] = value;
  },
  setRequestStatus: (state, value) => {
    state.requestStatus = value;
  },
  setFilterOptions: (state, filterOptions) => {
    state.filterOptions = { ...handleFilterOptions(filterOptions) };
  },
  resetFilterOptions: (state) => {
    state.filterOptions = {
      ...handleFilterOptions({
        unitId: null,
        id: null,
        name: null,
        entityName: null,
      }),
    };
  },
};

const mainActions = {
  cancelRequest: () => {
    if (cancelSourceActivities.hasItems()) {
      cancelSourceActivities.abort(abortControllerActivities);
    }
  },
  async loadRequestList({ commit, rootGetters },
    {
      namespace,
      status,
    }) {
    commit('setLoading', true);
    if (cancelSourceActivities.hasItems()) {
      cancelSourceActivities.abort(abortControllerActivities);
    }
    const cancelToken = cancelSourceActivities.register();
    if (!status.length) {
      switch (rootGetters['auth/adminRole']) {
        case AdminRoles.GovernanceAdmin:
          status = [ActivityRequestApprovalStatuses.GovernanceAdminApproval];
          break;
        case AdminRoles.HrsdEmployee:
          status = [ActivityRequestApprovalStatuses.HrsdEmployeeApproval];
          break;
        case AdminRoles.GovernanceCenterEmployee:
          status = [ActivityRequestApprovalStatuses.GovernanceEmployeeRecommendation];
          break;
        default:
          status = [ActivityRequestApprovalStatuses.SupervisoryApproval];
      }
    }
    const approvalStatuses = {
      [AdminRoles.GovernanceAdmin]: ActivityRequestApprovalStatuses.GovernanceAdminApproval,
      [AdminRoles.HrsdEmployee]: ActivityRequestApprovalStatuses.HrsdEmployeeApproval,
      [AdminRoles.GovernanceCenterEmployee]: ActivityRequestApprovalStatuses
        .GovernanceEmployeeRecommendation,
      [AdminRoles.Supervisory]: ActivityRequestApprovalStatuses.SupervisoryApproval,
    };
    try {
      const {
        unitId, id, name, entityName,
      } = mainState.filterOptions;
      const response = await fetchActivityRequests({
        status: status || [approvalStatuses[rootGetters['auth/adminRole']]],
        unitId,
        id,
        name,
        entityName,
        departmentId: rootGetters['auth/departmentId'],
        page: rootGetters[`${namespace}/selectedPage`],
        size: rootGetters[`${namespace}/selectedSize`],
      }, { cancelToken });

      const requestList = response.data.listNewEntityActivity?.entityActivities;
      const { meta } = response.data.listNewEntityActivity;

      if (meta) {
        commit(`${namespace}/setTotalPages`, meta.pageCount, { root: true });
        commit(`${namespace}/setTotalRecords`, meta.recordsCount, { root: true });
        commit('setTotalRequestsCount', meta.recordsCount);
      }

      commit('setRequests', handleRequestsList(requestList));
    } catch (error) {
      // if (error.message !== abortControllerActivities)
      console.warn(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchAllExternalAuthorities({ commit }) {
    try {
      const requestedList = await fetchExternalAuthorities();
      const list = await deserializer(requestedList.data);
      commit('setAllExternalAuthorities', list);
    } catch (error) {
      throw ErrorHandler.parseFormErrors(error);
    }
  },
  async fetchChosenExternalAuthorities({ commit }, activityId) {
    try {
      const requestData = await fetchChosenExternalAuthoritiesByActivityId(activityId);
      const chosenExternalAuthorities = requestData.data?.listActivityAuthoritiesByActivity || [];
      commit('setChosenExternalAuthorities', chosenExternalAuthorities);
      return chosenExternalAuthorities;
    } catch (error) {
      return ErrorHandler.parseFormErrors(error);
    }
  },
  async loadRequestDetails({
    commit, dispatch, rootGetters, getters,
  }, id) {
    commit('setError', null);
    commit('setLoading', true);
    try {
      const res = await fetchActivityRequest(id);
      const requestDetails = handleRequest(res.data.findNewEntityActivity);
      commit('setRequestStatus', requestDetails.status);
      commit('setRequestDetails', requestDetails);
      const allExternalAuthorities = dispatch('fetchAllExternalAuthorities');
      if (adminsAfterSupervisory.includes(rootGetters['auth/adminRole'])) {
        const chosenExternalAuthorities = dispatch('fetchChosenExternalAuthorities', id);
        await Promise.all([allExternalAuthorities, chosenExternalAuthorities]);
        const allExternalAuthoritiesArray = getters.getAllExternalAuthorities;
        if (!isEmpty(chosenExternalAuthorities)) {
          const cleanArray = getListWithoutChosen(
            chosenExternalAuthorities,
            allExternalAuthoritiesArray,
          );
          commit('setResidualExternalAuthorities', cleanArray);
        }
      } else {
        await allExternalAuthorities;
      }
    } catch (error) {
      console.warn(error);
      commit('setError', error.response.status);
    } finally {
      commit('setLoading', false);
    }
  },
  async sendDecisionRequest({ dispatch }, data) {
    try {
      let res;
      if (data.status === ActivityRequestApprovalStatuses.Accepted) {
        res = await acceptActivityRequest(data);
      } else {
        res = await rejectActivityRequest(data);
      }
      dispatch('toast/showNotification', {
        message: i18n.t('general.requestHasBeenSent'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  resetData({ commit, state }, resetKeysArray) {
    if (
      isEmpty(resetKeysArray)
      || !resetKeysArray
      || !Array.isArray(resetKeysArray)
    ) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('Function <resetData> get not valid array of state keys, and will not reset anything. If you want to reset any value - pass the array with strings (names of state keys to reset). Example ["key1", "key2" ...] ');
      }
      return;
    }
    resetKeysArray.forEach((prop) => {
      const defaultValue = getDefaultValue(state[prop]);
      commit('setValue', prop, defaultValue);
    });
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
