import axios from 'axios';
import * as mediaAPI from '@/api/media.api';
import { deserializer } from '@/helpers/api';
import { ErrorHandler } from '@/helpers';

let cancelSource = null;

const handleDocumentList = (arr) => arr.map((doc) => ({
  title: doc.filename,
  customUrl: doc.path,
  uid: doc.uid,
}));

const state = {
  loading: false,
  documentsList: [],
};

const getters = {
  getDocumentsList: (state) => state.documentsList,
  isLoading: (state) => state.loading,
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  setDocumentsList(state, list) {
    state.documentsList = list;
  },
};

const actions = {
  cancelRequest: () => {
    if (cancelSource) {
      cancelSource.cancel('abort request');
    }
  },
  async loadDocumentsListByDocumentType({ commit, rootGetters }, docType) {
    commit('setLoading', true);
    cancelSource = axios.CancelToken.source();
    let docList = [];
    try {
      const res = await mediaAPI.fetchDocuments({
        docType,
        page: rootGetters['referencesPagination/selectedPage'],
        size: rootGetters['referencesPagination/selectedSize'],
      }, { cancelToken: cancelSource.token });
      const result = await deserializer(res.data);
      docList = handleDocumentList(result);
      commit('setDocumentsList', docList);
      commit('referencesPagination/setTotalPages', res.data.meta.page_count, { root: true });
      commit('referencesPagination/setTotalRecords', res.data.meta.records_count, { root: true });
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    } finally {
      commit('setLoading', false);
    }
    return docList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
