<template>
  <section
    class="sidebar-list-dropdown"
    :class="{'collapsed': isDropDownCollapsed}"
  >
    <button
      class="sidebar-dropdown-activator sidebar-list-link"
      :class="{ 'sidebar-active-link': isActiveClass }"
      @click="toggleDropDown"
    >
      <slot name="activator" />
    </button>
    <transition name="fade">
      <ul
        v-if="isDropDownCollapsed"
        class="sidebar-dropdown-items"
      >
        <slot name="nestedListItem" />
      </ul>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'SidebarListDropdown',
  props: {
    isCollapsed: {
      type: Boolean,
    },
    availableRoutes: {
      type: Array,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isDropDownCollapsed: this.isCollapsed,
    };
  },
  computed: {
    isActiveClass() {
      return this.isActive
        || this.availableRoutes?.find((route) => route.routeTo?.name === this.$route.name);
    },
  },
  methods: {
    toggleDropDown() {
      this.isDropDownCollapsed = !this.isDropDownCollapsed;
    },
  },
};
</script>

<style src="./styles.scss" lang="scss" />
