import isEmpty from 'lodash.isempty';
import * as lookupAPI from '@/api/lookup.api';
import { deserializer } from '@/helpers/api';
import i18n from '@/i18n';
import { LocaleTypes } from '@/constants/enums';

class BrokenParametr extends Error {
  constructor(message, status) {
    super(message);
    this.name = 'ReadError';
    this.status = status || 404;
  }
}

const state = {
  bankList: [],
  mainClassifications: [],
  firstSubClassifications: [],
  secondSubClassifications: [],
  activities: [],
  goals: [],
  departments: [],
};

const getters = {
  bankList: (store) => store.bankList,
  bankOptions: (store) => store.bankList.map((bank) => ({
    label: i18n.locale === LocaleTypes.Ar ? bank.arTitle : bank.enTitle,
    key: bank.id,
  })),
  mainClassifications: (store) => store.mainClassifications.map((cl) => ({
    label: cl.arTitle,
    key: Number(cl.id),
  })),
  firstSubClassifications: (store) => store.firstSubClassifications.map((cl) => ({
    label: cl.arTitle,
    key: Number(cl.id),
  })),
  secondSubClassifications: (store) => store.secondSubClassifications.map((cl) => ({
    label: cl.arTitle,
    key: Number(cl.id),
  })),
  activitiesOptions: (store) => store.activities.map((cl) => ({
    label: cl.arTitle,
    key: cl.id,
  })),
  goalsOptions: (store) => store.goals.map((g) => ({
    label: g.arTitle,
    key: g.id,
  })),
  departmentsOptions: (store) => store.departments.map((cl) => ({
    label: cl.arTitle,
    key: cl.id,
  })),
};

const mutations = {
  setBanks: (store, list) => {
    store.bankList = list;
  },
  setMainClassification: (store, list) => {
    store.mainClassifications = list;
  },
  setFirstSubClassification: (store, list) => {
    store.firstSubClassifications = list;
  },
  setSecondSubClassification: (store, list) => {
    store.secondSubClassifications = list;
  },
  setActivities: (store, list) => {
    store.activities = list;
  },
  setGoals: (store, list) => {
    store.goals = list;
  },
  setDepartments: (store, list) => {
    store.departments = list;
  },
};

const actions = {
  fetchBanks: async ({ commit, state }) => {
    if (!isEmpty(state.bankList)) return [];
    let banks = null;
    try {
      const { data } = await lookupAPI.fetchBanks();
      banks = Object.freeze(data.banks);
      commit('setBanks', banks);
    } catch (e) {
      console.warn(e);
      banks = {
        error: e,
      };
    }
    return banks;
  },
  fetchDepartments: async ({ commit, state }) => {
    try {
      if (!isEmpty(state.departments)) return;
      const res = await lookupAPI.fetchDepartments();
      const result = await deserializer(res.data);
      commit('setDepartments', Object.freeze(result));
    } catch (e) {
      console.warn(e);
    }
  },
  fetchClassifications: async ({ commit }, { type, id }) => {
    let classifications = null;
    try {
      const res = await lookupAPI.fetchClassifications(type, id);
      const result = await deserializer(res.data);
      classifications = Object.freeze(result);
      commit(`set${type}`, Object.freeze(result));
    } catch (e) {
      console.warn(e);
      classifications = {
        error: e,
      };
    }
    return classifications;
  },
  fetchActivities: async ({ commit }, { classificationId }) => {
    let activities = null;
    try {
      const res = await lookupAPI.fetchActivitiesByClassificationId(classificationId);
      const result = await deserializer(res.data);
      activities = Object.freeze(result);
      commit('setActivities', activities);
    } catch (e) {
      console.warn(e);
      activities = {
        error: e,
      };
    }
    return activities;
  },
  fetchGoals: async ({ commit }, { classificationId, ids, type }) => {
    let goals = null;
    if (!classificationId) {
      const errMsg = 'ClassificationId not defined';
      console.warn(errMsg);
      return {
        error: {
          response: new BrokenParametr(errMsg, 404),
        },
      };
    }
    try {
      const res = await lookupAPI.fetchGoals(classificationId, ids, type);
      const result = await deserializer(res.data);
      goals = Object.freeze(result);
      commit('setGoals', goals);
    } catch (e) {
      console.warn(e);
      goals = {
        error: e,
      };
    }
    return goals;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
