import {
  EntityTypes, EditRequestFieldActions, EditRequestUnitTypes, AdminRoles,
} from '@/constants/enums';
import * as editPolicyAPI from '@/api/edit-policy.api';
import { ErrorHandler } from '@/helpers';

function pickEntityLocations(data) {
  return data
    .map((o) => ({
      id: o.id,
      editItemId: o.editItemId,
      field: o.unitType,
      action: o.action,
      old: [
        ...o.currentPayload?.entityLocations,
      ],
      new: [
        ...o.newPayload?.entityLocations,
      ],
    }));
}

export const acceptEditPolicyRequest = async (editId) => {
  try {
    const res = await editPolicyAPI.acceptEditPolicyRequest(editId);
    return res?.data?.acceptEditRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const rejectEditPolicyRequest = async (editId, rejectReason) => {
  try {
    const res = await editPolicyAPI.rejectEditPolicyRequest(editId, rejectReason);
    return res?.data?.rejectEditRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const submitEditPolicyRequest = async (editId, comments) => {
  try {
    const res = await editPolicyAPI.submitEditPolicyRequest(editId, comments);
    return res?.data?.submitEditRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

function pickRegulatonItemsAndGeographicInformation(data, entity) {
  let fields = [
    'numberOfBoardMembers',
  ];

  if (EntityTypes.CooperativeAssociation === entity?.entityType) {
    fields = fields.concat([
      'minNumOfSharesPerMember',
      'numberOfShares',
      'shareValue',
    ]);
  }

  return Object.entries(data?.currentPayload || {})
    .filter(([field]) => fields.includes(field))
    .map(([firstItem, lastItem]) => {
      const oldData = { value: lastItem };
      const newData = data?.newPayload[firstItem] ? { value: data?.newPayload[firstItem] } : {};
      const action = data?.newPayload[firstItem]
        ? { action: data.action }
        : { action: EditRequestFieldActions.NotUpdated };
      return {
        field: firstItem,
        ...action,
        old: oldData,
        new: newData,
      };
    });
}

function pickGoalsAndActivities(data) {
  const fields = {
    mainClassificationArTitle: 'mainClassification',
    mainClassificationEnTitle: 'mainClassification',
    firstSubClassificationArTitle: 'firstSubClassification',
    firstSubClassificationEnTitle: 'firstSubClassification',
    secondSubClassificationArTitle: 'secondSubClassification',
    secondSubClassificationEnTitle: 'secondSubClassification',
    goalsArTitles: 'goals',
    goalsEnTitles: 'goals',
    activitiesArTitles: 'activities',
    activitiesEnTitles: 'activities',
  };
  const goalsAndAcitivitiesOldData = Object.entries(data?.currentPayload)
    .filter(([field]) => Object.keys(fields).includes(field));

  const goalsAndAcitivitiesNewData = Object.entries(data?.newPayload)
    .filter(([field]) => Object.keys(fields).includes(field));

  const filteredGoalsAndAcitivitiesOldData = {};

  goalsAndAcitivitiesOldData.forEach(function handleField([field, value]) {
    if (filteredGoalsAndAcitivitiesOldData[this?.[field]]) {
      filteredGoalsAndAcitivitiesOldData[this?.[field]] = {
        ...filteredGoalsAndAcitivitiesOldData[this?.[field]],
        translationEn: value,
      };
      return;
    }
    filteredGoalsAndAcitivitiesOldData[this?.[field]] = {
      ...filteredGoalsAndAcitivitiesOldData[this?.[field]],
      translationAr: value,
    };
  }, fields);

  const filteredGoalsAndAcitivitiesNewData = {};

  goalsAndAcitivitiesNewData.forEach(function handleField([field, value]) {
    if (filteredGoalsAndAcitivitiesNewData[this?.[field]]) {
      filteredGoalsAndAcitivitiesNewData[this?.[field]] = {
        ...filteredGoalsAndAcitivitiesNewData[this?.[field]],
        translationEn: value,
      };
      return;
    }
    filteredGoalsAndAcitivitiesNewData[this?.[field]] = {
      ...filteredGoalsAndAcitivitiesNewData[this?.[field]],
      translationAr: value,
    };
  }, fields);

  return Object.entries({
    ...filteredGoalsAndAcitivitiesOldData,
    ...filteredGoalsAndAcitivitiesNewData,
  })
    .map(([key]) => {
      const oldData = { value: filteredGoalsAndAcitivitiesOldData[key] };
      const newData = { value: filteredGoalsAndAcitivitiesNewData[key] };
      const action = filteredGoalsAndAcitivitiesNewData[key]
        ? { action: data.action }
        : { action: EditRequestFieldActions.NotUpdated };
      return {
        field: key,
        ...action,
        old: oldData,
        new: newData,
      };
    });
}

export function handleEditPolicyRequest({ data, adminRole, entityInfo }) {
  const entityRequest = data.find((o) => o.unitType === EditRequestUnitTypes.Entity);
  const entityLocationRequest = data.filter((o) => (
    o.unitType === EditRequestUnitTypes.EntityLocation
  ));
  if (adminRole === AdminRoles.Supervisory) {
    return [
      ...pickGoalsAndActivities(entityRequest),
    ];
  }
  if (adminRole === AdminRoles.GovernanceAdmin) {
    return [
      ...pickEntityLocations(entityLocationRequest),
      ...pickRegulatonItemsAndGeographicInformation(entityRequest, entityInfo),
    ];
  }
  return [];
}

export function filterNullValuesFromObj(obj) {
  const copyObj = { ...obj };
  const newObj = {};
  Object
    .keys(copyObj)
    .forEach((key) => {
      if (copyObj[key] !== null) {
        newObj[key] = copyObj[key];
      }
    });
  return newObj;
}

export function filterEmptyArrayFromObj(obj) {
  const copyObj = { ...obj };
  const newObj = {};
  Object
    .keys(copyObj)
    .forEach((key) => {
      if (!(Array.isArray(copyObj[key]) && copyObj[key].length === 0)) {
        newObj[key] = copyObj[key];
      }
    });
  return newObj;
}
