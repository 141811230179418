import { getField, updateField } from 'vuex-map-fields';
import EntityProfileApi from '@/api/entity-profile.api';
import ErrorHandler from '@/helpers/errors';
import { EntityTypes } from '@/constants/enums';
import { normalizeEntityLocations } from '@/services/requests.service';
import { findEntityNew } from '@/services/entity.service';
import { PhoneFormatterAdapter } from '@/helpers';

const normalizeEntityContacts = (data = {}) => ({
  contactId: data.id,
  email: data.email || '',
  facebook: data.facebookAccount || '',
  instagram: data.instagramAccount || '',
  linkedin: data.linkedinAccount || '',
  phone: data.phoneNumber ? data.phoneNumber.split('00966')[1] : data.phoneNumber || '',
  snapchat: data.snapchatAccount || '',
  telephone: data.telephoneNumber ? data.telephoneNumber.split('00966')[1] : data.telephoneNumber || '' || '',
  twitter: data.twitterAccount || '',
  unifiedNumber: data.unifiedNumber || '',
  website: data.website || '',
});

const normalizeEntityProfile = (data = {}) => ({
  avatar: data?.entityProfile?.logo || null,
  ...data,
  areasOfActivity: normalizeEntityLocations(data?.areasOfActivity),
});

const mainState = {
  entityProfile: normalizeEntityProfile(),
  memberships: [],
  membershipConditions: [],
  entityContacts: normalizeEntityContacts(),
  entityEmployees: [],
  entityEmployeesLoading: false,
  citiesForIssuingUnifiedNumber700: [],
  selectedCityIdForIssuingUnifiedNumber700: '',
};

const mainGetters = {
  getField,
  getEntityProfile: (state) => state.entityProfile,
  getProfileMemberships: (state) => state.memberships,
  getProfileMembershipConditions: (state) => state.membershipConditions,
  getEntityContacts: (state) => state.entityContacts,
  memberRole: (state) => state.entityProfile?.memberRole,
  entityType: (state) => state.entityProfile?.type,
  isPrivateCorporation: (state) => state.entityProfile?.type === EntityTypes.PrivateCorporation,
  isPrivateAssociation: (state) => state.entityProfile?.type === EntityTypes.PrivateAssociation,
  getEntityEmployees: (state) => state.entityEmployees,
  getEntityEmployeesLoading: (state) => state.entityEmployeesLoading,
  getCitiesForIssuingUnifiedNumber700: (state) => state.citiesForIssuingUnifiedNumber700
    .map((e) => ({
      label: e?.name,
      key: e?.cityCode,
    })),
  getSelectedCityIdForIssuingUnifiedNumber700: (state) => (
    state.selectedCityIdForIssuingUnifiedNumber700
  ),
};

const mainMutations = {
  updateField,
  setEntityProfile: (store, profile) => {
    store.entityProfile = normalizeEntityProfile(profile);
  },
  setEntityProfileMemberships: (store, data) => {
    store.memberships = data;
  },
  setEntityProfileMembershipConditions: (store, data) => {
    store.membershipConditions = data;
  },
  setEntityProfileMembersInfo: (store, id) => {
    store.memberships = store.memberships.map(
      (m) => ({
        ...m,
        showInfo: m.id === id ? !m.showInfo : false,
      }),
    );
    store.errors = [];
  },
  setEntityProfileEmployeesInfo: (store, id) => {
    store.entityEmployees = store.entityEmployees.map(
      (e) => ({
        ...e,
        showInfo: e.id === id ? !e.showInfo : false,
      }),
    );
    store.errors = [];
  },
  setEntityContacts: (store, data) => {
    store.entityContacts = data;
  },
  setEntityEmployees: (store, arr) => {
    store.entityEmployees = arr;
  },
  setEntityEmployeesLoading: (store, val) => {
    store.entityEmployeesLoading = val;
  },
  setCitiesForIssuingUnifiedNumber700: (state, val) => {
    if (!Array.isArray(val)) return;
    state.citiesForIssuingUnifiedNumber700 = val;
  },
  resetSelectedCityIdForIssuingUnifiedNumber700: (state) => {
    state.selectedCityIdForIssuingUnifiedNumber700 = '';
  },
};

const mainActions = {
  fetchEntityProfile: async ({ commit, dispatch }, entityId) => {
    const lazyCalls = [
      'fetchEntityContacts',
      'findEntityAdditionalInfo',
      'fetchEntityEmployees',
    ];
    lazyCalls.forEach((name) => dispatch(name, entityId));
    let result = null;
    try {
      const response = await EntityProfileApi.findEntityProfileInfoByEntityId(entityId);
      result = response?.data?.entityProfile || {};
      commit('setEntityProfile', result);
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    }
    return result;
  },

  findEntityAdditionalInfo: async ({ commit }, entityId) => {
    let result = null;
    try {
      const response = await EntityProfileApi.findEntityAdditionalInfo(entityId);
      result = response?.data || {};
      commit('setEntityProfileMemberships', result.memberships.memberships);
      commit('setEntityProfileMembershipConditions', result.membershipConditions.membershipConditions);
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    }
    return result;
  },

  fetchEntityEmployees: async ({ commit }, entityId) => {
    if (!entityId) return null;
    commit('setEntityEmployeesLoading', true);
    let result = null;
    try {
      const res = await EntityProfileApi.fetchEntityEmployees({ entityId });
      result = res.data?.listEmployeeRequests?.employeeRequests;
      commit('setEntityEmployees', result);
    } catch (e) {
      result = 1;
    } finally {
      commit('setEntityEmployeesLoading', false);
    }
    return result;
  },

  fetchEntityContacts: async ({ commit }, entityId) => {
    let result = null;
    try {
      const response = await EntityProfileApi.fetchEntityContacts(entityId);
      result = response?.data?.findEntityContactByEntityId || {};
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    } finally {
      commit('setEntityContacts', normalizeEntityContacts(result?.error ? {} : result));
    }
    return result;
  },

  prepareFormForUnifiedNumber700: async ({ dispatch }, entityId) => {
    const citiesForNumber700 = dispatch('fetchCitiesForIssuingUnifiedNumber700', {});
    const currentEntityRes = findEntityNew(entityId);
    const importantFetches = await Promise.all([
      citiesForNumber700,
      currentEntityRes,
    ]);
    const [, currentEntity] = importantFetches;
    const anyError = importantFetches.find((f) => f.error);
    if (anyError) return anyError;
    const unifiedNumber700 = currentEntity.unifiedNumber700 || null;
    if (unifiedNumber700) return { unifiedNumber700 };
    await dispatch('fetchEntityContacts', entityId);
    return {};
  },

  fetchCitiesForIssuingUnifiedNumber700: async ({ commit }, {
    name,
    page,
    size,
  }) => {
    try {
      const res = await EntityProfileApi.fetchCitiesForIssuingUnifiedNumber700({
        name,
        page,
        size,
      });
      const { cities } = res.data.listUnifiedCities;
      commit('setCitiesForIssuingUnifiedNumber700', cities);
      return res.data;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },

  issueUnifiedNumber700: async ({ getters, dispatch }, entityId) => {
    const { phone, email, telephone } = getters.getEntityContacts;
    const payload = {
      entityId,
      phoneNumber: PhoneFormatterAdapter.fromUIToServer(phone),
      email,
      telephoneNumber: PhoneFormatterAdapter.fromUIToServer(telephone),
      cityCode: getters.getSelectedCityIdForIssuingUnifiedNumber700,
    };
    try {
      const res = await EntityProfileApi.issueUnifiedNumber700(payload);
      dispatch('entities/fetchAllEntities', true, { root: true });
      return res.data?.issueUnifiedNumber700;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
