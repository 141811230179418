import * as editRequestApi from '@/api/edit-request.api';
import { ErrorHandler } from '@/helpers';

export const createEditRequest = async (entityId, unitType, editType = 'ffe_establishment_rp_employee_changes') => {
  try {
    const res = await editRequestApi.createEditRequest(entityId, unitType, editType);
    return res?.data?.createEditRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const submitEditRequest = async (id, comments) => {
  try {
    const res = await editRequestApi.submitEditRequest(id, comments);
    return res?.data?.editRequestAttributes;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const updateEditItem = async (id, unitId, newPayload, action = 'update') => {
  try {
    const res = await editRequestApi.updateEditItem(id, unitId, action, newPayload);
    return res?.data?.updateEditItem;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const createEditItem = async (editRequestId, newPayload, action = 'create', unitType = 'EntitiesBank') => {
  try {
    const res = await editRequestApi.createEditItem(
      editRequestId, action, newPayload, unitType,
    );
    return res?.data?.createEditItem;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const deleteEditItem = async (id) => {
  try {
    const res = await editRequestApi.deleteEditItem(id);
    return res?.data?.deleteEditItem;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const fetchEditItems = async (editId, action, page = 1, size = 10) => {
  try {
    const res = await editRequestApi.listEditItems(editId, action, page, size);
    return res?.data?.listEditItems?.editItems;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const listEditRequests = async (type, unitId, status, unitType = 'Entity', page = 1, size = 10) => {
  try {
    const res = await editRequestApi.listEditRequests(type, unitId, status, unitType, page, size);
    return res?.data?.listEditRequests?.editRequests;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const listEditAdminRequests = async (editId, type, status = ['pending'], page = 1, size = 10) => {
  try {
    const res = await editRequestApi.listEditAdminRequests(editId, status, type, page, size);
    return res?.data?.listEditAdminRequests?.adminRequests;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const acceptEditRequest = async (editId) => {
  try {
    const res = await editRequestApi.acceptEditRequest(editId);
    return res?.data?.acceptEditAdminRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};

export const rejectEditRequest = async (editId, reason) => {
  try {
    const res = await editRequestApi.rejectEditRequest(editId, reason);
    return res?.data?.rejectEditAdminRequest;
  } catch (e) {
    return ErrorHandler.parseFormErrors(e);
  }
};
