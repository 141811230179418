import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import { ErrorHandler } from '@/helpers';
import * as requestsAPI from '@/api/requests.api';
import * as entityAPI from '@/api/entity.api';
import { normalizeEntity, findTempBankAccountByEntityId } from '@/services/entity.service';
import { AdminRoles, EstablishmentRequestType, mediaDocTypes } from '@/constants/enums';
import { getDocumentsList } from '@/services/media.service';
import { formatDate } from '@/helpers/date';
import { normalizeRequests } from '@/services/requests.service';

let cancelSource = null;

const handleRequests = (data) => (
  data.map((r) => ({
    ...r,
    establishmentType: r.unit?.type,
    unitNameArabic: r.unit?.nameArabic,
    unitNameEnglish: r.unit?.nameEnglish,
    remainingValue: r.remainingData?.remainingValue,
    remainingType: r.remainingData?.remainingType,
    createdAt: formatDate(r.createdAt),
    updatedAt: r.updatedAt ? formatDate(r.updatedAt) : formatDate(r.createdAt),
  }))
);

const state = {
  requests: [],
  requestsCount: [],
  bankStatement: {},
  bankCertificate: {},
  requestsLoading: false,
  requestDetailsLoading: false,
  entityDetails: {},
  members: [],
  errors: [],
  tempBankAccount: null,
};

const getters = {
  getField,
  bankAccontRequests: (store) => store.requests,
  getBankAccountRequestsCount: (store) => store.requestsCount,
  isBankAccountRequestsLoading: (store) => store.requestsLoading,
  isRequestDetailsLoading: (store) => store.requestDetailsLoading,
  getEntityDetails: (store) => store.entityDetails,
  getMembers: (store) => store.members.filter((m) => m.role !== 'Owner'),
  getBankStatement: (store) => store.bankStatement,
  getBankCertificate: (store) => store.bankCertificate,
  getErrors: (store) => store.errors,
  getTempBankAccount: (store) => store.tempBankAccount,
};

const mutations = {
  updateField,
  setRequests: (store, requests) => {
    store.requests = requests;
  },
  setRequestsCount: (store, count) => {
    store.requestsCount = count;
  },
  setRequestsLoading: (store, boolean) => {
    store.requestsLoading = boolean;
  },
  setEntityDetails: (store, value) => {
    store.entityDetails = value;
  },
  setMembers: (store, value) => {
    store.members = value;
  },
  setBankStatement: (store, value) => {
    store.bankStatement = value;
  },
  setBankCertificate: (store, value) => {
    store.bankCertificate = value;
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  setTempBankAccount: (store, tempBankAccount) => {
    store.tempBankAccount = tempBankAccount;
  },
};

const actions = {
  cancelRequest: () => {
    if (cancelSource) {
      cancelSource.cancel('abort temporary bank account request');
    }
  },
  fetchBankStatement: async ({ commit }, id) => {
    try {
      const res = await getDocumentsList(
        id,
        'Entity',
        mediaDocTypes.TempBankStatement,
        null,
      );
      commit('setBankStatement', res[0]);
    } catch (error) {
      console.warn(error);
    }
  },
  fetchBankCertificate: async ({ commit }, id) => {
    try {
      const res = await getDocumentsList(
        id,
        'Entity',
        mediaDocTypes.TempBankCertificate,
        null,
      );
      commit('setBankCertificate', res[0]);
    } catch (error) {
      console.warn(error);
    }
  },
  fetchBankAccountRequestsList: async ({ commit, rootGetters }, { namespace, status, active }) => {
    commit('setErrors', []);
    cancelSource = axios.CancelToken.source();
    commit('setRequestsLoading', true);
    try {
      const res = await requestsAPI.fetchEstablishmentRequests(
        {
          adminRole: AdminRoles.HrsdEmployee,
          requestType: EstablishmentRequestType.Approval,
          status,
          active,
          page: rootGetters[`${namespace}/selectedPage`],
          size: rootGetters[`${namespace}/selectedSize`],
        }, { cancelToken: cancelSource.token },
      );
      const metaData = res?.data?.establishmentAdminRequests.meta;
      const list = res?.data?.establishmentAdminRequests?.establishmentAdminRequests;
      commit(`${namespace}/setTotalPages`, metaData?.pageCount, { root: true });
      commit(`${namespace}/setTotalRecords`, metaData?.recordsCount, { root: true });
      commit('setRequestsCount', metaData?.recordsCount);
      commit('setRequests', handleRequests(list));
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
      commit('setRequests', []);
    } finally {
      commit('setRequestsLoading', false);
    }
  },
  fetchEntityDetails: async ({ commit }, entityId) => {
    try {
      const resp = await entityAPI.findEntity(entityId);
      const entity = resp.data?.findEntityById || {};
      commit('setEntityDetails', normalizeEntity(entity));
      return normalizeEntity(entity);
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchMembers: async ({ commit }, entityId) => {
    try {
      const res = await requestsAPI.fetchUserRequests(entityId);
      if (res?.data?.error) return;
      const list = res?.data?.userRequests?.list || [];
      const memberships = list.map((member) => normalizeRequests(member));
      commit('setMembers', memberships);
    } catch (e) {
      console.warn(e);
    }
  },
  findTempBankAccountByEntityId: async ({ commit }, entityId) => {
    const tempBankAccount = await findTempBankAccountByEntityId(entityId);
    if (tempBankAccount && !tempBankAccount.error) {
      commit('setTempBankAccount', tempBankAccount);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
