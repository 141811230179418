import * as enums from '@/constants/enums';
import { IS_PROD } from '@/constants';

export const constantsPlugin = {
  install(Vue) {
    Vue.prototype.$enums = enums;
    Vue.prototype.$isProd = IS_PROD;
    Vue.prototype.$isStage = !IS_PROD;
  },
};
