import { Gateway } from '@/setup/axios';
import ErrorHandler from '@takamol/nawa-library/src/helpers/errors';
import { normalizeDownloadItem } from '@/services/documents.service';
import EntitiesListApi from '@/api/entities-list.api';
import { abortController } from '@/helpers/api';
import axios from 'axios';

const ItemStatuses = {
  Pending: 'pending',
  Downloading: 'downloading',
  Completed: 'completed',
  Failed: 'failed',
};

const strictLoadByUrl = (response = {}, fileName) => {
  // TODO: temporary lock to merge into demo
  // const url = window.URL.createObjectURL(new Blob([response?.data]));
  const url = response?.path;
  // if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const cancelGenerationSource = abortController();
const cancelDownLoadingSource = abortController();

const state = {
  isVisible: false,
  downloadList: [],
  isGeneratingLoading: false,
};

const getters = {
  downloadList: (store) => store.downloadList,
  isVisible: (store) => store.isVisible,
  isGeneratingLoading: (store) => store.isGeneratingLoading,
};

const mutations = {
  setVisible: (store, boolean) => {
    store.isVisible = boolean;
  },
  setGenerating: (store, boolean) => {
    store.isGeneratingLoading = boolean;
  },
  resetAllDownloadItems: (store) => {
    store.downloadList = [];
  },
  deleteDownloadItem: (store, id) => {
    const index = store.downloadList.findIndex((o) => o.id === id);
    store.downloadList.splice(index, 1);
  },
  setDownloadItem: (store, item) => {
    store.downloadList = [
      item,
      ...store.downloadList,
    ];
  },
  updateDownloadItem: (store, { item, id }) => {
    store.downloadList = store.downloadList.map((oldItem) => {
      if (oldItem.id !== id) return oldItem;
      return {
        ...oldItem,
        ...item,
      };
    });
  },
  updateItemStatus: (store, { item, status }) => {
    const itemForUpdate = store.downloadList.find((i) => i.id === item.id);
    itemForUpdate.status = status;
  },
};

const actions = {
  cancelGenerationRequest: (_, id) => {
    if (cancelGenerationSource.hasItems()) {
      cancelGenerationSource.abort(`abort-generation-${id}`);
    }
  },
  cancelDownLoadingRequest: (_, id) => {
    if (cancelDownLoadingSource.hasItems()) {
      cancelDownLoadingSource.abort(`abort-downloading-${id}`);
    }
  },
  removeAllDownloadItems: ({ commit, dispatch, getters }) => {
    getters.downloadList.forEach((e) => {
      dispatch('cancelGenerationRequest', e.id);
      dispatch('cancelDownLoadingRequest', e.id);
    });
    commit('resetAllDownloadItems');
  },
  removeDownloadItem: ({ commit, dispatch }, id) => {
    dispatch('cancelGenerationRequest', id);
    dispatch('cancelDownLoadingRequest', id);
    commit('deleteDownloadItem', id);
  },
  showDownloadList: ({ commit, getters }) => {
    if (getters.isVisible) {
      return;
    }
    commit('setVisible', true);
  },
  hideDownloadList: ({ commit, dispatch }) => {
    commit('setVisible', false);
    dispatch('removeAllDownloadItems');
  },
  generateReport: async ({
    commit,
    getters,
    rootGetters,
    dispatch,
  }, locale) => {
    commit('setGenerating', true);
    const filters = rootGetters['entities/getEntityFilters'];
    let result = null;
    const defaultItem = normalizeDownloadItem({});
    commit('setDownloadItem', defaultItem);
    dispatch('showDownloadList', null);
    const cancelToken = await cancelGenerationSource.register();
    try {
      const report = await EntitiesListApi.generateReports(
        filters,
        locale,
        { cancelToken },
      );
      result = report.data.getEntitiesReport;
      commit('updateDownloadItem', {
        item: {
          ...result,
          error: result?.error,
          status: result?.error ? ItemStatuses.Failed : ItemStatuses.Completed,
          order: getters.downloadList.length,
        },
        id: defaultItem.id,
      });
      strictLoadByUrl(result);
    } catch (e) {
      if (!axios.isCancel(e)) result = ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setGenerating', false);
    }
    return result;
  },
  startDownloadByPath: async ({ commit }, item) => {
    commit('setGenerating', false);
    const cancelToken = cancelDownLoadingSource.register();
    const axiosConfig = {
      withCredentials: false,
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100)
          / progressEvent.total);
        commit('updateDownloadItem', {
          item: {
            ...item,
            percentage: percentCompleted,
          },
          status: percentCompleted === 100 ? ItemStatuses.Completed : ItemStatuses.Downloading,
        });
        return percentCompleted;
      },
      cancelToken,
    };
    let response;
    try {
      response = await Gateway.get(item.path, axiosConfig);
    } catch (e) {
      response = ErrorHandler.parseFormErrors(e);
    }
    if (response.error) {
      commit('updateDownloadItem', {
        item: {
          ...item,
          error: response.error,
          status: 'failed',
        },
        id: item.id,
      });
    }
    strictLoadByUrl(response, item.name);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
