import DocumentsApi from '@/api/documents.api';
import i18n from '@/i18n';
import { generatePDF } from '@/helpers/document';
import { isFeatureAvailable } from '@/helpers/features-config';
import { featureNames } from '@/constants/enums';

const state = {
  generating: false,
};

const getters = {
  isGenerating: (store) => store.generating,
};

const mutations = {
  setGenerating: (store, value) => {
    store.generating = value;
  },
};

const actions = {
  getDocument: async ({ commit, dispatch }, {
    id,
    itemType,
    docType,
    uid,
  }) => {
    commit('setGenerating', true);
    try {
      let res;
      if (uid) {
        res = await DocumentsApi.getDocumentV2(uid);
      } else {
        if (isFeatureAvailable(featureNames.hotkeys)) {
          dispatch('toast/showNotification', {
            message: 'Deprecated API method used for document downloading',
            duration: 2000,
            type: 'warning',
          }, { root: true });
        }
        // Method is deprecated due to security reason
        res = await DocumentsApi.getDocument(id, itemType, docType);
      }
      generatePDF(
        res.data,
        `${itemType}_${docType}`,
        'application/pdf',
      );
    } catch (e) {
      console.warn(e);
      dispatch('toast/showNotification', {
        message: i18n.t('document.securityForm.error'),
        duration: 2000,
        type: 'try-again',
      }, { root: true });
    } finally {
      commit('setGenerating', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
