import axios from 'axios';
import { updateField } from 'vuex-map-fields';

import i18n from '@/i18n';
import * as lookupAPI from '@/api/lookup.api';
import * as requestsAPI from '@/api/requests.api';
import * as entityAPI from '@/api/entity.api';
import * as updatingEntitiesRequestsAPI from '@/api/updating-entities-requests.api';
import { getEntityClassifications } from '@/services/entity.service';
import {
  handleDOB,
} from '@/helpers/general';
import { formatDate } from '@/helpers/date';
import { PhoneFormatterAdapter, ErrorHandler } from '@/helpers';
import { deserializer } from '@/helpers/api';
import {
  ClassificationsTypes,
  RequestStatuses,
  EntityTypes,
  MembershipTypesOptions,
} from '@/constants/enums';
import { normalizeEntityLocations } from '@/services/requests.service';

let cancelSource = null;

function getFullName(member) {
  const firstName = member?.firstName;
  const fatherName = member?.fatherName;
  const grandfatherName = member?.grandfatherName;
  const lastName = member?.lastName;

  return `${firstName ? `${firstName}` : ''}${fatherName ? ` ${fatherName}` : ''}${grandfatherName ? ` ${grandfatherName}` : ''}${lastName ? ` ${lastName}` : ''}`;
}

function normalizeEntity(request) {
  const absherPhone = request?.unit?.owner?.absherPhone;
  const birthDateHijri = request?.unit?.owner?.birthDateHijri;
  return {
    ...request,
    createdAt: formatDate(request?.createdAt),
    type: request?.unit?.type,
    nameEnglish: request?.unit?.nameEnglish,
    nameArabic: request?.unit?.nameArabic,
    typeOfBenefits: request?.unit?.typeOfBenefits,
    owner: {
      ...request?.unit?.owner,
      fullName: getFullName(request?.unit?.owner),
      dateOfBirth: birthDateHijri ? handleDOB(birthDateHijri) : null,
      absherPhone: absherPhone
        ? PhoneFormatterAdapter.fromServerToUI(absherPhone)
        : null,
    },
    secondClassification: request?.unit?.secondSubClassificationId,
    activities: request?.unit?.activityIds,
    goals: request?.unit?.goalIds,
    membershipTypeAssociation: request?.unit?.membershipType,
    region: request?.unit?.region,
    city: request?.unit?.city,
  };
}

export const normalizeMember = (member) => ({
  isSpecialized: member.specialized,
  foundingMember: member.foundingMember,
  numberOfShares: member.numberOfShares,
  customPosition: member.customPosition,
  createdAt: formatDate(member.createdAt),
  id: member.id,
  membershipId: member.membershipId,
  status: member.status,
  member: {
    ...member.member,
    phone: PhoneFormatterAdapter.fromServerToUI(member.member?.absherPhone || ''),
  },
  position: member.position,
  role: member.role,
  governmental: member.governmental,
  showInfo: false,
  financeContributionAmount: member.financeContributionAmount,
  memberId: member.memberId,
  delegatorProof: member.delegatorProof,
  fullName: getFullName(member.member),
});

function normalizeRequest(request) {
  return {
    ...request,
    createdAt: formatDate(request?.createdAt),
  };
}

const state = {
  requestsList: [],
  request: {},
  entityLocations: [],
  membershipConditions: [],
  entityBanks: [],
  memberships: [],
  totalRequestsListCount: 0,
  isLoading: false,
};

const getters = {
  getRequestsList: ({ requestsList }) => requestsList,
  getRequest: ({ request }) => request,
  getEntityLocations: ({ entityLocations }) => entityLocations,
  getMembershipConditions: ({ membershipConditions }) => membershipConditions
    .filter((mCondition) => mCondition.type !== MembershipTypesOptions.Worker),
  getEntityBanks: ({ entityBanks }) => entityBanks,
  getTotalRequestsListCount: ({ totalRequestsListCount }) => totalRequestsListCount,
  getMemberships: ({ memberships }) => memberships,
  isLoading: ({ isLoading }) => isLoading,
  isPrivateAssociation: ({ request }) => request.type === EntityTypes.PrivateAssociation,
  isPrivateCorporation: ({ request }) => request.type === EntityTypes.PrivateCorporation,
};

const mutations = {
  updateField,
  setRequestsList: (store, list = []) => {
    store.requestsList = list;
  },
  setRequest: (store, request = {}) => {
    store.request = request;
  },
  setLoading: (store, boolean) => {
    store.isLoading = boolean;
  },
  setTotalRequestsListCount: (store, count) => {
    store.totalRequestsListCount = count;
  },
  setEntityLocations: (store, list) => {
    store.entityLocations = normalizeEntityLocations(list);
  },
  setMembershipConditions: (store, list) => {
    store.membershipConditions = list;
  },
  setMemberships: (store, memberships) => {
    store.memberships = memberships;
  },
  setVisibleInfo: (store, id) => {
    store.memberships = store.memberships.map(
      (m) => ({ ...m, showInfo: m.id === id ? !m.showInfo : false }),
    );
  },
};

const actions = {
  cancelRequest: () => {
    if (cancelSource) {
      cancelSource.cancel('abort request');
    }
  },
  fetchRequestsList: async ({ commit, rootGetters }, { status }) => {
    cancelSource = axios.CancelToken.source();

    commit('setLoading', true);
    try {
      const res = await updatingEntitiesRequestsAPI.fetchUpdatingEntitiesRequests({
        status,
        page: rootGetters['updatingEntitiesRequestsPagination/selectedPage'],
        size: rootGetters['updatingEntitiesRequestsPagination/selectedSize'],
        admin_role: 'audit_officer',
        config: axios.CancelToken.source(),
      });
      const metaData = res.data?.listNamaAdminRequest?.meta;
      const requestsList = res.data?.listNamaAdminRequest?.namaAdminRequests || [];
      if (metaData) {
        commit('updatingEntitiesRequestsPagination/setTotalPages', metaData.pageCount, { root: true });
        commit('updatingEntitiesRequestsPagination/setTotalRecords', metaData.recordsCount, { root: true });
        commit('setTotalRequestsListCount', metaData.recordsCount);
      } else {
        commit('updatingEntitiesRequestsPagination/setTotalPages', 1, { root: true });
        commit('updatingEntitiesRequestsPagination/setTotalRecords', 0, { root: true });
        commit('setTotalRequestsListCount', 0);
      }

      commit('setRequestsList', requestsList.map(normalizeRequest));
      return requestsList.map(normalizeRequest);
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  fetchRequest: async ({ commit, dispatch }, id) => {
    try {
      const res = await updatingEntitiesRequestsAPI.fetchUpdatingEntitiesRequest(id);
      const request = normalizeEntity(res.data?.findNamaAdminRequest);

      commit('setRequest', request);

      const mainClassifications = dispatch(
        'lookup/fetchClassifications',
        {
          type: ClassificationsTypes.MainClassification,
        },
        { root: true },
      );
      if (request.secondClassification) {
        const classificationJson = await lookupAPI.fetchClassification(
          request.secondClassification,
        );
        const classificationData = await deserializer(classificationJson.data);
        commit('updateField', {
          path: 'request.firstClassification',
          value: Number(classificationData?.parentClassification?.id),
        });
        commit('updateField', {
          path: 'request.classification',
          value: Number(classificationData?.parentClassification?.classificationId),
        });

        const firstSubClassification = dispatch(
          'lookup/fetchClassifications',
          {
            type: ClassificationsTypes.FirstSubClassification,
            id: classificationData?.parentClassification?.classificationId,
          },
          { root: true },
        );
        const secondSubClassification = dispatch(
          'lookup/fetchClassifications',
          {
            type: ClassificationsTypes.SecondSubClassification,
            id: classificationData?.parentClassification?.id,
          },
          { root: true },
        );
        const goals = dispatch(
          'lookup/fetchGoals',
          {
            classificationId: request.secondClassification,
            type: request.type,
          },
          { root: true },
        );
        const activities = dispatch(
          'lookup/fetchActivities',
          {
            classificationId: request.secondClassification,
          },
          { root: true },
        );
        await Promise.all([
          mainClassifications,
          firstSubClassification,
          secondSubClassification,
          goals,
          activities,
        ]);
      }
      return request;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchEntityLocations: async ({ commit }, id) => {
    try {
      const { data } = await entityAPI.fetchEntityLocationsByEntityId({ id });
      commit('setEntityLocations', data.entityLocations?.entityLocations);
      return data.entityLocations?.entityLocations;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchMembershipConditions: async ({ commit }, id) => {
    try {
      const { data } = await requestsAPI.fetchMembershipConditionsByEntityId(id);
      commit('setMembershipConditions', data.membershipConditions?.membershipConditions);
      return data.membershipConditions?.membershipConditions;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  fetchMemberships: async ({ commit }, entityId) => {
    try {
      const res = await requestsAPI.fetchUserRequests(entityId);
      const list = res.data.userRequests.list || [];
      const memberships = list.map(normalizeMember);
      commit('setMemberships', memberships);
      return memberships;
    } catch (e) {
      return ErrorHandler.parseFetchErrors(e);
    }
  },
  submitRequest: async ({ dispatch }, data) => {
    try {
      let res;
      const { requestId, statusReason } = data;
      if (data.status === RequestStatuses.Accepted) {
        res = await updatingEntitiesRequestsAPI.acceptUpdatingEntitiesRequest(requestId);
      } else {
        // eslint-disable-next-line max-len
        res = await updatingEntitiesRequestsAPI.rejectUpdatingEntitiesRequest(requestId, statusReason);
      }
      dispatch('toast/showNotification', {
        message: i18n.t('general.requestHasBeenSent'),
        duration: 4000,
        type: 'success',
      }, { root: true });
      return res;
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  getEntityClassificationsDetails: async ({ commit }, secondClassification) => {
    if (secondClassification) {
      const classifications = await getEntityClassifications(secondClassification);
      commit('updateField', {
        path: 'request.department',
        value: classifications[0]?.department,
      });
      commit('updateField', {
        path: 'request.description',
        value: classifications[0]?.description,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
