<template>
  <div
    @mouseover="getMouseleave"
    @mouseleave="getMouseover"
  >
    <lottie
      v-if="isUserAllowAnimation"
      :options="getLottieOptions"
      :height="Number(height)"
      :width="Number(width)"
      @animCreated="handleAnimation"
    />
    <svg
      v-else
      viewBox="0 0 32 32"
      :style="backupSvgName ? '' : 'overflow: visible'"
      :height="height"
      :width="width"
    >
      <text
        v-if="!backupSvgName"
        x="0"
        y="0"
        fill="#000"
      >Animation is not allowed by user</text>
      <component
        :is="backupSvgName"
        v-else
      />
    </svg>
  </div>
</template>

<script>

export default {
  name: 'BaseLottie',
  props: {
    json: {
      type: Object,
      required: true,
    },
    height: {
      type: [String, Number],
      default: '18',
    },
    width: {
      type: [String, Number],
      default: '18',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    loop: {
      type: Boolean,
      default: () => true,
    },
    autoplay: {
      type: Boolean,
      default: () => false,
    },
    animationSpeed: {
      type: Number,
      default: () => NaN,
    },
    isPlayOnMouseEvent: {
      type: Boolean,
      default: () => true,
    },
    backupSvgName: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    isUserAllowAnimation() {
      return window.matchMedia('(prefers-reduced-motion: no-preference)').matches;
    },
    getMouseleave() {
      if (!this.isUserAllowAnimation) return void (0); // eslint-disable-line no-void
      return this.isPlayOnMouseEvent ? this.play : void (0); // eslint-disable-line no-void
    },
    getMouseover() {
      if (!this.isUserAllowAnimation) return void (0); // eslint-disable-line no-void
      return this.isPlayOnMouseEvent ? this.stop : void (0); // eslint-disable-line no-void
    },
    getLottieOptions() {
      return {
        animationData: this.json,
        autoplay: this.autoplay,
        loop: this.loop,
        ...this.options,
      };
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    stop() {
      this.anim.stop();
    },
    play() {
      this.anim.play();
    },
  },
};
</script>
