import { GraphQLRequest } from '@/setup/axios';
import {
  LIST_OBJECTION_REQUESTS,
  FIND_OBJECTION_REQUEST,
  ACCEPT_OBJECTION_REQUEST, REJECT_OBJECTION_REQUEST,
} from '@/api/graphql/objection-requests-gql';
import { EstablishmentRequestType } from '@/constants/enums';

export function listObjectionRequests(status, adminRole, page, size, config) {
  return GraphQLRequest({
    query: LIST_OBJECTION_REQUESTS,
    variables: {
      status,
      adminRole,
      unitType: 'ObjectionRequest',
      page: Number(page),
      size: Number(size),
    },
  }, config);
}

export function fetchAdminObjectionRecommendation(unitId, adminRole) {
  return GraphQLRequest({
    query: LIST_OBJECTION_REQUESTS,
    variables: {
      adminRole,
      unitId: Number(unitId),
      unitType: 'ObjectionRequest',
      requestType: [EstablishmentRequestType.Recommendation],
      page: 1,
      size: 10,
    },
  });
}

export function fetchObjectionDetails(objectionId) {
  return GraphQLRequest({
    query: FIND_OBJECTION_REQUEST,
    variables: {
      id: Number(objectionId),
    },
  });
}

export function acceptObjectionAdminRequest(objectionId, message) {
  return GraphQLRequest({
    query: ACCEPT_OBJECTION_REQUEST,
    variables: {
      objectionAdminRequestAttributes: {
        id: Number(objectionId),
        message,
      },
    },
  });
}

export function rejectObjectionAdminRequest(objectionId, message) {
  return GraphQLRequest({
    query: REJECT_OBJECTION_REQUEST,
    variables: {
      objectionAdminRequestAttributes: {
        id: Number(objectionId),
        message,
      },
    },
  });
}
