import { GraphQLRequest } from '@/setup/axios';
import {
  CREATE_EDIT_REQUEST,
  UPDATE_EDIT_ITEM,
  LIST_EDIT_ITEMS,
  SUBMIT_EDIT_REQUEST,
  LIST_EDIT_REQUESTS,
  CREATE_EDIT_ITEM,
  DELETE_EDIT_ITEM,
  ACCEPT_EDIT_REQUEST,
  REJECT_EDIT_REQUEST,
  LIST_EDIT_ADMIN_REQUESTS,
} from '@/api/graphql/edit-request-gql';

export function createEditRequest(entityId, unitType, editType) {
  return GraphQLRequest({
    query: CREATE_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        unitId: Number(entityId),
        unitType,
        type: editType,
      },
    },
  });
}

export function submitEditRequest(id, comments) {
  return GraphQLRequest({
    query: SUBMIT_EDIT_REQUEST,
    variables: {
      editRequestAttributes: {
        id: Number(id),
        comments,
      },
    },
  });
}

export function listEditRequests(type, unitId, status, unitType, page, size) {
  return GraphQLRequest({
    query: LIST_EDIT_REQUESTS,
    variables: {
      unitId: Number(unitId),
      unitType,
      type,
      status,
      page,
      size,
    },
  });
}

export function listEditAdminRequests(editId, status, type, page, size) {
  return GraphQLRequest({
    query: LIST_EDIT_ADMIN_REQUESTS,
    variables: {
      requestId: Number(editId),
      status,
      type,
      page,
      size,
    },
  });
}

export function updateEditItem(id, unitId, action, newPayload) {
  return GraphQLRequest({
    query: UPDATE_EDIT_ITEM,
    variables: {
      editItemAttributes: {
        id: Number(id),
        unitId: Number(unitId),
        action,
        newPayload,
      },
    },
  });
}

export function createEditItem(editRequestId, action, newPayload, unitType) {
  return GraphQLRequest({
    query: CREATE_EDIT_ITEM,
    variables: {
      editItemAttributes: {
        editRequestId: Number(editRequestId),
        unitType,
        action,
        newPayload,
      },
    },
  });
}

export function deleteEditItem(id) {
  return GraphQLRequest({
    query: DELETE_EDIT_ITEM,
    variables: {
      id: Number(id),
    },
  });
}

export function listEditItems(editId, action, page, size) {
  return GraphQLRequest({
    query: LIST_EDIT_ITEMS,
    variables: {
      editRequestId: Number(editId),
      page: Number(page),
      size: Number(size),
      action,
    },
  });
}

export function acceptEditRequest(editId) {
  return GraphQLRequest({
    query: ACCEPT_EDIT_REQUEST,
    variables: {
      editAdminRequestAttributes: {
        id: Number(editId),
      },
    },
  });
}

export function rejectEditRequest(editId, reason) {
  return GraphQLRequest({
    query: REJECT_EDIT_REQUEST,
    variables: {
      editAdminRequestAttributes: {
        id: Number(editId),
        message: reason,
      },
    },
  });
}
