<template>
  <header class="header">
    <template v-if="isShowSimplifiedHeader">
      <div class="header-left">
        <slot name="header-left" />
      </div>
      <logo route-name="Dashboard" />
      <div class="header-right">
        <lang-switcher />
      </div>
    </template>
    <template v-else>
      <header-mobile-menu
        ref="headerMobileMenuComponent"
      >
        <template slot="mobile-sidebar-items">
          <slot name="mobile-sidebar-items" />
        </template>
      </header-mobile-menu>
      <logo route-name="Dashboard" />
      <div class="header-right">
        <slot name="header-right" />
      </div>
    </template>
  </header>
</template>

<script>
import HeaderMobileMenu from '@/containers/header/header-mobile-menu/HeaderMobileMenu.vue';
import LangSwitcher from '@/containers/lang-switcher/LangSwitcher.vue';

export default {
  name: 'Header',
  components: {
    HeaderMobileMenu,
    LangSwitcher,
  },
  computed: {
    isShowSimplifiedHeader() {
      const publicRouteNameList = ['Login', 'ChangePassword', 'ResetPassword'];
      return publicRouteNameList.includes(this.$route.name);
    },
  },
  methods: {
    handleLinkClick() {
      this.$refs.headerMobileMenuComponent.handleLinkClick();
    },
  },

};
</script>

<style src="./styles.scss" lang="scss"/>
