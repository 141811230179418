import { GraphQLRequest } from '@/setup/axios';
import {
  LIST_CONTRIBUTION_SOURCES,
  FETCH_FILTERED_ENTITIES_FOR_ADMIN,
  GENERATE_REPORTS,
} from '@/api/graphql/entities-list-gql';

class EntitiesListApi {
  fetchFilteredEntities = (payload = {}) => (
    GraphQLRequest({
      query: FETCH_FILTERED_ENTITIES_FOR_ADMIN,
      variables: {
        page: payload.page || 1,
        size: payload.size || 10,
        name: payload.name ? String(payload.name) : undefined,
        hijriDateFrom: payload.hijriDateFrom ? String(payload.hijriDateFrom) : undefined,
        hijriDateTo: payload.hijriDateTo ? String(payload.hijriDateTo) : undefined,
        goalId: payload.goalId ? Number(payload.goalId) : undefined,
        activityId: payload.activityId ? Number(payload.activityId) : undefined,
        type: payload.entityType ? String(payload.entityType) : undefined,
        mainClassificationId: payload.classification
          ? Number(payload.classification)
          : undefined,
        firstSubClassificationId: payload.firstClassification
          ? Number(payload.firstClassification)
          : undefined,
        secondSubClassificationsId: payload.secondSubClassificationIds
          ? Number(payload.secondSubClassificationIds)
          : undefined,
        statuses: Array.isArray(payload.status) ? payload.status.map((e) => String(e)) : undefined,
        excludedType: Array.isArray(payload.excludedType)
          ? payload.excludedType.map((e) => String(e)) : [],
        regionCode: payload.region ? String(payload.region) : undefined,
        cityUid: Array.isArray(payload.city)
          ? payload.city.map((e) => String(e)) : undefined,
        entityUid: payload.entityUid ? String(payload.entityUid) : undefined,
        unifiedNumber700: payload.unifiedNumber700 ? String(payload.unifiedNumber700) : undefined,
      },
    })
  )

  listContributionSources = (entityId, page = 1, size = 10) => GraphQLRequest({
    query: LIST_CONTRIBUTION_SOURCES,
    variables: {
      entityId: Number(entityId),
      page,
      size,
    },
  })

  generateReports = (payload = {}, locale, config) => GraphQLRequest({
    query: GENERATE_REPORTS,
    variables: {
      name: payload.name ? String(payload.name) : undefined,
      type: payload.entityType ? String(payload.entityType) : undefined,
      locale,
      status: Array.isArray(payload.status) ? payload.status.map((e) => String(e)) : [],
      mainClassificationId: payload.classification
        ? Number(payload.classification)
        : undefined,
      firstSubClassificationId: payload.firstClassification
        ? Number(payload.firstClassification)
        : undefined,
      secondSubClassificationsId: payload.secondSubClassificationIds
        ? Number(payload.secondSubClassificationIds)
        : undefined,
      hijriDateFrom: payload.hijriDateFrom ? String(payload.hijriDateFrom) : undefined,
      hijriDateTo: payload.hijriDateTo ? String(payload.hijriDateTo) : undefined,
      goalId: payload.goalId ? Number(payload.goalId) : undefined,
      activityId: payload.activityId ? Number(payload.activityId) : undefined,
      regionCode: payload.region ? String(payload.region) : undefined,
      cityUid: Array.isArray(payload.city)
        ? payload.city.map((e) => String(e)) : undefined,
      entityUid: payload.entityUid ? String(payload.entityUid) : undefined,
      unifiedNumber700: payload.unifiedNumber700 ? String(payload.unifiedNumber700) : undefined,
    },
  }, config)
}

export default new EntitiesListApi();
