export const SUBMIT_EDIT_REQUEST = `mutation submitEditRequest($editRequestAttributes: EditRequestSubmitInput!) {
  submitEditRequest(editRequestAttributes: $editRequestAttributes) {
    id
  }
}`;

export const ACCEPT_EDIT_REQUEST = `mutation acceptEditRequest($editRequestAttributes: EditRequestAcceptInput!) {
  acceptEditRequest(editRequestAttributes: $editRequestAttributes) {
    id
  }
}`;

export const REJECT_EDIT_REQUEST = `mutation rejectEditRequest($editRequestAttributes: EditRequestRejectInput!) {
  rejectEditRequest(editRequestAttributes: $editRequestAttributes) {
    id
  }
}`;

export const ADJUST_EDIT_REQUEST = `mutation adjustEditRequest($editRequestAttributes: EditRequestAdjustmentInput!) {
  adjustEditRequest(editRequestAttributes: $editRequestAttributes) {
    id
  }
}`;

export const UPDATE_EDIT_ITEM = `mutation updateEditItem($editItemAttributes: EditItemUpdateInput!) {
  updateEditItem(editItemAttributes: $editItemAttributes) {
    action
    createdAt
    currentPayload {
      activityIds
      additionalNumber
      amount
      bankBranchName
      bankId
      buildingNumber
      ceoCustomCondition
      ceoDegreesCondition
      ceoEducationalLevelCondition
      ceoPreviousExperienceCondition
      cityUid
      description
      duties
      entityId
      fee
      goalIds
      minNumOfSharesPerMember
      nameArabic
      nameEnglish
      numberOfBoardMembers
      numberOfShares
      postCode
      regionCode
      rights
      secondSubClassificationId
      shareValue
      streetName
      type
      typeOfBenefits
      unitId
    }
    editRequestId
    id
    newPayload {
      activityIds
      additionalNumber
      amount
      bankBranchName
      bankId
      buildingNumber
      ceoCustomCondition
      ceoDegreesCondition
      ceoEducationalLevelCondition
      ceoPreviousExperienceCondition
      cityUid
      description
      duties
      entityId
      fee
      goalIds
      minNumOfSharesPerMember
      nameArabic
      nameEnglish
      numberOfBoardMembers
      numberOfShares
      postCode
      regionCode
      rights
      secondSubClassificationId
      shareValue
      streetName
      type
      typeOfBenefits
      unitId
    }
    unitId
    unitType
    updatedAt
  }
}`;
