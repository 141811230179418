export const GET_EMPLOYEE_REQUESTS = `query ListEmployeeAdminRequests($page: Int!, $size: Int!, $status: [String!]) {
  listEmployeeAdminRequests (page: $page, size: $size, status: $status) {
    adminRequests {
      id
      autoDecision
      status
      remainingData {
        remainingValue
        remainingType
      }
      request {
        ... on EmployeeRequest {
          id
          createdAt
          updatedAt
          entity {
            nameArabic
            nameEnglish
            type
          }
        }
      }
    }
    meta {
      pageCount
      page
      recordsCount
    }
  }
}`;

export const GET_EMPLOYEE_REQUEST_BY_ID = `query FindEmployeeAdminRequests($id: Int!) {
  findEmployeeAdminRequests(id: $id) {
    id
    requestItemId: requestId
    status
    request {
      ... on EmployeeRequest {
        createdAt
        updatedAt
        entity {
          nameArabic
          nameEnglish
          type
        }
        boardOfTrusteesMinutesOfMeetings {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        position
        previousExperience
        positionExperienceYears
        salary
        employee {
          absherPhone
          account {
            id
          }
          birthplace
          birthDateHijri
          createdAt
          degrees
          deletedAt
          educationSpecialization
          educationalCertificateUids
          educationalCertificates {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          educationalLevel
          email
          employmentPlace
          fatherName
          firstName
          gender
          grandfatherName
          homePhone
          id
          lastName
          nationalAddress {
            additionalNo
            buildingNo
            cityAr
            cityEn
            createdAt
            districtAreaAr
            districtAreaEn
            id
            latitude
            longitude
            streetNameAr
            streetNameEn
            unitNo
            updatedAt
            zipCode
          }
          nationalId
          nationality {
            arTitle
            enTitle
            uid
          }
          nidExpireDate
          occupation
          previousExperience
          socialMedias {
            accountName
            id
            type
            userId
          }
          updatedAt
        }
      }
    }
  }
}`;

export const ACCEPT_EMPLOYEE_ADMIN_REQUEST = `mutation AcceptEmployeeAdminRequest($id: Int!) {
  acceptEmployeeAdminRequest(id: $id) {
    id
  }
}`;

export const REJECT_EMPLOYEE_ADMIN_REQUEST = `mutation RejectEmployeeAdminRequest($id: Int!, $message: String!) {
  rejectEmployeeAdminRequest(id: $id, message: $message) {
    id,
  }
}`;
